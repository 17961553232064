import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'ERP коды',
      fields: {
        docErpHId: 'ERP Документ',
        algorithm: 'Алгоритм поиска',
        ki: 'Код идентификации',
        num_goods: 'Код товара',
        uit_code: 'КМ',
        uitu_code: 'SSCC',
      },
    }
  }
}