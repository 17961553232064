import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Aggr M',
      fields: {
        sntins: 'SNTINS',
        aggrPId: 'Родительская позиция',
      },
    }
  }
}