import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Import document',
      title: 'Import document',
      executeAction: 'Submit',
      errorMessage: 'Error',
      successMessage: 'Request successfully sent',
      fields: {
        docType: 'Document type',
      },
    }
  }
}