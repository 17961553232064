import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Остатки',
      reportP: 'Позиции',
      fields: {
        actionId: 'Id действия',
        checkStatus: 'Проверка статуса',
        direction: 'Направление',
        docStatus: 'Статус документа',
        docnum: 'Номер документа',
        documentId: 'Id документа',
        documentSystemStatus: 'Системный статус',
        errors: 'Ошибки',
        guid: 'GUID',
        receiverId: 'Id получателя',
        requestId: 'Id запроса',
        subjectId: 'Id Субъекта',
        tradeParticipantInn: 'ИНН участника торговли',
        id: '#',
      },
      send: {
        errorMessage: 'Ошибка',
        successMessage: 'Успешно',
        action: 'Отправить'
      }
    }
  }
}