import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Перемаркировка |||| Перемаркировка',
      remarkP: 'Позиции',
      sendRemark: {
        action: 'Отправить',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      fields: {
        processedDate: 'Дата обработки',
        remarkingDate: 'Дата перемаркировки',
        checkStatus: 'Статус ИСМП',
        actionId: 'Id Операции',
        direction: 'Направление',
        docStatus: 'Статус',
        docnum: 'Номер документа',
        documentId: 'Id документа',
        documentLinkType: 'Тип ссылки на документ',
        documentLinkId: 'Id ссылки на документ',
        documentSystemStatus: 'Системный статус документа',
        errorModelId: 'Id ошибки',
        markingType: 'Тип маркировки',
        participantInn: 'ИНН участника',
        receiverId: 'Id получателя',
        remarkingCause: 'Причина перемаркировки',
        requestId: 'Id запроса',
        subjectId: 'Id темы',

        guids: 'GUID (Список)',
        docnums: 'Номер документа (Список)',
      }
    }
  }
}