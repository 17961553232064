import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        RECEIPT: 'Кассовый чек',
        DESTRUCTION_ACT: 'Акт уничтожения (утраты/утилизации)',
        CONSIGNMENT_NOTE: 'Товарная накладная',
        UTD: 'Универсальный передаточный документ',
        CORRECTION_RECEIPT: '',
        SALES_RECEIPT: 'Товарный чек',
        CUSTOMS_DECLARATION: 'Таможенная декларация на товары',
        LOAN_RECEIPT: 'LOAN_RECEIPT',
        CANCELLATION: 'CANCELLATION',
        CANCELLATION_APPLIED: 'CANCELLATION_APPLIED',
        STRICT_REPORTING_FORM: 'STRICT_REPORTING_FORM',
        CONTRACT: 'CONTRACT',
        OTHER: 'Прочее',
      },
    }
  }
}
