import React from 'react';
import {
  translate,
  Datagrid,
  Edit,
  Create,
  // EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Filter,
  EditButton,
  BooleanInput,
  NumberInput,
  NumberField,
  CreateButton
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';

import resourceLocales from './locales';
import resourceConfig from './config';

import LinkToList from './LinkToList';
import DateInput from '../../../../components/DateInput';
import remarkCause from '../remarkCause';
import remarkH from '../remarkH';
import markingType from '../markingType';
import exporter from '../../../../utils/exporter';
import remarkPrimaryDocType from '../remarkPrimaryDocType';
import ExportDocButton from '../docH/ExportDocButton';
import Toolbar from '@material-ui/core/Toolbar';

const ResourceIcon = BookmarkIcon;

const resourceToString = (x) => `#${x.id} (${x.docnum})`;

const listStyles = {};

const ResourceActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  translate,
}) => (
  <Toolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} to={`${basePath}/create`} />
    <ExportDocButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

const ResourceListFilter = (props) => (
  <Filter {...props}>
    <remarkH.Input source={'overEntityIds'} />
  </Filter>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
  <List
    {...props}
    sort={{ field: 'id', order: 'DESC' }}
    filters={<ResourceListFilter />}
    exporter={exporter}
    actions = {<ResourceActions />}
    >
    <Datagrid>
      <TextField source={'id'} />
      <TextField source={'certificateDocument'} />
      <TextField source={'lastUin'} />
      <TextField source={'newUin'} />
      <TextField source={'tnved10'} />
      <TextField source={'production_country'} />
      <TextField source={'color'} />
      <NumberField source={'product_size'} />
      <remarkCause.Field source={'remarkingCause'} />
      <remarkH.Field source={'remarkHId'} />
      <EditButton />
    </Datagrid>
  </List>
));

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.name}
    &quot;
  </span>
));

const ResourceCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source={'certificateDocument'} />
      <DateInput
        source={'certificateDocumentDate'}
        label={`resources.${resourceConfig.name}.fields.certificateDocumentDate`}
      />
      <TextInput source={'certificateDocumentNumber'} />
      <TextInput source={'lastUin'} />
      <markingType.Input source={'markingType'} />
      <TextInput source={'newUin'} />
      <remarkH.Input source={'remarkHId'} />
      <remarkCause.Input source={'remarkingCause'} />
      <DateInput
        source={'remarkingDate'}
        label={`resources.${resourceConfig.name}.fields.remarkingDate`}
      />

      <remarkPrimaryDocType.Input source={'primaryDocumentType'} />
      <TextInput source={'primaryDocumentCustomName'} />
      <DateInput
        source={'primaryDocumentDate'}
        label={`resources.${resourceConfig.name}.fields.primaryDocumentDate`}
      />
      <TextInput source={'primaryDocumentNumber'} />
      <TextInput source={'tnved10'} />
      <TextInput source={'production_country'} />
      <TextInput source={'color'} />
      <NumberInput source={'product_size'} />
      <BooleanInput source={'paid'} />
    </SimpleForm>
  </Create>
);

const ResourceEdit = (props) => (
  <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
    <SimpleForm redirect={false}>
      <TextInput source={'certificateDocument'} />
      <DateInput
        source={'certificateDocumentDate'}
        label={`resources.${resourceConfig.name}.fields.certificateDocumentDate`}
      />
      <TextInput source={'certificateDocumentNumber'} />
      <TextInput source={'lastUin'} />
      <markingType.Input source={'markingType'} />
      <TextInput source={'newUin'} />
      <remarkH.Input source={'remarkHId'} />
      <remarkCause.Input source={'remarkingCause'} />
      <DateInput
        source={'remarkingDate'}
        label={`resources.${resourceConfig.name}.fields.remarkingDate`}
      />

      <remarkPrimaryDocType.Input source={'primaryDocumentType'} />
      <TextInput source={'primaryDocumentCustomName'} />
      <DateInput
        source={'primaryDocumentDate'}
        label={`resources.${resourceConfig.name}.fields.primaryDocumentDate`}
      />
      <TextInput source={'primaryDocumentNumber'} />
      <TextInput source={'tnved10'} />
      <TextInput source={'production_country'} />
      <TextInput source={'color'} />
      <NumberInput source={'product_size'} />
      <BooleanInput source={'paid'} />
    </SimpleForm>
  </Edit>
);

const ResourceStringField = ({ record }) => <span>{resourceToString(record)}</span>;

// const ResourceStringChipField = ({ record }) => (
//     <span>{resourceToString(record)}</span>
// )

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} {...props}>
    <ResourceStringField />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={resourceToString} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={resourceToString} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Create: ResourceCreate,
  Edit: ResourceEdit,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,
  LinkToList,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    edit: ResourceEdit,
    create: ResourceCreate,
    icon: ResourceIcon,
  },
  // menuConfig: {
  //     name: resourceConfig.name,
  //     icon: ResourceIcon,
  // },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {
      certificateDocumentDate: {
        type: 'dateTime',
      },
      remarkingDate: {
        type: 'dateTime',
      },
      primaryDocumentDate: {
        type: 'dateTime',
      },
    },
  },
};
