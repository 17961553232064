import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'ATK |||| ATK',
      atkKM: 'Codes',
      enums: {
        docType: {
          ATK_AGGREGATION: 'Atk aggregation',
          ATK_TRANSFORMATION: 'Atk transformation',
          ATK_DISAGGREGATION: 'Atk disaggregation',
        },
        transformationType: {
          REMOVING: 'Removing',
          ADDING: 'Adding',
        },
      },
      dissagr: {
        action: 'Dissagregation',
        successMessage: 'Request sent',
        errorMessage: 'Error',
      },
      send: {
        action: 'Send',
        successMessage: 'Request sent',
        errorMessage: 'Error',
      },
      fields: {
        actionId: 'Actionid',
        atk: 'Atk',
        checkStatus: 'Check status',
        country: 'Country',
        dateInvoice: 'Date invoice',
        direction: 'Direction',
        docStatus: 'Doc status',
        docType: 'Doctype',
        docnum: 'Docnum',
        documentDate: 'Document date',
        documentId: 'Document id',
        documentLinkType: 'Document link type',
        documentLinkId: 'Document link id',
        documentSystemStatus: 'Document system status',
        errorModelId: 'Errormodel id',
        errors: 'Errors',
        guid: 'Guid',
        invoice: 'Invoice',
        invoiceAtk: 'Invoice atk',
        invoiceAtkDate: 'Invoice atk date',
        invoiceAtkPos: 'Invoice atk pos',
        ismpDocType: 'Ismp doc type',
        mrkSystemStatus: 'Mrk system status',
        numGoods: 'Numgoods',
        processedDate: 'Processed date',
        processedDateFrom: 'Processed date from',
        processedDateTo: 'Processed date to',
        productGroup: 'Product group',
        receiverId: 'Receiver id',
        requestId: 'Request id',
        runningBefore: 'Running before',
        status: 'Status',
        subjectId: 'Subject id',
        tnved: 'Tnved',
        tnvedCode: 'Tnved code',
        tradeParticipantInn: 'Trade participant inn',
        transformationType: 'Transformation type',
        itemCode: 'Item code',
      }
    }
  }
}