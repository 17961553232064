import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Отчет по статусам',
      title: 'Отчет по статусам',
      executeAction: 'Отправить',
      errorMessage: 'Ошибка',
      successMessage: 'Запрос успешно отправлен',
      fields: {
        docType: 'Тип документа',
        file: 'Файл',
        email: 'Email',
        emails: 'Адреса'
      },
    }
  }
}