import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        NEW: 'NEW',
        IN_DOC_NOT_YET_EXEC: 'IN_DOC_NOT_YET_EXEC',
        MOVE_DESTRUCTION: 'MOVE_DESTRUCTION',
        DESTRUCTION: 'DESTRUCTION',
        WITHDRAWAL: 'WITHDRAWAL',
        PRINTED: 'PRINTED',
        NOT_ARRIVED: 'NOT_ARRIVED',
        TRANSIT_2_SORT: 'TRANSIT_2_SORT',
        TRANSIT_DAMAGED: 'TRANSIT_DAMAGED',
        W_RETIREMENT: 'W_RETIREMENT',
        OUT_OF_CIRCULATION: 'OUT_OF_CIRCULATION',
        W_INTRODUCING: 'W_INTRODUCING',
        INTRODUCED: 'INTRODUCED',
        W_SHIPMENT: 'W_SHIPMENT',
        SHIPPED: 'SHIPPED',
        PRODUCTION: 'PRODUCTION',
        USED: 'USED',
        W_CANCELLATION: 'W_CANCELLATION',
        ORDER_RESERVED: 'ORDER_RESERVED',
        IN_SET: 'IN_SET',
        W_RETURN: 'W_RETURN',
        COMPLETED: 'COMPLETED',
        MOVED: 'MOVED',
        W_REMARK: 'W_REMARK',
        PRINTING_PROCESS: 'PRINTING_PROCCESS'
      },
    },
  },
};
