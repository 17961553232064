import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Отгрузки ЕАЭС',
      title: 'Отгрузки ЕАЭС',
      executeAction: 'Подтвердить',
      errorMessage: 'Ошибка',
      successMessage: 'Запрос успешно отправлен',
      filterErrorMessage: 'Выберите период по дате',
    },
  },
};
