import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Upload',
      title: 'Upload',
      executeAction: 'Submit',
      errorMessage: 'Error',
      successMessage: 'All %{count} rows added',
      addedItemsCount: 'Added rows count',
      errorItems: 'Errors',
      fields: {
      },
    }
  }
}