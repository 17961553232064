import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'NK Good |||| NK Goods',
      sign: {
        action: 'Sign',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      check: {
        action: 'Check',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      load: {
        action: 'Load',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      enums: {
        goodDetailedStatus: {
          DRAFT: 'Draft',
          MODERATION: 'Moderation',
          ERRORS: 'Errors',
          NOTSIGNED: 'Notsigned',
          PUBLISHED: 'Published',
          ARCHIVED: 'Archived',
        },
      },
      fields: {
        id: 'Id',
        date: 'Date',
        feedId: 'Feed id',
        goodId: 'Good id',
        goodName: 'Good name',
        gtin: 'Gtin',
        isKit: 'Is kit',
        isSet: 'Is set',
        tnved: 'Tnved',
        categories: 'Categories',
        attrId: 'Attr id',
        attrValue: 'Attr value',
        attrValueType: 'Attr value type',
        goodAttrs: 'Good attrs',
        catId: 'Cat id',
        identifiedBy: 'Identified by',
        level: 'Level',
        multiplier: 'Multiplier',
        type: 'Type',
        value: 'Value',
        quantity: 'Quantity',
        brandId: 'Brand id',
        brandName: 'Brand name',
        flagUpdatedDate: 'Flag updated date',
        goodDetailedStatus: 'Good detailed status',
        goodMarkFlag: 'Good mark flag',
        goodRating: 'Good rating',
        goodSigned: 'Good signed',
        goodStatus: 'Good status',
        goodTurnFlag: 'Good turn flag',
        goodUrl: 'Good url',
        producerInn: 'Producer inn',
        producerName: 'Producer name',
        remainderType: 'Remainder type',
        updatedDate: 'Updated date',
        attrGroupId: 'Attr group id',
        attrGroupName: 'Attr group name',
        attrName: 'Attr name',
        valueId: 'Value id',
        catName: 'Cat name',
      },
    },
  },
};
