import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'ERP товары',
      fields: {
        docErpHId: 'ERP Документ',
        certificate_date: 'Дата документа, подтверждающего соответствие',
        certificate_number: 'Номер документа, подтверждающего соответствие',
        certificate_type: 'Вид документа обязательной сертификации',
        description: 'Описание',
        mrk_system_status: 'Системный статус',
        num_goods: 'Номер товара',
        paid: 'Оплачен',
        pg: 'Товарная группа',
        primary_document_custom_name: 'Наименование первичного документа',
        primary_document_date: 'Дата первичного документа',
        primary_document_number: 'Номер первичного документа',
        primary_document_type: 'Тип первичного документа',
        product_cost: 'Цена',
        product_tax: 'НДС',
        quantity: 'Количество',
      },
    }
  }
}