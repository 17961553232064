import React from 'react';
import { translate } from  'react-admin';
import Button from '@material-ui/core/Button';

import TransitionLink from '../../../../components/TransitionLink';
import { dictionaryToParam } from '../../../../utils';
import resourceConfig from './config';

const OpenButton = ({params, translate,...props}) => (
  <Button
    size={'small'}
    color={'primary'}
    {...props}
    component={TransitionLink}
    onClick={(e) => {
      if (params.ids.length ==1)
        return true;
      alert(translate(`resources.${resourceConfig.name}.filterErrorMessage`))
      e.preventDefault();
    }}
    transitionTo={{
      params: {
        [resourceConfig.urlParamName]: dictionaryToParam(params.ids)
      }
    }}
  >
    {translate(`resources.${resourceConfig.name}.openAction`)}
  </Button>
);


export default translate(OpenButton);
