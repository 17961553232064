export default {
  enums: [
    { id: 'RETAIL' },
    { id: 'REMOTE_SALE' },
    { id: 'EEC_EXPORT' },
    { id: 'BEYOND_EEC_EXPORT' },
    { id: 'RETURN' },
    { id: 'DAMAGE_LOSS' },
    { id: 'DESTRUCTION' },
    { id: 'CONFISCATION' },
    { id: 'LIQUIDATION' },
    { id: 'ENTERPRISE_USE' },
    { id: 'DONATION' },
    { id: 'STATE_ENTERPRISE' },
    { id: 'NO_RETAIL_USE' },
    { id: 'OTHER' },
    { id: 'DISTANCE' },
    { id: 'PRODUCTION_USE' },
    { id: 'EXPIRATION' },
    { id: 'RECALL' },
    { id: 'STATE_SECRET' },
    { id: 'VENDING' },
    { id: 'UTILIZATION' },
    { id: 'PACKING' },
    { id: 'LOSS' },
    { id: 'BY_SAMPLES' },
    { id: 'EAS_TRADE' },
    { id: 'OWN_USE' },
    { id: 'STATE_CONTRACT' },
  ],
};
