import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        RECEIPT: 'RECEIPT',
        CORRECTION_RECEIPT: 'CORRECTION_RECEIPT',
        SALES_RECEIPT: 'SALES_RECEIPT',
        CONSIGNMENT_NOTE: 'CONSIGNMENT_NOTE',
        UTD: 'UTD',
        CUSTOMS_DECLARATION: 'CUSTOMS_DECLARATION',
        DESTRUCTION_ACT: 'DESTRUCTION_ACT',
        OTHER: 'OTHER',
      },
    }
  }
}