import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        NEW: 'Новый',
        WITHDRAWAL: 'Вывод',
        PRINTED: 'Напечатан',
        NOT_ARRIVED: 'Не прибыл',
        TRANSIT_2_SORT: 'Транзит для сортировки',
        TRANSIT_DAMAGED: 'Транзит поврежден',
        W_RETIREMENT: 'Ожидает вывода из оборота',
        OUT_OF_CIRCULATION: 'Выведен из оборота',
        W_INTRODUCING: 'Ожидает ввод в оборот',
        INTRODUCED: 'Введен в оборот',
        W_SHIPMENT: 'Ожидает отгрузки',
        SHIPPED: 'Отгружен',
        MOVE_DESTRUCTION: 'Переместить разрушение',
        DESTRUCTION: 'Разрушение',
        IN_DOC_NOT_YET_EXEC: 'В док пока нет exec',
        PRODUCTION: 'Отдан в производство',
        USED: 'Использован',
        W_CANCELLATION: 'Ожидает списания',
        ORDER_RESERVED: 'Зарезервирован в заказ',
        IN_SET: 'В наборе',
        W_RETURN: 'Ожидает возврата',
        COMPLETED: 'Набор скомплектован',
        MOVED: 'Перемещен',
        W_REMARK: 'Ожидает перемаркировки',
        PRINTING_PROCESS: 'В процессе печати'
      },
    },
  },
};
