import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        GOODSIMPORT: 'GOODSIMPORT',
        LP_INTRODUCE_OST: 'LP_INTRODUCE_OST',
        Promotion_Inform_Selfmade: 'Promotion_Inform_Selfmade',
        LP_FTS_INTRODUCE: 'LP_FTS_INTRODUCE',
        CROSSBORDER: 'CROSSBORDER',
      },
    },
  },
};
