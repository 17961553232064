import React from 'react';
import {
  translate,
  Datagrid,
  Edit,
  Create,
  // EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Filter,
  EditButton,
  CreateButton,
  ExportButton,
  BooleanInput,
  BooleanField,
} from 'react-admin';
import qs from 'qs';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Toolbar from '@material-ui/core/Toolbar';

// import CodeType from '../codeType';
// import EmissionType from '../emissionType';
// import MarkingType from '../markingType';
// import OrderH from '../orderH';
// import SerialNumType from '../serialNumType';
import resourceLocales from './locales';
import resourceConfig from './config';

import LinkToList from './LinkToList';
import DocH from '../docH';
import exporter from '../../../../utils/exporter';
import docM from '../docM';
import ExportDocButton from '../docH/ExportDocButton';
const ChildrenMButton = (props) => {
  return (
    <docM.LinkToList
      label={`resources.${resourceConfig.name}.docM`}
      filter={{
        overEntityIds: props.record.id,
      }}
    />
  );
};
const ResourceIcon = BookmarkIcon;

const resourceToString = (x) => `#${x.id} (${x.docnum})`;

const listStyles = {};

const ResourceActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  translate,
}) => (
  <Toolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton
      basePath={basePath}
      to={`${basePath}/create?${
        filterValues && filterValues.overEntityIds ? `_dochId=${filterValues.overEntityIds}` : ''
      }`}
    />
    <ExportDocButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

const ResourceListFilter = (props) => (
  <Filter {...props}>
    <DocH.Input source={'overEntityIds'} label="overEntityIds" />
    <TextInput source={'idpos'} />
    <TextInput source={'numdoc'} />
    <TextInput source={'product_cost'} />
    <TextInput source={'product_description'} />
    <TextInput source={'product_tax'} />
    <TextInput source={'uit_code'} />
    <TextInput source={'uitu_code'} />
  </Filter>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
  <List
    {...props}
    filters={<ResourceListFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    actions={<ResourceActions />}
    exporter={exporter}>
    <Datagrid>
      <TextField source={'idpos'} />
      <TextField source={'numdoc'} />
      <TextField source={'product_cost'} />
      <TextField source={'product_description'} />
      <TextField source={'product_tax'} />
      <TextField source={'uit_code'} />
      <TextField source={'uitu_code'} />
      <BooleanField source={'accepted'} />
      <DocH.Field source={'docHId'} />
      <ChildrenMButton />
      <EditButton />
    </Datagrid>
  </List>
));

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.name}
    &quot;
  </span>
));

const ResourceCreate = (props) => {
  const defaultData = {};
  if (props.location && props.location.search && props.location.search.length > 1) {
    const params = qs.parse(props.location.search.replace(/^\?/, ''));
    if (params && params._dochId) {
      defaultData.docHId = parseInt(params._dochId);
    }
  }
  return (
    <Create {...props}>
      <SimpleForm>
        <DocH.Input source={'docHId'} defaultValue={defaultData.docHId} />
        <TextInput source={'idpos'} />
        <TextInput source={'numdoc'} />
        <TextInput source={'num_goods'} />
        <TextInput source={'product_cost'} />
        <TextInput source={'product_description'} />
        <TextInput source={'product_tax'} />
        <TextInput source={'uit_code'} />
        <TextInput source={'uitu_code'} />
        <BooleanInput source={'accepted'} defaultValue={true} />
        <TextInput source={'exStatus'} />
        <TextInput source={'importerId'} />
        <TextInput source={'importerName'} />
        <TextInput source={'producerId'} />
        <TextInput source={'producerName'} />
      </SimpleForm>
    </Create>
  );
};

const ResourceEdit = (props) => (
  <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
    <SimpleForm redirect={false}>
      <DocH.Input source={'docHId'} />
      <TextInput source={'idpos'} />
      <TextInput source={'numdoc'} />
      <TextInput source={'num_goods'} />
      <TextInput source={'product_cost'} />
      <TextInput source={'product_description'} />
      <TextInput source={'product_tax'} />
      <TextInput source={'uit_code'} />
      <TextInput source={'uitu_code'} />
      <BooleanInput source={'accepted'} />
      <TextInput source={'exStatus'} />
      <TextInput source={'importerId'} />
      <TextInput source={'importerName'} />
      <TextInput source={'producerId'} />
      <TextInput source={'producerName'} />
    </SimpleForm>
  </Edit>
);

const ResourceStringField = ({ record }) => <span>{resourceToString(record)}</span>;

// const ResourceStringChipField = ({ record }) => (
//     <span>{resourceToString(record)}</span>
// )

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} {...props}>
    <ResourceStringField />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={resourceToString} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={resourceToString} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Create: ResourceCreate,
  Edit: ResourceEdit,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,
  LinkToList,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    edit: ResourceEdit,
    create: ResourceCreate,
    icon: ResourceIcon,
  },
  // menuConfig: {
  //     name: resourceConfig.name,
  //     icon: ResourceIcon,
  // },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {},
  },
};
