import React, { Component } from 'react';
import { TextInput, SelectInput } from 'react-admin';
import request from '../../network/request';


class UserWarehouseInput extends Component {
  state = {
    items: [],
  }

  componentDidMount() {
    this.loadItems();
  }

  loadItems = async () => {
    let response = null;
    try {
      response = await request({
        apiService: 'account',
        path: '/current/warehouse',
        method: 'GET',
      })
    } catch (e) {
      console.error(e);
    }
    let items = [];
    if (response !== null && response.ok) {
      items = response.data.map(x => ({ id: x }));
    }  
    this.setState({
      items,
    });
  }

  render () {
    const { items } = this.state;

    if (items.length === 0) {
      return (
        <TextInput
          {...this.props}
        />
      )
    }

    return (
      <SelectInput 
        choices={items}
        optionValue={'id'}
        optionText={'id'}
        {...this.props}
      />
    )
  }
}

export default UserWarehouseInput;
