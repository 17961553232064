import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Aggr |||| Aggrs',
      aggrP: 'Records',
      send: {
        action: 'Send',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      fields: {
        dateCreate: 'Date create',
        dateSend: 'Date send',
        processedDate: 'Processed date',
        actionId: 'Action Id',
        companyUniqName: 'Company Uniq Name',
        creatorUsername: 'Creator Username',
        direction: 'Direction',
        docStatus: 'Doc Status',
        docnum: 'Docnum',
        documentId: 'Document Id',
        documentLinkId: 'Document link Id',
        documentLinkType: 'Document link type',
        documentSystemStatus: 'Document system status',
        errorModelId: 'Error model Id',
        participantId: 'Participant Id',
        receiverId: 'Receiver Id',
        reportId: 'Report Id',
        requestId: 'Request Id',
        subjectId: 'Subject Id',
        typedoc: 'Type Doc',
        uitu: 'UITU',
      }
    }
  }
}