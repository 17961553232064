import React, { Fragment } from 'react';
import {
  translate,
  Datagrid,
  Edit,
  Create,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Filter,
  BulkDeleteButton,
  NumberInput,
  CreateButton,
  BooleanInput,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Toolbar from '@material-ui/core/Toolbar';

import LinkToList from './LinkToList';
import CreatePDFButton from './CreatePDFButton';
import resourceLocales from './locales';
import resourceConfig from './config';
import documentRowStyle from '../../../../utils/ui/documentRowStyle';
import printJournalElement from '../printJournalElement';
import printJasper from '../printJasper';
import printUpload from '../printUpload';
import printLoad from '../printLoad';
import CreateCSVButton from './CreateCSVButton';
import quickPrint from '../quickPrint';
import emissionType from '../emissionType';
import exporter from '../../../../utils/exporter';
import mrkStatusSystem from '../mrkStatusSystem';
import rePrintByXlsx from '../rePrintByXlsx';
import PostPagination from '../../../../utils/pagination';
import ExportDocButton from '../docH/ExportDocButton';
const ResourceIcon = BookmarkIcon;

const listStyles = {};

const ResourceBulkActionButtons = (props) => (
  <Fragment>
    <BulkDeleteButton {...props} />
  </Fragment>
);

const ResourceActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  translate,
}) => (
  <Toolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <printJasper.OpenButton />
    <printUpload.OpenButton />
    <printLoad.OpenButton />
    <quickPrint.OpenButton />
    <rePrintByXlsx.OpenButton />
    <CreateButton basePath={basePath} />
    <ExportDocButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

const ResourceListFilter = (props) => (
  <Filter {...props}>
    <TextInput source={'ids'} />
    <TextInput source={'ean'} />
    <TextInput source={'gtin'} />
    <NumberInput source={'inputhId'} />
    <TextInput source={'itemCode'} />
  </Filter>
);

const ChildrenPButton = (props) => {
  return (
    <printJournalElement.LinkToList
      label={`resources.${resourceConfig.name}.printJournalElement`}
      filter={{
        printJournalId: props.record.id,
      }}
    />
  );
};

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
  <List
    {...props}
    filters={<ResourceListFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={<ResourceBulkActionButtons />}
    actions={<ResourceActions />}
    exporter={exporter}
    pagination={<PostPagination />}>
    <Datagrid rowStyle={documentRowStyle}>
      <TextField source={'id'} />

      <TextField source={'label'} />
      <TextField source={'gtin'} />
      <TextField source={'matnr'} />
      <TextField source={'menge'} />
      <TextField source={'ordernum'} />
      <TextField source={'serialNumber'} />
      <TextField source={'itemCode'} />
      <TextField source={'userName'} />

      <printLoad.OpenButton type={'csv'} />
      <printLoad.OpenButton type={'pdf'} />
      <CreateCSVButton />
      <CreatePDFButton />
      <ChildrenPButton />
      <EditButton />
    </Datagrid>
  </List>
));

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.name}
    &quot;
  </span>
));

const ResourceCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      {/* <TextInput source={'companyUniqName'} />
            <TextInput source={'creatorUsername'} /> */}
      <TextInput source={'gtin'} />
      <TextInput source={'label'} />
      <TextInput source={'matnr'} />
      <TextInput source={'ean'} />
      <NumberInput source={'inputhId'} />
      <NumberInput source={'menge'} />
      <NumberInput source={'ordernum'} />
      <TextInput source={'serialNumber'} />
      <TextInput source={'itemCode'} />
      <TextInput source={'userName'} />
      <emissionType.Input source={'emissionType'} />
      <mrkStatusSystem.Input source={'mrkStatusAfterPrint'} />
      <BooleanInput source={'importPrint'} />
    </SimpleForm>
  </Create>
);

const ResourceEdit = (props) => (
  <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
    <SimpleForm redirect={false}>
      <TextField source={'id'} />
      {/* <TextInput source={'companyUniqName'} />
            <TextInput source={'creatorUsername'} /> */}
      <TextInput source={'gtin'} />
      <TextInput source={'label'} />
      <TextInput source={'matnr'} />
      <TextInput source={'ean'} />
      <NumberInput source={'inputhId'} />
      <NumberInput source={'menge'} />
      <NumberInput source={'ordernum'} />
      {/* <TextInput source={'path'} /> */}
      <TextInput source={'serialNumber'} />
      <TextInput source={'pdfPath'} />
      <TextInput source={'csvPath'} />
      <TextInput source={'itemCode'} />
      <TextInput source={'userName'} />
      <emissionType.Input source={'emissionType'} />
      <mrkStatusSystem.Input source={'mrkStatusAfterPrint'} />
      <BooleanInput source={'importPrint'} />
    </SimpleForm>
  </Edit>
);

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} {...props}>
    <TextField source={'id'} />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={'id'} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={'id'} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Create: ResourceCreate,
  Edit: ResourceEdit,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,
  LinkToList,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    edit: ResourceEdit,
    create: ResourceCreate,
    icon: ResourceIcon,
  },
  menuConfig: {
    name: resourceConfig.name,
    icon: ResourceIcon,
  },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {
      actionDate: {
        type: 'dateTime',
      },
      cancellationDocDate: {
        type: 'dateTime',
      },
      documentDate: {
        type: 'dateTime',
      },
      orderDate: {
        type: 'dateTime',
      },
      processedDate: {
        type: 'dateTime',
      },
    },
    filters: {
      actionDate: {
        type: 'dateTime',
      },
      cancellationDocDate: {
        type: 'dateTime',
      },
      documentDate: {
        type: 'dateTime',
      },
      orderDate: {
        type: 'dateTime',
      },
      processedDate: {
        type: 'dateTime',
      },
    },
  },
};
