import React from 'react';
import {
  translate,
  Datagrid,
  Edit,
  Create,
  // EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  SimpleShowLayout,
  Show,
  ShowButton,
  Filter,
  DateField,
} from 'react-admin';
import BookmarkIcon from '@material-ui/icons/Bookmark';

import resourceLocales from './locales';
import resourceConfig from './config';
import docLink from '../docLink';
import docLinkType from '../docLinkType';
import LinkToList, { LinkToRelatedList } from './LinkToList';
import exporter from '../../../../utils/exporter';

const ResourceIcon = BookmarkIcon;

/* eslint-disable */
const ResourceListFilter = (props) => (
  <Filter {...props}>
    <TextInput source={'ids'} />
    <TextInput source={'documentLinkId'} />
    <docLinkType.Input source={'documentLinkType'} />
  </Filter>
);

const ResourceList = ({ classes, ...props }) => (
  <List
    // filters={<ResourceListFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    exporter={exporter}
    {...props}>
    <Datagrid>
      <TextField source={'id'} />
      <docLink.Field typeField={'documentType'} idField={'documentId'} />
      <TextField source={'status'} />
      <DateField source={'operationDate'} />
      <TextField source={'uit'} />
      <ShowButton />
    </Datagrid>
  </List>
);

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.name}
    &quot;
  </span>
));

const ResourceCreate = (props) => (
  <Create {...props}>
    <SimpleForm></SimpleForm>
  </Create>
);

const ResourceEdit = (props) => (
  <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
    <SimpleForm redirect={false}></SimpleForm>
  </Edit>
);

const ResourceShow = (props) => (
  <Show {...props} title={<ResourceTitle />}>
    <SimpleShowLayout>
      <TextField source={'id'} />
      <TextField source={'uit'} />
      <docLink.Field typeField={'documentType'} idField={'documentId'} />
      <TextField source={'documentType'} />
      <TextField source={'documentId'} />
      <TextField source={'status'} />
      <DateField source={'operationDate'} />
      <TextField source={'goodsBrand'} />
      <TextField source={'goodsEan'} />
      <TextField source={'goodsName'} />
      <TextField source={'warehouseUniqName'} />
    </SimpleShowLayout>
  </Show>
);

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} {...props}>
    <TextField source="id" />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={'id'} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={'id'} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Create: ResourceCreate,
  Edit: ResourceEdit,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,
  LinkToList: LinkToList,
  LinkToRelatedList: LinkToRelatedList,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    // edit: ResourceEdit,
    // create: ResourceCreate,
    show: ResourceShow,
    icon: ResourceIcon,
  },
  // menuConfig: {
  //     name: resourceConfig.name,
  //     icon: ResourceIcon,
  // },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    listPath: resourceConfig.apiPath,
    disableSort: true,
    mapping: {
      outputList: (data) => ({
        total: 999999,
      }),
    },
    schema: {},
  },
};
