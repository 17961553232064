import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Doc M',
      enums: {
        certType: {
          CONFORMITY_CERTIFICATE: 'Сертификат соответствия',
          CONFORMITY_DECLARATION: 'Декларация соответствия',
        },
      },
      fields: {
        sntins: 'SNTINS',
        docPId: 'Родительская позиция',
        certType: 'Тип сертификата',
        certDate: 'Дата сертификата',
      },
    },
  },
};
