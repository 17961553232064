import React, { Fragment } from 'react';
import {
  translate,
  Datagrid,
  Edit,
  Create,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Filter,
  BulkDeleteButton,
  CreateButton,
  ExportButton,
  BooleanInput,
  DateField,
} from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import DateInput from '../../../../components/DateInput';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';

import LinkToList from './LinkToList';
import returnP from '../returnP';
import SendRemarkButton from './SendRemarkButton';
import resourceLocales from './locales';
import resourceConfig from './config';
import documentRowStyle from '../../../../utils/ui/documentRowStyle';
import errorModel from '../errorModel';
import objectSendModel from '../objectSendModel';
import docStatusSystem from '../docStatusSystem';
import docStatus from '../docStatus';
import directionType from '../directionType';
import documentImport from '../documentImport';
import exporter from '../../../../utils/exporter';
import certificateType from '../certificateType';
import returnPrimaryDocType from '../returnPrimaryDocType';
import returnType from '../returnType';
import docUpdate from '../docUpdate';
import docCheckStatus from '../docCheckStatus';
import PostPagination from '../../../../utils/pagination';
import ExportDocButton from '../docH/ExportDocButton';
const ResourceIcon = BookmarkIcon;

const listStyles = {};

const ResourceBulkActionButtons = (props) => (
  <Fragment>
    <docUpdate.Button {...props} bulk docType={'RETURN'} />
    <BulkDeleteButton {...props} />
  </Fragment>
);

const ResourceListFilter = (props) => (
  <Filter {...props}>
    <TextInput source={'ids'} />
    <TextInput source={'guids'} />
    <TextInput source={'docnums'} />
  </Filter>
);

const ChildrenPButton = (props) => {
  return (
    <returnP.LinkToList
      label={`resources.${resourceConfig.name}.returnP`}
      filter={{
        overEntityIds: props.record.id,
      }}
    />
  );
};

const ResourceActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  translate,
}) => (
  <Toolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <documentImport.OpenButton params={{ docType: 'RETURN' }} />
    <CreateButton basePath={basePath} to={`${basePath}/create`} />
    <ExportDocButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
  <List
    {...props}
    sort={{ field: 'id', order: 'DESC' }}
    filters={<ResourceListFilter />}
    actions={<ResourceActions />}
    bulkActionButtons={<ResourceBulkActionButtons />}
    exporter={exporter}
    pagination={<PostPagination />}>
    <Datagrid rowStyle={documentRowStyle}>
      <TextField source={'id'} />

      <returnType.Field source={'returnType'} />
      <TextField source={'primaryDocumentNumber'} />
      <DateField source={'processedDate'} />

      <TextField source={'guid'} />
      <docCheckStatus.Field source={'checkStatus'} />

      <errorModel.LinkToRelatedList type={'RETURN'} />
      <objectSendModel.LinkToRelatedList type={'RETURN'} />
      <SendRemarkButton />
      <ChildrenPButton />
      <EditButton />
    </Datagrid>
  </List>
));

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.name}
    &quot;
  </span>
));

const ResourceCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source={'actionId'} />
      <DateInput source={'certificateDate'} />
      <TextInput source={'certificateNumber'} />
      <certificateType.Input source={'certificateType'} />
      <TextInput source={'checkStatus'} />
      <TextInput source={'description'} />
      <directionType.Input source={'direction'} />
      <docStatus.Input source={'docStatus'} />
      <TextInput source={'docnum'} />
      <TextInput source={'documentId'} />
      <docStatusSystem.Input source={'documentSystemStatus'} />
      <TextInput source={'errors'} />
      <TextInput source={'guid'} />
      <BooleanInput source={'paid'} />
      <TextInput source={'primaryDocumentCustomName'} />
      <DateInput source={'primaryDocumentDate'} />
      <TextInput source={'primaryDocumentNumber'} />
      <returnPrimaryDocType.Input source={'primaryDocumentType'} />
      <DateInput source={'processedDate'} />
      <TextInput source={'receiverId'} />
      <TextInput source={'requestId'} />
      <returnType.Input source={'returnType'} />
      <TextInput source={'subjectId'} />
      <TextInput source={'tradeParticipantInn'} />
    </SimpleForm>
  </Create>
);

const ResourceEdit = (props) => (
  <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
    <SimpleForm redirect={false}>
      <TextField source={'id'} />

      <TextInput source={'actionId'} />
      <DateInput source={'certificateDate'} />
      <TextInput source={'certificateNumber'} />
      <certificateType.Input source={'certificateType'} />
      <TextInput source={'checkStatus'} />
      <TextInput source={'description'} />
      <directionType.Input source={'direction'} />
      <docStatus.Input source={'docStatus'} />
      <TextInput source={'docnum'} />
      <TextInput source={'documentId'} />
      <docStatusSystem.Input source={'documentSystemStatus'} />
      <TextInput source={'errors'} />
      <TextInput source={'guid'} />
      <BooleanInput source={'paid'} />
      <TextInput source={'primaryDocumentCustomName'} />
      <DateInput source={'primaryDocumentDate'} />
      <TextInput source={'primaryDocumentNumber'} />
      <returnPrimaryDocType.Input source={'primaryDocumentType'} />
      <DateInput source={'processedDate'} />
      <TextInput source={'receiverId'} />
      <TextInput source={'requestId'} />
      <returnType.Input source={'returnType'} />
      <TextInput source={'subjectId'} />
      <TextInput source={'tradeParticipantInn'} />
    </SimpleForm>
  </Edit>
);

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} {...props}>
    <TextField source={'id'} />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={'id'} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={'id'} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Create: ResourceCreate,
  Edit: ResourceEdit,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,
  LinkToList,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    edit: ResourceEdit,
    create: ResourceCreate,
    icon: ResourceIcon,
  },
  menuConfig: {
    name: resourceConfig.name,
    icon: ResourceIcon,
  },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {
      certificateDate: {
        type: 'dateTime',
      },
      primaryDocumentDate: {
        type: 'dateTime',
      },
      processedDate: {
        type: 'dateTime',
      },
    },
    filters: {
      certificateDate: {
        type: 'dateTime',
      },
      primaryDocumentDate: {
        type: 'dateTime',
      },
      processedDate: {
        type: 'dateTime',
      },
    },
  },
};
