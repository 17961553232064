import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Возврат |||| Возвраты',
      returnP: 'Позиции',
      sendOut: {
        action: 'Отправить',
        successMessage: 'Успешно',
        errorMessage: 'Ошибка',
      },
      fields: {
        certificateDate: 'Дата сертификата',
        primaryDocumentDate: 'Дата главного документа',
        processedDate: 'Дата обработки',
        checkStatus: 'Статус ИСМП',
        actionId: 'Id операции',
        certificateNumber: 'Номер сертификата',
        certificateType: 'Тип сертификата',
        description: 'Описание',
        direction: 'Направление',
        docStatus: 'Статус',
        docnum: 'Номер документа',
        documentId: 'Id документа',
        documentSystemStatus: 'Системный статус',
        errors: 'Ошибка',
        guid: 'GUID',
        paid: 'Оплачено',
        primaryDocumentCustomName: 'Имя основного документа',
        primaryDocumentNumber: 'Номер основного документа',
        primaryDocumentType: 'Тип основного документа',
        receiverId: 'Id получателя',
        requestId: 'Id запроса',
        returnType: 'Тип возврата',
        subjectId: 'Id темы',
        tradeParticipantInn: 'ИНН участника',

        guids: 'GUID (Список)',
        docnums: 'Номер документа (Список)',
      }
    }
  }
}