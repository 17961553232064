import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import download from 'downloadjs';
import { translate, SimpleForm, Toolbar, SaveButton, showNotification } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import qs from 'qs';
import { withRouter } from 'react-router';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Divider } from '@material-ui/core';
import resourceConfig from './config';
import request from '../../../../network/request';
import { paramToDictionary } from '../../../../utils';
import xlsBrokerTemplateEnum from '../xlsBrokerTemplateEnum';
import appConfig from '../../../../appConfig';
import moment from 'moment';

const styles = {
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
  toolbar: { background: 'none' },
  loader: {
    marginLeft: 15
  }
};

const FormToolbar = translate(({ translate, ...props }) => (
  <Toolbar
    style={styles.toolbar}
    {...props}
  >
    <SaveButton
      label={translate(`resources.${resourceConfig.name}.executeAction`)}
      redirect={false}
      submitOnEnter={true}
    />
    {props.saving &&
      <div style={styles.loader}>
        <CircularProgress />
      </div>
    }
  </Toolbar>
))

class AppModule extends Component {
  defaultParams = {
  }

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      executing: false,
      files: [],
      params: {
        ...this.defaultParams
      }
    };
  }

  componentDidMount = () => {
    this.checkLocation();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.location.search !== prevProps.location.search) {
      this.checkLocation();
    }
  }

  checkLocation = () => {
    if (this.props.location.search.length < 2) {
      this.resetState();
      return;
    }
    const params = qs.parse(this.props.location.search.slice(1));
    if (!(resourceConfig.urlParamName in params)) {
      this.resetState();
      return;
    }
    if (this.state.show) {
      return;
    }
    const data = paramToDictionary(params[resourceConfig.urlParamName]);

    this.setState({
      show: true,
      params: {
        ...this.defaultParams,
        ...data
      }
    });
  }

  resetState = () => {
    this.setState({
      show: false,
      executing: false,
    });
    setTimeout(this.afterClose, 500);
  }

  afterClose = () => {
    this.setState({
      files: [],
      params: {
        ...this.defaultParams
      }
    })
  }

  execute = async (formData) => {

    this.setState({
      executing: true
    });
    const {
      type,
      ...restData
    } = formData
    let data = {
      ...restData
    }
    if (this.state.params) {
      data = {
        ...data,
        ...this.state.params,
      }
    }

    if (data.dateInvoiceByDate) {
      data.dateInvoiceByDate = moment(data.dateInvoiceByDate).format(appConfig.data.apiDateFormat);
    }
    if (data.invoiceAtkDateByDate) {
      data.invoiceAtkDateByDate = moment(data.invoiceAtkDateByDate).format(appConfig.data.apiDateFormat);
    }

    if (data.invoiceAtkDate) {
      data.invoiceAtkDate = moment(data.invoiceAtkDate).format(appConfig.data.apiDateTimeFormat);
    }
    if (data.dateInvoice) {
      data.dateInvoice = moment(data.dateInvoice).format(appConfig.data.apiDateTimeFormat);
    }
    if (data.emmissionDate) {
      data.emmissionDate = moment(data.emmissionDate).format(appConfig.data.apiDateTimeFormat);
    }
    if (data.emissionDateFrom) {
      data.emissionDateFrom = moment(data.emissionDateFrom).format(appConfig.data.apiDateTimeFormat);
    }
    if (data.emissionDateTo) {
      data.emissionDateTo = moment(data.emissionDateTo).format(appConfig.data.apiDateTimeFormat);
    }
    if (data.producedDate) {
      data.producedDate = moment(data.producedDate).format(appConfig.data.apiDateTimeFormat);
    }
    if (data.dateGtd) {
      data.dateGtd = moment(data.dateGtd).format(appConfig.data.apiDateTimeFormat);
    }
    if (data.statusChangeDate) {
      data.statusChangeDate = moment(data.statusChangeDate).format(appConfig.data.apiDateTimeFormat);
    }
    if (data.timestampDate) {
      data.timestampDate = moment(data.timestampDate).format(appConfig.data.apiDateTimeFormat);
    }

    let response = null;
    let files = [];
    try {
      response = await request({
        apiService: 'shoes',
        path: '/admin/mrk/func/xls',
        urlParams: {
          type,
        },
        body: JSON.stringify(data),
        method: 'POST',
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      
      if (response.ok) {
        if (Array.isArray(response.data)) {
          const guidInFileNameRegexp = /_[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}.([a-z]{1,6})$/i;
          files = response.data.map(fileName => ({
            fileName,
            prettyName: fileName.replace(guidInFileNameRegexp, '.$1') // Удаляем GUID из файла для красоты (MRK-793)
          }));
        }
      }
    } catch (e) {
      console.error(e);
    }
    if (response === null || !response.ok) {
      this.props.showNotification(`resources.${resourceConfig.name}.errorMessage`, 'error');
    } else {
      // this.props.showNotification(`resources.${resourceConfig.name}.successMessage`, 'success');
      // this.props.history.goBack();
    }

    this.setState({
      files,
      executing: false
    });
  }

  handleCloseClick = () => {
    this.props.history.goBack();
  }

  downloadFile = async (file) => {
    let data = {
      path: file.fileName,
    };
    const response = await request({
      apiService: 'shoes',
      path: '/admin/xls/func/load',
      urlParams: data,
      method: 'GET',
      responseType: 'blob',
    })

    if (!response.ok) {
      throw new Error()
    }

    const blob = response.data;
    const fileName = file.prettyName;
    const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    download(blob, fileName, mimeType);
  }


  render() {
    const {
      translate
    } = this.props;
    const {
      files,
    } = this.state;

    return (
      <Drawer
        anchor={'right'}
        open={this.state.show}
        onClose={this.handleCloseClick}
      >
        <SimpleForm
          defaultValue={this.state.params}
          save={this.execute}
          toolbar={<FormToolbar />}
          saving={this.state.executing}
          style={{ width: 600, maxWidth: '90vw'}}
        >
          <Typography
            variant={'title'}
          >
            {translate(`resources.${resourceConfig.name}.title`)}
          </Typography>

          <xlsBrokerTemplateEnum.Input source={'type'} />

          {files && (
              <List dense fullWidth>
                {files.map((file, index) => (
                  <Fragment key={file}>
                    {index > 0 && (<Divider />)}
                    <ListItem>
                      <ListItemText
                        primary={file.prettyName}
                      />
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => this.downloadFile(file)}>
                          <DownloadIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Fragment>
                ))}
              </List>
            )}
        </SimpleForm>
      </Drawer>
    )
  }
}

export default compose(
  withRouter,
  connect(null, { showNotification }),
  translate,
  withStyles(styles)
)(AppModule);
