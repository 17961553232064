import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Articles Map',
      fields: {
        gtin: 'gtin',
        itemCode: 'itemCode',
        manufacturingItem: 'manufacturingItem',
        irreducibleStock: 'irreducibleStock',
        irreducibleStockCount: 'irreducibleStockCount',
      }
    }
  }
}