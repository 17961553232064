import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        RECEIPT: 'Чек',
        CORRECTION_RECEIPT: 'Чек коррекции',
        SALES_RECEIPT: 'Чек продажи',
        CONSIGNMENT_NOTE: 'Товарная накладная',
        UTD: 'Универсальный передаточный документ',
        CUSTOMS_DECLARATION: 'Таможенная декларация',
        DESTRUCTION_ACT: 'Акт списания',
        OTHER: 'Другое',
      },
    }
  }
}
