import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS } from 'react-admin';
// import qs from 'qs';
import auth from '../auth';
// import request from './request';
import appCore from '../appCore';
// import appConfig from '../appConfig';

const onLogout = async () => {
    // localStorage.removeItem('accesstoken');
    appCore.cleanParams();
    await auth.logout();
}

export const authProviderHandle = async (type, params) =>  {
    return new Promise(async(resolve, reject) => {
        if (type === AUTH_LOGIN) {
            // const { username, password } = params;
            // try {
            //     const response = await request({
            //         apiService: 'keycloak',
            //         path: '/auth/realms/marking/protocol/openid-connect/token',
            //         method: 'POST',
            //         // contentType: 'form',
            //         headers: {
            //             'content-type': 'application/x-www-form-urlencoded',
            //         },
            //         body: qs.stringify({
            //             grant_type: 'password',
            //             client_id: appConfig.data.services.keycloak.clientId,
            //             client_secret: appConfig.data.services.keycloak.clientSecret,
            //             scope: 'ui',
            //             username,
            //             password,
            //         })
            //     });
            //     if (response.ok && response.data.access_token) {
            //         localStorage.setItem('accesstoken', response.data.access_token);
            //         resolve();
            //     } else {
            //         reject()
            //     }
            // } catch(e) {
            //     reject(e);
            // }
        } else if (type === AUTH_LOGOUT) {
            await onLogout();
            resolve();
        } else if (type === AUTH_ERROR) {
            if (params.status === 401) {
                await onLogout();
                reject();
            } else {
                resolve();
            }
        } else if (type === AUTH_GET_PERMISSIONS) {
            // const accesstoken = localStorage.getItem('accesstoken') || null;
            // if (accesstoken === null) {
            //     reject();
            //     return;
            // }
            resolve([]);
        } else if (type === AUTH_CHECK) {

            const isAuthorized = await auth.isAutorized();
            if (isAuthorized) {
                resolve({});
                return true;
            }
            reject();
            // const accesstoken = localStorage.getItem('accesstoken') || null;
            // if (accesstoken === null) {
            //     reject();
            //     return;
            // }
            // resolve();
            // try {
            //     const response = await request({
            //         apiService: 'auth',
            //         path: '/users/current'
            //     });
            //     if (response.ok) {
            //         resolve();
            //     } else {
            //         reject();
            //     }
            // } catch(e) {
            //     reject(e);
            // }
        } else {
            reject('Unkown method');
        }
    });
};

export default {
    login: (params) => authProviderHandle(AUTH_LOGIN, params),
    logout: (params) => authProviderHandle(AUTH_LOGOUT, params),
    checkAuth: (params) => authProviderHandle(AUTH_CHECK, params),
    checkError: (params) => authProviderHandle(AUTH_ERROR, params),
    getPermissions: (params) => authProviderHandle(AUTH_GET_PERMISSIONS, params),
};