import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  translate,
  SimpleForm,
  Toolbar,
  SaveButton,
  showNotification,
  FileInput,
  FileField,
  required,
  refreshView as refreshViewAction,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';
import qs from 'qs';
import { withRouter } from 'react-router';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import resourceConfig from './config';
import { paramToDictionary } from '../../../../utils';
import request from '../../../../network/request';
import Snackbar from '../../../../components/Snackbar';

const styles = {
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
  toolbar: { background: 'none' },
  loader: {
    marginLeft: 15,
  },
};

const FormToolbar = translate(({ translate, ...props }) => (
  <Toolbar style={styles.toolbar} {...props}>
    <SaveButton
      label={translate(`resources.${resourceConfig.name}.executeAction`)}
      redirect={false}
      submitOnEnter={true}
    />
    {props.saving && (
      <div style={styles.loader}>
        <CircularProgress />
      </div>
    )}
  </Toolbar>
));

class AppModule extends Component {
  defaultParams = {
    csv: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      error: null,
      executing: false,
      params: {
        ...this.defaultParams,
      },
    };
  }

  componentDidMount = () => {
    this.checkLocation();
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.location.search !== prevProps.location.search) {
      this.checkLocation();
    }
  };

  checkLocation = () => {
    if (this.props.location.search.length < 2) {
      this.resetState();
      return;
    }
    const params = qs.parse(this.props.location.search.slice(1));

    if (!(resourceConfig.urlParamName in params)) {
      this.resetState();
      return;
    }
    if (this.state.show) {
      return;
    }
    const data = paramToDictionary(params[resourceConfig.urlParamName]);

    this.setState({
      show: true,
      params: {
        ...this.defaultParams,
        ...data,
      },
    });
  };

  resetState = () => {
    this.setState({
      show: false,
      executing: false,
    });
    setTimeout(this.afterClose, 500);
  };

  afterClose = () => {
    this.setState({
      params: {
        ...this.defaultParams,
      },
    });
  };

  execute = async (formData) => {
    const { translate } = this.props;
    this.setState({
      executing: true,
    });
    let data = {
      ...formData,
    };

    let response = null;
    try {
      var fetchBody = new FormData();

      if (data.file && data.file.rawFile) {
        fetchBody.append('file', data.file.rawFile);
      }
      delete data.file;
      for (var name in data) {
        fetchBody.append(name, data[name]);
      }
      const params = qs.parse(this.props.location.search.slice(1));
      let id = JSON.parse(params.filter).overEntityIds;

      fetchBody.append('id', id);

      response = await request({
        apiService: resourceConfig.apiService,
        path: resourceConfig.apiPath,
        body: fetchBody,
        urlParams: id,
        method: 'POST',
      });
    } catch (e) {
      console.error(e);
    }
    let error = null;
    if (response === null || !response.ok) {
      this.props.showNotification(`resources.${resourceConfig.name}.errorMessage`, 'error');
      if (response !== null) {
        error = response.data;
      }
    } else if (response.data.badString && response.data.badString.length > 0) {
      error = {
        message: `${translate(`resources.${resourceConfig.name}.addedItemsCount`)}: ${
          response.data.addedString.length
        };
${translate(`resources.${resourceConfig.name}.errorItems`)}:
${response.data.badString.map((x) => `- ${x}`).join('\n')}`,
      };
    } else {
      this.props.showNotification(
        translate(`resources.${resourceConfig.name}.successMessage`, {
          count: response.data.addedString.length,
        }),
        'success',
      );
      this.props.history.goBack();
    }

    this.setState(
      {
        error,
        executing: false,
      },
      this.props.refreshView,
    );
  };

  handleCloseClick = () => {
    this.props.history.goBack();
  };

  render() {
    const { translate } = this.props;

    return (
      <Drawer anchor={'right'} open={this.state.show} onClose={this.handleCloseClick}>
        <SimpleForm
          defaultValue={this.state.params}
          save={this.execute}
          toolbar={<FormToolbar />}
          saving={this.state.executing}
          style={{ width: 1200, maxWidth: '90vw' }}>
          <Typography variant={'title'}>
            {translate(`resources.${resourceConfig.name}.title`)}
          </Typography>

          <FileInput source="file" validate={required()}>
            <FileField source="src" title="title" />
          </FileInput>

          {this.state.error && (
            <Snackbar
              style={{ marginTop: 20, marginBottom: 40, maxWidth: '100%' }}
              textStyle={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}
              showIcon={false}
              variant="error"
              message={this.state.error.exMessage || this.state.error.message}
              fullWidth
            />
          )}
        </SimpleForm>
      </Drawer>
    );
  }
}

export default compose(
  withRouter,
  connect(null, {
    showNotification,
    refreshView: refreshViewAction,
  }),
  translate,
  withStyles(styles),
)(AppModule);
