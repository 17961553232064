import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Печать',
      title: 'Печать',
      alreadyExistTitle: 'Такой элемент уже существует',
      alreadyExistText: 'Вы можете распечатать этот файл',
      alreadyExistAction: 'Печать',
      findCount: 'Найдено элементов: %{count}',
      executeAction: 'Печать',
      errorMessage: 'Ошибка',
      errorMessageKMNotFound: 'Код маркировки не найден',
      warnFoundKMNotAll: 'Внимание: найдено %{found} кодов из %{total}',
      successMessage: 'Запрос успешно отправлен',
      createAnotherAction: 'Следующая этикетка',
      downloadAs: 'Формат файла',
      asPdf: 'PDF',
      asCsv: 'CSV',
      searchBySerialNumber: 'Печать по серийному номеру',
      enterGtin: 'Введите GTIN',
      searching: 'Поиск...',
      itemNotFound: 'Файл не найден',

      canNotFoundEANOrGTIN: 'Нет соответствия в ean info или GTIN отсутствует в справочнике Товаров“',
      theProductIsNotRegisteredInTheDeclaration: 'EAN нет в декларации или остаток кодов равен 0',
      setGTINOrOrdernum: 'Укажите GTIN или EAN или номер заказа',
      incorrectLabelTemplate: 'Некорректный шаблон или путь шаблона',
      notFoundArticleMapByItemCode: 'Не найден GTIN по введенному Item code',
      
      fields: {
        companyUniqName: 'Компания в системе',
        creatorUsername: 'Создатель',
        gtin: 'GTIN',
        label: 'Шаблон',
        matnr: 'Материал',
        ean: 'EAN',
        inputhId: 'Ввод в оборот',
        ordernum: 'Номер заказа',
        serialNumber: 'Серийный номер',
        emissionType: 'Тип эмиссии',
        mrkStatusAfterPrint: 'Внутренний статус',
        importPrint: 'Маркировка импорта',
        menge: 'Количество',
        itemCode: 'Код товара',
      },
    }
  }
}