import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, showNotification, refreshView as refreshViewAction } from 'react-admin';

import request from '../../../../network/request';
import resourceConfig from './config';

class AcceptAll extends Component {
  state = {
    executing: false,
  };
  handleClick = async () => {
    const { showNotification, selectedIds, refreshView } = this.props;

    this.setState({
      executing: true,
    });
    let data = {
      docIds: selectedIds.join(','),
      accepted: true,
    };

    let response = null;
    try {
      response = await request({
        apiService: 'shoes',
        path: '/admin/doc/func/change_acceptance/list',
        urlParams: data,
        method: 'POST',
      });
    } catch (e) {
      console.error(e);
    }
    if (response === null || !response.ok) {
      showNotification(`resources.${resourceConfig.name}.acceptAll.errorMessage`, 'error');
    } else {
      showNotification(`resources.${resourceConfig.name}.acceptAll.successMessage`, 'success');
    }

    this.setState(
      {
        executing: false,
      },
      refreshView,
    );
  };

  render() {
    return (
      <Button
        label={`resources.${resourceConfig.name}.acceptAll.action`}
        disabled={this.state.executing}
        onClick={this.handleClick}
      />
    );
  }
}

export default connect(undefined, {
  showNotification,
  refreshView: refreshViewAction,
})(AcceptAll);
