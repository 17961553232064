import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Inventory',
      inventoryP: 'P records',
      send: {
        action: 'Send',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      fields: {
        id: '#',
        inventory_date: 'Inventory date',
        processedDate: 'Processed date',
      },
    }
  }
}