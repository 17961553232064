import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        LOAD_GOODS: 'загрузка товаров',
        LOAD_DOC: 'синхронизация',
        LOAD_KM_LIST: 'загрузка кодов маркировки',
        SEND_ORDER: 'отправка заказа',
        PRINT: 'печать',
        UPDATE_ORDER_STATUS: 'обновление статуса заказа',
        PRINT_KM: 'печать',
        SEND_INPUT: 'отправка ввода в оборт',
        UPDATE_KM: 'обновление кодов',
        SEND_DOC: 'отправка документа',
        SEND_OUT: 'отправка вывода из оборота',
        SEND_REMARK: 'отправка перемаркировки',
        SEND_AGGR: 'отправка агрегации',
        LOAD_LABEL: 'загрузка щаблона',
        UPDATE_GOOD: 'обновление товара',
        SEND_DESC_REST: 'отправка описания остатков',
        SEND_RETURN: 'отправка возврата',
        CHECK_DOCUMENT: 'проверка документа',
      },
    }
  }
}
