export default {
  name: 'marking--nkRequestH',
  apiService: 'shoes',
  apiPath: '/admin/nk_request_h',
  enums: {
    nkRequestType: ['CREATE', 'EDIT'],
    nkStatus: ['REJECTED', 'RECEIVED', 'MODERATED', 'SIGNED', 'DRAFT'],
    goodDetailedStatus: ['DRAFT', 'MODERATION', 'ERRORS', 'NOTSIGNED', 'PUBLISHED'],
  },
};
