import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Aggr |||| Агрегация',
      aggrP: 'Записи',
      send: {
        action: 'Отправить',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      fields: {
        dateCreate: 'Дата создания',
        dateSend: 'Дата отправки',
        processedDate: 'Дата обработки',
        actionId: 'Id действия',
        companyUniqName: 'Имя компании',
        creatorUsername: 'Имя создателя',
        direction: 'Направление',
        docStatus: 'Статус документа',
        docnum: 'Номер документа',
        documentId: 'Id документа',
        // documentLinkId: 'Id документа',
        // documentLinkType: 'Тип документа',
        documentSystemStatus: 'Статус документа',
        errorModelId: 'Id ошибки',
        participantId: 'Id участника',
        receiverId: 'Id получателя',
        reportId: 'Id отчёта',
        requestId: 'Id запроса',
        subjectId: 'Id субъекта',
        typedoc: 'Тип документа',
        uitu: 'Код агрегата',
        checkStatus: 'Статус ИСМП',
      }
    }
  }
}