import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate, Button, showNotification } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';
import qs from 'qs';
import { withRouter } from 'react-router';
import resourceConfig from './config';
import { paramToDictionary } from '../../../../utils';
import rest from '../../../../network/rest'

const styles = {
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
  toolbar: { background: 'none' },
  loader: {
    marginLeft: 15
  }
};


class ExportDocButton extends Component {
  defaultParams = {
  }

  constructor(props) {
    super(props);


    this.state = {
      show: false,
      error: null,
      executing: false,
      params: {
        ...this.defaultParams
      },
      exportProps: {},
      filters: props.filters
    };
  }

  componentDidMount = () => {
    this.checkLocation();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.location.search !== prevProps.location.search) {
      this.checkLocation();
    }
    localStorage.setItem('propsForExport', JSON.stringify(prevProps))
  }

  checkLocation = () => {
    if (this.props.location.search.length < 2) {
      this.resetState();
      return;
    }
    const params = qs.parse(this.props.location.search.slice(1));
    if (!(resourceConfig.urlParamName in params)) {
      this.resetState();
      return;
    }
    if (this.state.show) {
      return;
    }
    const data = paramToDictionary(params[resourceConfig.urlParamName]);

    this.setState({
      show: true,
      params: {
        ...this.defaultParams,
        ...data
      }
    });
  }

  resetState = () => {
    this.setState({
      show: false,
      executing: false,
    });
    setTimeout(this.afterClose, 500);
  }

  afterClose = () => {
    this.setState({
      params: {
        ...this.defaultParams
      }
    })
  }

  execute = async (formData) => {

    this.setState({
      executing: true
    });
    let data = {
      ...formData
    }

    let propFromStorage =  localStorage.getItem('propsForExport')

    rest.export(JSON.parse(propFromStorage))

    this.props.showNotification(`resources.${resourceConfig.name}.exportDoc.successMessage`, 'success');

    this.setState({
      executing: false
    });
  }
  
  render() {
    return (
      <Button
        label={`resources.${resourceConfig.name}.exportDoc.action`}
        disabled={this.state.executing}
        onClick={this.execute}
      />
    );
  }
}

export default compose(
  withRouter,
  connect(null, { showNotification }),
  translate,
  withStyles(styles)
)(ExportDocButton);
