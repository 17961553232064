import React from 'react';
import {
  translate,
  Datagrid,
  Edit,
  Create,
  // EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Filter,
  EditButton,
  CreateButton,

} from 'react-admin';
import qs from 'qs';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Toolbar from '@material-ui/core/Toolbar';

import InputH from '../inputH';
import resourceLocales from './locales';
import resourceConfig from './config';

import LinkToList from './LinkToList';
import certificateType from '../certificateType';
import DateInput from '../../../../components/DateInput';
import exporter from '../../../../utils/exporter';
import inputPChild from '../inputPChild';
import ExportDocButton from '../docH/ExportDocButton';
const ResourceIcon = BookmarkIcon;

const resourceToString = (x) => `#${x.id}`;

const listStyles = {};

const ResourceActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  translate,
}) => (
  <Toolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton
      basePath={basePath}
      to={`${basePath}/create?${
        filterValues && filterValues.overEntityIds ? `_inputHId=${filterValues.overEntityIds}` : ''
      }`}
    />
    <ExportDocButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

const ResourceListFilter = (props) => (
  <Filter {...props}>
    <InputH.Input source={'overEntityIds'} label="overEntityIds" />
    <TextInput source={'certificateDocument'} />
    <TextInput source={'certificate_document_date'} />
    <TextInput source={'certificate_document_number'} />
    <TextInput source={'docnum'} />
    <TextInput source={'pos_id'} />
    <TextInput source={'status'} />
    <TextInput source={'tnved_code'} />
    <TextInput source={'uit_code'} />
    <TextInput source={'uitu_code'} />
    <TextInput source={'ean'} />
  </Filter>
);

const ChildrenButton = (props) => {
  return (
    <inputPChild.LinkToList
      label={`resources.${resourceConfig.name}.inputPChild`}
      filter={{
        overEntityIds: props.record.id,
      }}
    />
  );
};

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
  <List
    {...props}
    filters={<ResourceListFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    actions={<ResourceActions />}
    exporter={exporter}>
    <Datagrid>
      <TextField source={'id'} />
      <TextField source={'docnum'} />
      <TextField source={'pos_id'} />
      <TextField source={'status'} />
      <TextField source={'tnved_code'} />
      <TextField source={'uit_code'} />
      <TextField source={'uitu_code'} />
      <InputH.Field source={'inputHId'} />
      <ChildrenButton />
      <EditButton />
    </Datagrid>
  </List>
));

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.name}
    &quot;
  </span>
));

const ResourceCreate = (props) => {
  const defaultData = {};
  if (props.location && props.location.search && props.location.search.length > 1) {
    const params = qs.parse(props.location.search.replace(/^\?/, ''));
    if (params && params._inputHId) {
      defaultData.inputHId = parseInt(params._inputHId);
    }
  }
  return (
    <Create {...props}>
      <SimpleForm>
        <InputH.Input source={'inputHId'} defaultValue={defaultData.inputHId} />
        <certificateType.Input source={'certificateDocument'} />
        <DateInput
          source={'certificate_document_date'}
          label={`resources.${resourceConfig.name}.fields.certificate_document_date`}
        />
        <TextInput source={'certificate_document_number'} />
        <TextInput source={'custom_valuegoods'} />
        <TextInput source={'docnum'} />
        <TextInput source={'goods_price'} />
        <TextInput source={'pos_id'} />
        <DateInput
          source={'productionDate'}
          label={`resources.${resourceConfig.name}.fields.productionDate`}
        />
        <TextInput source={'status'} />
        <TextInput source={'tax_vat'} />
        <TextInput source={'tnved_code'} />
        <TextInput source={'uit_code'} />
        <TextInput source={'uitu_code'} />
        <TextInput source={'ean'} />
        <TextInput source={'packType'} />
        <TextInput source={'productSize'} />
        <TextInput source={'color'} />
      </SimpleForm>
    </Create>
  );
};

const ResourceEdit = (props) => (
  <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
    <SimpleForm redirect={false}>
      <InputH.Input source={'inputHId'} />
      <certificateType.Input source={'certificateDocument'} />
      <DateInput
        source={'certificate_document_date'}
        label={`resources.${resourceConfig.name}.fields.certificate_document_date`}
      />
      <TextInput source={'certificate_document_number'} />
      <TextInput source={'custom_valuegoods'} />
      <TextInput source={'docnum'} />
      <TextInput source={'goods_price'} />
      <TextInput source={'pos_id'} />
      <DateInput
        source={'productionDate'}
        label={`resources.${resourceConfig.name}.fields.productionDate`}
      />
      <TextInput source={'status'} />
      <TextInput source={'tax_vat'} />
      <TextInput source={'tnved_code'} />
      <TextInput source={'uit_code'} />
      <TextInput source={'uitu_code'} />
      <TextInput source={'ean'} />
      <TextInput source={'packType'} />
      <TextInput source={'productSize'} />
      <TextInput source={'color'} />
    </SimpleForm>
  </Edit>
);

const ResourceStringField = ({ record }) => <span>{resourceToString(record)}</span>;

// const ResourceStringChipField = ({ record }) => (
//     <span>{resourceToString(record)}</span>
// )

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} {...props}>
    <ResourceStringField />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={resourceToString} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={resourceToString} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Create: ResourceCreate,
  Edit: ResourceEdit,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,
  LinkToList,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    edit: ResourceEdit,
    create: ResourceCreate,
    icon: ResourceIcon,
  },
  // menuConfig: {
  //     name: resourceConfig.name,
  //     icon: ResourceIcon,
  // },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {
      certificate_document_date: {
        type: 'dateTime',
      },
      productionDate: {
        type: 'dateTime',
      },
    },
  },
};
