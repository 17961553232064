

export default {
  enums: [
    { id: 'DEFECT' },
    { id: 'EXPIRY' },
    { id: 'QA_SAMPLES' },
    { id: 'PRODUCT_REACALL' },
    { id: 'COMPLAINTS' },
    { id: 'PRODUCT_TESTING' },
    { id: 'DEMO_SAMPLES' },
    { id: 'OTHER' },
    { id: 'DAMAGE_LOSS' },
    { id: 'DESTRUCTION' },
    { id: 'LIQUIDATION' },
    { id: 'CONFISCATION' },
    { id: 'OWN_USE_EXCISE' },
  ]
};