import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Task |||| Task manager ',
      errors: 'Errors',
      logs: 'Logs',
      fields: {
        periodValue:'Period value'
      }
    }
  }
}