import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        DEFECT: 'DEFECT',
        EXPIRY: 'EXPIRY',
        QA_SAMPLES: 'QA_SAMPLES',
        PRODUCT_REACALL: 'PRODUCT_REACALL',
        COMPLAINTS: 'COMPLAINTS',
        PRODUCT_TESTING: 'PRODUCT_TESTING',
        DEMO_SAMPLES: 'DEMO_SAMPLES',
        OTHER: 'OTHER',
        DAMAGE_LOSS: 'DAMAGE_LOSS',
        DESTRUCTION: 'DESTRUCTION',
        LIQUIDATION: 'LIQUIDATION',
        CONFISCATION: 'CONFISCATION',
        OWN_USE_EXCISE: 'OWN_USE_EXCISE',
      },
    }
  }
}