export default {
  enums: [
    { id: 'EMITTED' },
    { id: 'APPLIED' },
    { id: 'INTRODUCED' },
    { id: 'WRITTEN_OFF' },
    { id: 'WITHDRAWN' },
    { id: 'UNDEFINED' },
    { id: 'RETIRED' },
    // { id: 'DISAGGREGATION' },
    // { id: 'WAIT_FOR_CONTINUATION' },
  ]
};