import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'УПД подзаписи |||| УПД подзаписи M records',
      fields: {
        id: '#',
        updPId: 'Позиция УПД',
        packageIds: 'Уникальный идентификатор вторичной (потребительской)/третичной (заводской, транспортной) упаковки',
        units: 'Контрольный идентификационный знак',
      },
    }
  }
}