import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Status map',
      enums: {
        result: {
          'OK': 'OK',
          'NOTOK': 'NOTOK',
          'OVER': 'OVER',
          'SHORTFALL': 'SHORTFALL',
          'RE_GRADING': 'RE_GRADING',
          'CODE_NOT_FOUND': 'CODE_NOT_FOUND',
          'DIFFERENT_VENDOR_DATA': 'DIFFERENT_VENDOR_DATA',
        },
      },
      fields: {
        result: 'Result',
      }
    }
  }
}