import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'ERP Goods',
      fields: {
        docErpHId: 'docErpHId',
        certificate_date: 'certificate_date',
        certificate_number: 'certificate_number',
        certificate_type: 'certificate_type',
        description: 'description',
        mrk_system_status: 'mrk_system_status',
        num_goods: 'num_goods',
        paid: 'paid',
        pg: 'pg',
        primary_document_custom_name: 'primary_document_custom_name',
        primary_document_date: 'primary_document_date',
        primary_document_number: 'primary_document_number',
        primary_document_type: 'primary_document_type',
        product_cost: 'product_cost',
        product_tax: 'product_tax',
        quantity: 'quantity',
      },
    }
  }
}