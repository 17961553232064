import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Status report',
      title: 'Status report',
      executeAction: 'Submit',
      errorMessage: 'Error',
      successMessage: 'Request successfully sent',
      fields: {
        docType: 'Document type',
        emails: 'Emails',
        email: 'Email',
        file: 'File'
      },
    }
  }
}