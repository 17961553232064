import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      action: 'Проверить статус',
      successAllMessage: 'Запрос отправлен',
      successWithFailedMessage: 'Некоторые запросы не обработаны. Успешно обработаны %{success} из %{total}',
      errorMessage: 'Ошибка',
      errorOneMessage: 'Ошибка при отправке запроса для документа ID %{id}',
    }
  }
}