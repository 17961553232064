import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Return |||| Returns',
      returnP: 'Records',
      sendOut: {
        action: 'Send',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      fields: {
        certificateDate: 'Certificate date',
        primaryDocumentDate: 'Primary document date',
        processedDate: 'Processed date',
      }
    }
  }
}