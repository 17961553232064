import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Отчет доставки',
      title: 'Отчет доставки',
      executeAction: 'Подтвердить',
      errorMessage: 'Ошибка',
      successMessage: 'Запрос успешно отправлен',
      filterErrorMessage: 'Выберите только 1 документ',
    }
  }
}