export const darkTheme = {
  palette: {
    type: 'dark', // Switching the dark mode on is a single property value change.
    primary: {
      main: '#F0F0F0',
      light: '#FFFFFF',
      dark: '#C0C0C0',
      contrastText: '#101010',
    },
    secondary: {
      main: '#3F51B5',
      light: '#C5CAE9',
      dark: '#303F9F',
      contrastText: '#FFFFFF',
    },
  },
  overrides: {
    RaSidebar: {
      fixed: {
        '& .left-menu-container': {
          backgroundColor: '#424242',
        },
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: '#999',
      },
    },
  },
};

export const lightTheme = {
  palette: {
    primary: {
      main: '#03A9F4',
      light: '#B3E5FC',
      dark: '#0288D1',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#03A9F4',
      light: '#B3E5FC',
      dark: '#0288D1',
      contrastText: '#FFFFFF',
    },
  },
  overrides: {
    RaSidebar: {
      drawerPaper: {
        backgroundColor: 'rgba(175, 65, 84, 1)',
        color: 'rgba(175, 65, 84, 1)',
        height: '100%',
        boxShadow: '2px 0px 1px -1px rgba(0,0,0,0.2), 1px 0px 3px 0px rgba(0,0,0,0.1)',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '&$disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
    RaFormInput: {
      input: {
        width: 500,
        maxWidth: '100%',
      },
    },
  },
};
