import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'DRAFT': 'DRAFT',
        'PENDING': 'PENDING',
        'READY_TO_SEND': 'READY_TO_SEND',
        'REJECTED': 'REJECTED',
        'SENT': 'SENT',
        'SUCCESS': 'SUCCESS',
        'FAILED': 'FAILED',
        'PARTIALLY': 'PARTIALLY',
      },
    }
  }
}
