import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Documents (subrecords)',
      docM: 'M Records',
      fields: {
        id: 'ID',
        docM: 'M records',
      },
    },
  },
};
