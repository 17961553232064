import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Report P |||| Report P records',
      fields: {
        id: '#',
        reportHId: 'Документ',
        cryptoCode: 'Криптокод',
        gtin: 'GTIN',
        numpos: 'Номер позиции',
        serialNumber: 'Серийный номер',
        fullkm: 'Код маркировки'
      },
    }
  }
}