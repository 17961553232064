import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Remains',
      reportP: 'Records',
      fields: {
        id: '#',
      },
      send: {
        errorMessage: 'Error',
        successMessage: 'Success',
        action: 'Send'
      }
    }
  }
}