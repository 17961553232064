import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        // 'CANCELLATION_APPLIED': 'Списан после нанесения на товар',
        // 'REMARK_RETIRED': 'Перемаркирован',
        // 'RETIRED_CANCELLATION': 'Списан',
        // 'DONATION': 'Безвозмездная передача товара',
        // 'STATE_ENTERPRISE': 'Приобретение гос.предприятием',
        // 'NO_RETAIL_USE': 'Использование для собственных нужд покупателем',
        // 'BEYOND_EEC_EXPORT': 'Экспорт за пределы стран ЕАЭС',
        // 'REMOTE_SALE': 'Продажа по образцам, дистанционный способ продажи',
        // 'EEC_EXPORT': 'Экспорт в страны ЕАЭС',
        // 'RETURN': 'Возврат физическому лицу',
        // 'DAMAGE_LOSS': 'Утрата или повреждение',
        // 'DESTRUCTION': 'Уничтожение',
        // 'CONFISCATION': 'Конфискация',
        // 'LIQUIDATION': 'Ликвидация предприятия',
        'EMPTY': 'Значение отсутствует',
        'RESERVED_NOT_USED': 'Зарезервировано. Не использовать',
        'WAIT_SHIPMENT': 'Ожидает подтверждения приёмки',
        'EXPORTED': 'Использован для документов экспорта',
        'LOAN_RETIRED': 'Выведен из оборота по договору рассрочки',
        'REMARK_RETIRED': 'Выведен из оборота при перемаркировкe',
        'WAIT_TRANSFER_TO_OWNER': 'Ожидает передачу собственнику',
        'WAIT_REMARK': 'Товар ожидает перемаркировку',
        'RETIRED_CANCELLATION': 'Списание',
        'RETIRED_DONATION': 'Безвозмездная передача',
        'RETIRED_STATE_ENTERPRISE': 'Приобретение государственным предприятием',
        'RETIRED_NO_RETAIL_USE': 'Использование для собственных нужд покупателем',
        'RETIRED_BEYOND_EEC_EXPORT': 'Экспорт за пределы стран ЕАЭС',
        'RETIRED_REMOTE_SALE': 'Продажа по образцам, дистанционный способ продажи',
        'RETIRED_EEC_EXPORT': 'Экспорт в страны ЕАЭС',
        'RETIRED_RETURN': 'Возврат физическому лицу',
        'RETIRED_DAMAGE_LOSS': 'Утрата или повреждение',
        'RETIRED_DESTRUCTION': 'Уничтожение',
        'RETIRED_CONFISCATION': 'Конфискация',
      },
    }
  }
}