import React from 'react';
import {
  translate,
  Datagrid,
  Edit,
  Create,
  // EditButton,
  List,
  SimpleForm,
  TextField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Filter,
  EditButton,
  TextInput,
  NumberInput,
  CreateButton,
  ExportButton,
  BooleanInput,
  NumberField,
} from 'react-admin';
import qs from 'qs';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Toolbar from '@material-ui/core/Toolbar';
import DateInput from '../../../../components/DateInput';

import resourceLocales from './locales';
import resourceConfig from './config';

import LinkToList from './LinkToList';
import exporter from '../../../../utils/exporter';
import erpH from '../erpH';
import ExportDocButton from '../docH/ExportDocButton';

const ResourceIcon = BookmarkIcon;

const resourceToString = (x) => `#${x.id} (${x.docnum})`;

const listStyles = {};

const ResourceActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  translate,
}) => (
  <Toolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton
      basePath={basePath}
      to={`${basePath}/create?${
        filterValues && filterValues.overEntityIds ? `_docErpHId=${filterValues.overEntityIds}` : ''
      }`}
    />
    <ExportDocButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

const ResourceListFilter = (props) => (
  <Filter {...props}>
    <erpH.Input source={'overEntityIds'} label="overEntityIds" />
  </Filter>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
  <List
    {...props}
    filters={<ResourceListFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    actions={<ResourceActions />}
    exporter={exporter}>
    <Datagrid>
      <TextField source={'id'} />
      <erpH.Field source={'docErpHId'} />
      <TextField source={'num_goods'} />
      <NumberField source={'quantity'} />
      <NumberField source={'product_cost'} />
      <EditButton />
    </Datagrid>
  </List>
));

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.name}
    &quot;
  </span>
));

const ResourceCreate = (props) => {
  const defaultData = {};
  if (props.location && props.location.search && props.location.search.length > 1) {
    const params = qs.parse(props.location.search.replace(/^\?/, ''));
    if (params && params._docErpHId) {
      defaultData.docErpHId = parseInt(params._docErpHId);
    }
  }
  return (
    <Create {...props}>
      <SimpleForm>
        <erpH.Input source={'docErpHId'} defaultValue={defaultData.docErpHId} />
        <DateInput
          source={'certificate_date'}
          label={`resources.${resourceConfig.name}.fields.certificate_date`}
        />
        <TextInput source={'certificate_number'} />
        <TextInput source={'certificate_type'} />
        {/* "CONFORMITY_CERTIFICATE", */}

        <TextInput source={'description'} />
        <TextInput source={'mrk_system_status'} />
        {/* "NEW", */}

        <TextInput source={'num_goods'} />
        <BooleanInput source={'paid'} />
        <TextInput source={'pg'} />
        <TextInput source={'primary_document_custom_name'} />
        <DateInput
          source={'primary_document_date'}
          label={`resources.${resourceConfig.name}.fields.primary_document_date`}
        />
        <TextInput source={'primary_document_number'} />
        <TextInput source={'primary_document_type'} />
        {/* "RECEIPT", */}

        <NumberInput source={'product_cost'} />
        <NumberInput source={'product_tax'} />
        <NumberInput source={'quantity'} />
      </SimpleForm>
    </Create>
  );
};

const ResourceEdit = (props) => (
  <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
    <SimpleForm redirect={false}>
      <erpH.Input source={'docErpHId'} />
      <DateInput
        source={'certificate_date'}
        label={`resources.${resourceConfig.name}.fields.certificate_date`}
      />
      <TextInput source={'certificate_number'} />
      <TextInput source={'certificate_type'} />
      {/* "CONFORMITY_CERTIFICATE", */}

      <TextInput source={'description'} />
      <TextInput source={'mrk_system_status'} />
      {/* "NEW", */}

      <TextInput source={'num_goods'} />
      <BooleanInput source={'paid'} />
      <TextInput source={'pg'} />
      <TextInput source={'primary_document_custom_name'} />
      <DateInput
        source={'primary_document_date'}
        label={`resources.${resourceConfig.name}.fields.primary_document_date`}
      />
      <TextInput source={'primary_document_number'} />
      <TextInput source={'primary_document_type'} />
      {/* "RECEIPT", */}

      <NumberInput source={'product_cost'} />
      <NumberInput source={'product_tax'} />
      <NumberInput source={'quantity'} />
    </SimpleForm>
  </Edit>
);

const ResourceStringField = ({ record }) => <span>{resourceToString(record)}</span>;

// const ResourceStringChipField = ({ record }) => (
//     <span>{resourceToString(record)}</span>
// )

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} {...props}>
    <ResourceStringField />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={resourceToString} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={resourceToString} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Create: ResourceCreate,
  Edit: ResourceEdit,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,
  LinkToList,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    edit: ResourceEdit,
    create: ResourceCreate,
    icon: ResourceIcon,
  },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {
      certificate_date: {
        type: 'dateTime',
      },
      primary_document_date: {
        type: 'dateTime',
      },
    },
  },
};
