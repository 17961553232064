import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate, Button, showNotification, refreshView as refreshViewAction } from 'react-admin';
import compose from 'recompose/compose';

import request from '../../../../network/request';
import resourceConfig from './config';

class CheckButton extends Component {
  state = {
    executing: false,
  };
  handleClick = async () => {
    const { showNotification, record, refreshView } = this.props;

    this.setState({
      executing: true,
    });
    let response = null;
    try {
      response = await request({
        apiService: 'shoes',
        path: `/admin/nkgoods/func/check?docId=${record.id}`,
        method: 'POST',
      });
    } catch (e) {
      console.error(e);
    }
    if (response === null || !response.ok) {
      showNotification(`resources.${resourceConfig.name}.check.errorMessage`, 'error');
    } else {
      showNotification(`resources.${resourceConfig.name}.check.successMessage`, 'success');
    }

    this.setState(
      {
        executing: false,
      },
      refreshView,
    );
  };

  render() {
    return (
      <Button
        label={`resources.${resourceConfig.name}.check.action`}
        disabled={this.state.executing}
        onClick={this.handleClick}
      />
    );
  }
}

export default compose(
  translate,
  connect(undefined, {
    showNotification,
    refreshView: refreshViewAction,
  }),
)(CheckButton);
