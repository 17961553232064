import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Remark (subrecords)',
      fields: {
        certificateDocumentDate: 'Certificate document date',
        remarkingDate: 'Remarking date',
      },
    }
  }
}