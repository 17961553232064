import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'DRAFT': 'Черновик',
        'PENDING': 'В ожидании',
        'READY_TO_SEND': 'Готов к отправке',
        'REJECTED': 'Отклонен',
        'SENT': 'Отправлен',
        'SUCCESS': 'Обработан успешно',
        'FAILED': 'Обработан с ошибкой',
        'PARTIALLY': 'Обработан частично',
      },
    }
  }
}
