import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'PD Information (subrecords)',
      fields: {},
    },
  },
};
