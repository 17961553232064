import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Conformity Certificate |||| Conformity Certificates',
      enums: {
        certType: {
          CONFORMITY_CERTIFICATE: 'Conformity Certificate',
          CONFORMITY_DECLARATION: 'Conformity Declaration',
        },
      },
      fields: {
        certDate: 'Cert Date',
        certDateBegin: 'Cert Date Begin',
        certDateEnd: 'Cert Date End',
        certType: 'Cert Type',
        certNum: 'Cert Num',
        gtin: 'Gtin',
        country: 'Country',
      }
    }
  }
}