import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        RETAIL: 'Розничная продажа',
        REMOTE_SALE: 'Продажа по образцам, дистанционный способ продажи',
        EEC_EXPORT: 'Экспорт в страны ЕАЭС',
        BEYOND_EEC_EXPORT: 'Экспорт за пределы стран ЕАЭС',
        RETURN: 'Возврат физическому лицу',
        DAMAGE_LOSS: 'Утрата или повреждение',
        DESTRUCTION: 'Уничтожение',
        CONFISCATION: 'Конфискация',
        LIQUIDATION: 'Ликвидация предприятия',
        ENTERPRISE_USE: 'Использование для собственных нужд предприятия',
        DONATION: 'Безвозмездная передача',
        STATE_ENTERPRISE: 'Приобретение гос.предприятием',
        NO_RETAIL_USE: 'Использование для собственных нужд покупателем',
        OTHER: 'Другое',
        DISTANCE: 'Дистанционная продажа',
        PRODUCTION_USE: 'Использование для производственных целей',
        EXPIRATION: 'Истечение срока годности',
        RECALL: 'Отзыв с рынка',
        STATE_SECRET: 'Продажа по сделке с государственной тайной',
        VENDING: 'Продажа через вендинговый аппарат',
        UTILIZATION: 'Утилизация',
        PACKING: 'Фасовка',
        LOSS: 'Утрата',
        BY_SAMPLES: '	Продажа по образцам',
        EAS_TRADE: 'Трансграничная продажа в страны ЕАЭС',
        OWN_USE: 'Использование для собственных нужд',
        STATE_CONTRACT: 'Продажа по гос. (муниц.) контракту',
      },
    },
  },
};
