import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Report',
      reportP: 'P records',
      sendDoc: {
        action: 'SEND'
      },
      checkReport:{
        action: 'CHECK',
      },
      fields: {
        id: '#',
        dateCreate: 'Date create',
        date_send: 'Date send',
        expirationDate: 'Expiration date',
        productionDate: 'Production date',
      },
    }
  }
}