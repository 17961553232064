import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Заказы',
      orderP: 'Позиции заказа',
      fields: {
        orderId: 'Id заказа',
        omsId: 'OMS Id',
        actionId: 'Id действия',
        expectedCompletionTime: 'Ожидаемое время исполнения',
        cemContractDate: 'Дата договора с ЦЭМ',
        cemContractNumber: 'Номер договора с ЦЭМ',
        cemInn: 'ИНН ЦЭМ',
        cemName: 'Наименование ЦЭМ',
        companyUniqName: 'Компания в системе',
        contactPerson: 'Имя сотрудника',
        contractDate: 'Дата контракта',
        contractNumber: 'Серийный номер',
        creatorUsername: 'Юзернейм создателя',
        deliveryAddress: 'Адрес доставки',
        deliveryType: 'Тип доставки',
        direction: 'Направление',
        docStatus: 'Статус документа',
        docnum: 'Номер документа',
        documentId: 'Id документа',
        documentSystemStatus: 'Системный статус документа',
        emissionRegistrarOrderId: 'Id регистратора эмиссии',
        labelTemplateId: 'Id шаблона этикетки',
        numdoc: 'Номер документа',
        orderStatus: 'Статус заказа',
        processedDate: 'Дата обработки',
        productionType: 'Тип производства',
        releaseMethodType: 'Тип',
        receiverId: 'Id получателя',
        registrarId: 'Id регистратора',
        requestId: 'Id запроса',
        subjectId: 'Id субъекта',
        status: 'Статус',
        id: 'Id',
        ids: 'Id',
        orderIds: 'Id заказа',
        serialNumberType: 'Тип серийного номера',
        createMethodType: 'Способ изготовления СИ',
        remainsAvailable: 'Произведен (Остатки)',
        remainsImport: 'Импорт (Остатки)',

        descriptionNumdoc: 'Описание ссылочного документа',
        dateCreate: 'Дата создания',

        guids: 'GUID (Список)',
        docnums: 'Номер документа (Список)',
        autoRemark: 'Авто перемаркировка',
        paymentType: 'Тип оплаты',
        producer: 'ИНН владельца карточки товара'
      },
      filters:{
        dateCreateFrom: 'Дата создания с',
        dateCreateTo: 'Дата создания по'
      },

      orderSendList: {
        action: 'Отправить заказ',
        successMessage: 'Успешно',
        errorMessage: 'Ошибка',
      },

      checkKMList: {
        action: 'Проверить КМ',
        successMessage: 'Успешно',
        errorMessage: 'Ошибка',
      },

      getKMList: {
        action: 'Получить KM',
        successMessage: 'Успешно',
        errorMessage: 'Ошибка',
      },

      closeOrders: {
        action: 'Закрыть заказы READY',
        successMessage: 'Успешно',
        errorMessage: 'Ошибка',
      },
    }
  }
}