import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Ввод в оборот (подзаписи)',
      packM: 'Записи',
      inputPChild: 'Товары',
      fields: {
        id: '#',
        certificate_document_date: 'Дата сертификата',
        productionDate: 'Дата производства',
        inputHId: 'Родительский документ',
        certificateDocument: 'Тип сертификата',
        certificate_document_number: 'Номер сертификата',
        custom_valuegoods: 'Пользовательские товары',
        docnum: 'Номер документа',
        goods_price: 'Цена',
        pos_id: 'Id позиции',
        status: 'Статус',
        tax_vat: 'Налог',
        tnved_code: 'ТН ВЭД код',
        uit_code: 'КИ',
        uitu_code: 'КИТУ',
        ean: 'EAN',
        packType: 'Тип упаковки',
        productSize: 'Размер',
        color: 'Цвет',
      },
    }
  }
}