export default {
  enums: [
    { id: 'LP_INTRODUCE_GOODS' },
    { id: 'LP_INTRODUCE_GOODS_CSV' },
    { id: 'LP_INTRODUCE_GOODS_XML' },
    { id: 'LP_GOODS_IMPORT' },
    { id: 'LP_GOODS_IMPORT_CSV' },
    { id: 'LP_GOODS_IMPORT_XML' },
    { id: 'LK_CONTRACT_COMMISSIONING' },
    { id: 'LK_CONTRACT_COMMISSIONING_CSV' },
    { id: 'LK_CONTRACT_COMMISSIONING_XML' },
    { id: 'LK_INDI_COMMISSIONING' },
    { id: 'LK_INDI_COMMISSIONING_CSV' },
    { id: 'LK_INDI_COMMISSIONING_XML' },
    { id: 'LP_INTRODUCE_OST' },
    { id: 'LP_INTRODUCE_OST_CSV' },
    { id: 'LP_INTRODUCE_OST_XML' },
    { id: 'CROSSBORDER' },
    { id: 'CROSSBORDER_CSV' },
    { id: 'CROSSBORDER_XML' },
    { id: 'LP_SHIP_GOODS' },
    { id: 'LP_SHIP_GOODS_CSV' },
    { id: 'LP_SHIP_GOODS_XML' },
    { id: 'LP_CANCEL_SHIPMENT' },
    { id: 'LP_CANCEL_SHIPMENT_CSV' },
    { id: 'LP_CANCEL_SHIPMENT_XML' },
    { id: 'LP_ACCEPT_GOODS' },
    { id: 'LP_ACCEPT_GOODS_XML' },
    { id: 'LK_REMARK' },
    { id: 'LK_REMARK_CSV' },
    { id: 'LK_REMARK_XML' },
    { id: 'LK_KM_CANCELLATION' },
    { id: 'LK_KM_CANCELLATION_CSV' },
    { id: 'LK_KM_CANCELLATION_XML' },
    { id: 'LK_APPLIED_KM_CANCELLATION' },
    { id: 'LK_APPLIED_KM_CANCELLATION_CSV' },
    { id: 'LK_APPLIED_KM_CANCELLATION_XML' },
    { id: 'AGGREGATION_DOCUMENT' },
    { id: 'AGGREGATION_DOCUMENT_CSV' },
    { id: 'AGGREGATION_DOCUMENT_XML' },
    { id: 'DISAGGREGATION_DOCUMENT' },
    { id: 'DISAGGREGATION_DOCUMENT_CSV' },
    { id: 'DISAGGREGATION_DOCUMENT_XML' },
    { id: 'REAGGREGATION_DOCUMENT' },
    { id: 'REAGGREGATION_DOCUMENT_CSV' },
    { id: 'REAGGREGATION_DOCUMENT_XML' },
    { id: 'OST_DESCRIPTION' },
    { id: 'OST_DESCRIPTION_CSV' },
    { id: 'OST_DESCRIPTION_XML' },
    { id: 'UNIVERSAL_TRANSFER_DOCUMENT' },
    { id: 'INCLUDE_DOCUMENT' },
    { id: 'RECEIPT' },
    { id: 'LP_ADD_GOODS_CSV' },
    { id: 'LP_ADD_GOODS_XML' },
    { id: 'LK_REGISTRATION' },
    { id: 'LK_REGISTRATION_XML' },
    { id: 'LK_RESUME_ACCESS' },
    { id: 'LK_RESUME_ACCESS_XML' },
    { id: 'LK_CHANGE_EMAIL_XML' },
    { id: 'LK_CHANGE_EMAIL' },
    { id: 'LK_LOAN_RECEIPT' },
    { id: 'LK_LOAN_RECEIPT_CSV' },
    { id: 'LK_RECEIPT_XML' },
    { id: 'LK_BLOCKING' },
    { id: 'LK_BLOCKING_XML' },
    { id: 'LK_RECEIPT_CSV' },
    { id: 'INTERNAL_RECEIPT' },
    { id: 'LK_RECEIPT' },
    { id: 'LK_LOAN_RECEIPT_XML' },
    { id: 'LP_SHIP_RECEIPT' },
    { id: 'LP_SHIP_RECEIPT_CSV' },
    { id: 'ORDER' },
    { id: 'LP_SHIP_RECEIPT_XML' },
    { id: 'LP_FTS_INTRODUCE' },
    { id: 'LP_FTS_INTRODUCE_CSV' },
    { id: 'LP_FTS_INTRODUCE_XML' },
    { id: 'NK_REQUEST' },
    { id: 'EAS_CROSSBORDER_EXPORT' },
  ],
};
