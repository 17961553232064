import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Документ |||| Документы',
      docP: 'Записи',
      sendDoc: {
        action: 'Отправить',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      acceptAll: {
        action: 'Принять все позиции',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      rejectAll: {
        action: 'Отклонить все позиции',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      sendDocs: {
        action: 'Отправить документы',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      acceptDoc: {
        action: 'Принять',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      sync: {
        action: 'Синхронизация',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      exportDoc: {
        action: 'Выгрузить',
        successMessage: 'Формирование файла в процессе. Готовый файл можно получить в журнале "Файлы выгрузки"',
        errorMessage: 'Ошибка',
      },
      fields: {
        transferDate: 'Дата трансфера',
        processedDate: 'Дата обработки',
        acceptance_date: 'Дата приёмки',
        document_date: 'Дата первичного документа',
        direction: 'Направление',
        errors: 'Ошибки',
        guid: 'GUID',
        owner: 'Владелец',
        owner_inn: 'ИНН Владельца',
        pdf: 'PDF',
        receiver: 'Получатель',
        receiver_inn: 'ИНН Получателя',
        releaseOrderNumber: 'Исходяший номер',
        requestType: 'Тип запроса',
        sender: 'Отправитель',
        sender_inn: 'ИНН Отправителя',
        trade_sender_name: 'Наименование отправителя (приемка)',
        trade_recipient_inn: 'ИНН получателя',
        trade_sender_inn: 'ИНН отправителя (приемка)',
        trade_owner_inn: 'ИНН собственника (приемка)',
        trade_owner_name: 'Наименование собственника (приемка)',
        turnoverType: 'Вид товарооборота',
        actionId: 'Id действия',
        creatorUsername: 'Имя создателя',
        docnum: 'Первичный документ',
        documentId: 'Id документа',
        documentSystemStatus: 'Системный статус документа',
        receiverId: 'Получатель',
        requestId: 'Id запроса',
        subjectId: 'Id субъекта',
        status: 'Статус',
        checkStatus: 'Статус ИСМП',

        warehouseFrom: 'Перемещение со склада',
        warehouseTo: 'Перемещение на склад',

        docStatus: 'Статус документа',

        ids: 'Id',

        needSSCC: 'Нужен код товарной упаковки',

        withdrawalDate: 'Дата вывода из оборота',
        withdrawalType: 'Причина вывода из оборота',

        numUpd: 'Номер УПД',
        torg2: 'Номер ТОРГ2',
        virtual: 'Виртуальная поставка',

        needVirtualSSCC: 'Генерировать виртуальный SSCC',
        virtualSSCC: 'Виртуальный SSCC',

        guids: 'GUID (Список)',
        docnums: 'Номер документа (Список)',

        document_date_to: 'Дата документа до (окончание периода)',
        document_date_from: 'Дата документа с (начало периода)',
      },
    },
  },
};
