import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Таблица соотв. НК',
      fields: {
        emails: 'Emails',
        mrkSystemStatus: 'Системный Статус',
        result: 'Результат',
        status: 'Статус',
      },
    },
  },
};
