import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        GOODSIMPORT: 'Импорт',
        LP_INTRODUCE_OST: 'Остатки',
        Promotion_Inform_Selfmade: 'Производство',
        LP_FTS_INTRODUCE: 'Импорт ФТС',
        CROSSBORDER: 'Трансграничный',
      },
    },
  },
};
