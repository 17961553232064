import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        USED_FOR_PRODUCTION: 'КМ был передан на производственную линию',
        SENT_TO_PRINTER: 'Производственная линия отправила КМ на принтер',
        PRINTED: 'КМ был напечатан',
        PRINTER_LOST: 'Подтверждённая потеря КМ принтером',
        VERIFIED: 'Нанесение КМ подтверждено',
      },
    }
  }
}
