import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'НК Заявка |||| НК Заявки',
      send: {
        action: 'Отправить',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      check: {
        action: 'Проверить статус карточки',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      verify: {
        action: 'Проверить статус запроса',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      moderate: {
        action: 'Модерировать',
        successMessage: 'Успешно',
        errorMessage: 'Ошибка',
      },
      enums: {
        nkRequestType: {
          CREATE: 'Создание',
          EDIT: 'Изменение',
        },
        nkStatus: {
          REJECTED: 'Отклонено',
          RECEIVED: 'Получен',
          MODERATED: 'Модерация',
          SIGNED: 'Подписано',
          DRAFT: 'Черновик',
          ERRORS: 'Требует изменений',
        },
        goodDetailedStatus: {
          DRAFT: 'Черновик',
          MODERATION: 'Модерация',
          ERRORS: 'Требует изменений',
          NOTSIGNED: 'Требует подписи',
          PUBLISHED: 'Опубликовано',
        },
      },
      fields: {
        id: 'Id',
        brand: 'Бренд',
        date: 'Дата создания',
        feedId: 'Id запроса',
        goodId: 'Id товара',
        goodName: 'Наименование товара',
        gtin: 'GTIN',
        isKit: 'Комплект',
        isSet: 'Набор',
        moderation: 'Признак отправки товара на модерацию',
        nkRequestType: 'Тип запроса',
        nkStatus: 'Статус запроса',
        tnved: 'Код группы ТН ВЭД',
        categories: 'Категории',
        attr_id: 'Id атрибута',
        attr_value: 'Значение атрибута',
        attr_value_type: 'Тип значения атрибута',
        goodAttrs: 'Атрибуты',
        cat_id: 'Id категории',
        identifiedBy: 'Идентификаторы',
        level: 'Тип упаковки',
        multiplier: 'Количество товаров в упаковке',
        party_id: 'Id торговой сети',
        type: 'Тип идентификатора',
        unit: 'Тип измерений',
        value: 'Значение кода товара упаковки',
        delete: 'Удаление атрибута товара',
        goodDetailedStatus: 'Текущий статус карточки',
        goodStatus: 'Технологический статус карточки',
        setGtins: 'Вложения в набор',
        quantity: 'Количество',
      },
    },
  },
};
