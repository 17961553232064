import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate, Button, showNotification, refreshView as refreshViewAction } from 'react-admin';
import compose from 'recompose/compose';

import resourceConfig from './config';
import request from '../../../../network/request';
import checkDocumentBeforeSend from '../../../../utils/checkDocumentBeforeSend';

class SendButton extends Component {
  state = {
    executing: false,
  };
  handleClick = async () => {
    const { showNotification, record, refreshView, translate } = this.props;

    if (!checkDocumentBeforeSend(record, { showNotification, translate })) {
      return;
    }

    let data = {
      id: record.id,
    };

    this.setState({
      executing: true,
    });
    let response = null;
    try {
      response = await request({
        apiService: 'shoes',
        method: 'POST',
        path: '/admin/aggr/func/send',
        urlParams: data,
      });
    } catch (e) {
      console.error(e);
    }
    if (response === null || !response.ok) {
      showNotification(`resources.${resourceConfig.name}.send.errorMessage`, 'error');
    } else {
      showNotification(`resources.${resourceConfig.name}.send.successMessage`, 'success');
    }
  };

  render() {
    return (
      <Button
        label={`resources.${resourceConfig.name}.send.action`}
        disabled={this.state.executing}
        onClick={this.handleClick}
      />
    );
  }
}

export default compose(
  translate,
  connect(undefined, {
    showNotification,
    refreshView: refreshViewAction,
  }),
)(SendButton);
