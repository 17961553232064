import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'УПД',
      updP: 'Позиции',
      downloadXML: {
        action: 'Скачать',
        errorMessage: 'Ошибка',
      },
      enums:{
        updDirection:{
          INBOUND:'INBOUND',
          OUTBOUND:'OUTBOUND'
        }
      },
      fields: {
        actionId: 'Id действия',
        checkStatus: 'Статус',
        dateSupply: 'Дата поставки',
        invoiceCreationTime: 'Дата создания инвойса',
        direction: 'Направление',
        docStatus: 'Статус документа',
        docnum: 'Номер документа',
        documentId: 'Id документа',
        documentLinkId: 'Id ссылочного документа',
        documentLinkType: 'Тип ссылочного документа',
        documentSystemStatus: 'Системный статус документа',
        errorModelId: 'Id ошибки',
        errors: 'Ошибки',
        guid: 'GUID',
        ismpDocType: 'Тип документа ГИСМТ',
        numberSupply: 'Номер поставки',
        orgNameBuyer: 'Наименование организации покупателя',
        orgNameSeller: 'Наименование организации продавца',
        processedDate: 'processedDate',
        receiverId: 'Id получателя',
        requestId: 'Id запроса',
        subjectId: 'Id субъекта',
        surname: 'Фамилия подписанта',
        total: 'Сумма всего с НДС',
        updDirection: 'Направление УПД',
        'additional.addressBuyer': 'Адрес покупателя',
        'additional.addressCodeBuyer': 'КодГАР покупателя',
        'additional.addressCodeConsignee': 'КодГАР грузополучателя',
        'additional.addressCodeSeller': 'КодГАР продавца',
        'additional.addressCodeShipper': 'КодГАР грузоотправителя',
        'additional.addressConsignee': 'Адрес грузополучателя',
        'additional.addressSeller': 'Адрес продавца',
        'additional.addressShipper': 'Адрес грузоотправителя',
        'additional.addressTextBuyer': 'Текст адреса покупателя',
        'additional.addressTextConsignee': 'Текст адреса грузополучателя',
        'additional.addressTextSeller': 'Текст адреса продавца',
        'additional.addressTextShipper': 'Текст адреса грузоотправителя',
        'additional.apartmentBuyer': 'Квартира покупателя',
        'additional.apartmentConsignee': 'Квартира грузополучателя',
        'additional.apartmentSeller': 'Квартира продавца',
        'additional.apartmentShipper': 'Квартира грузоотправителя',
        'additional.baseDocumentName': 'Наименование документа-основания отгрузки',
        'additional.blockBuyer': 'Корпус покупателя',
        'additional.blockConsignee': 'Корпус грузополучателя',
        'additional.blockSeller': 'Корпус продавца',
        'additional.blockShipper': 'Корпус грузоотправителя',
        'additional.buildingBuyer': 'Дом покупателя',
        'additional.buildingConsignee': 'Дом грузополучателя',
        'additional.buildingSeller': 'Дом продавца',
        'additional.buildingShipper': 'Дом грузоотправителя',
        'additional.cityBuyer': 'Город покупателя',
        'additional.cityConsignee': 'Город грузополучателя',
        'additional.citySeller': 'Город продавца',
        'additional.cityShipper': 'Город грузоотправителя',
        'additional.countryCodeBuyer': 'Код страны покупателя',
        'additional.countryCodeConsignee': 'Код страны грузополучателя',
        'additional.countryCodeSeller': 'Код страны продавца',
        'additional.countryCodeShipper': 'Код страны грузоотправителя',
        'additional.currency': 'Валюта (код)',
        'additional.currencyName': 'Наименование валюты',
        'additional.datetimeInfSeller':
          'Дата формирования файла обмена счета-фактуры (информации продавца) ',
        'additional.documentCreator':
          'Составитель файла обмена счета-фактуры (информации продавца)',
        'additional.documentDate': 'Дата первичного документа',
        'additional.documentName': 'Наименование первичного документа',
        'additional.documentNamePFHJ': 'Наименование документа по факту хозяйственной жизни ',
        'additional.documentNumber': 'Номер первичного документа',
        'additional.firstName': 'Имя подписанта',
        'additional.fnsParticipantId':
          'Идентификатор оператора ЭДО отправителя файла обмена информации покупателя',
        'additional.function': 'Функция документа',
        'additional.id': 'ID',
        'additional.idFile': 'Идентификатор файла ',
        'additional.idReceipt': 'Идентификатор  получателя файла обмена информации покупателя',
        'additional.idSender': 'Идентификатор отправителя файла обмена информации покупателя',
        'additional.innEdo': 'ИНН организации ЭДО',
        'additional.innBuyer': 'ИНН организации покупателя',
        'additional.innConsignee': 'ИНН организации грузополучателя',
        'additional.innSeller': 'ИНН организации продавца',
        'additional.innShipper': 'ИНН организации грузоотправителя',
        'additional.innSigner':
          'ИНН юридического лица подписанта или индивидуального предпринимателя',
        'additional.jobTitle': 'Должность подписанта',
        'additional.kndCode': 'Код документа по КНД',
        'additional.kppBuyer': 'КПП организации покупателя',
        'additional.kppConsignee': 'КПП организации грузополучателя',
        'additional.kppSeller': 'КПП организации продавца',
        'additional.kppShipper': 'КПП организации грузоотправителя',
        'additional.localityBuyer': 'Населенный пункт покупателя',
        'additional.localityConsignee': 'Населенный пункт грузополучателя',
        'additional.localitySeller': 'Населенный пункт продавца',
        'additional.localityShipper': 'Населенный пункт грузоотправителя',
        'additional.nameOrgEdo': 'Наименование организации ЭДО',
        'additional.operationInfo': 'Содержание операции',
        'additional.orgNameConsignee': 'Наименование организации грузополучателя',
        'additional.orgNameShipper': 'Наименование организации грузоотправителя',
        'additional.orgTypeBuyer': 'Идентификационные сведения покупателя',
        'additional.orgTypeConsignee': 'Идентификационные сведения грузополучателя',
        'additional.orgTypeSeller': 'Идентификационные сведения продавца',
        'additional.orgTypeShipper': 'Идентификационные сведения грузоотправителя',
        'additional.patronymic': 'Отчество подписанта',
        'additional.regionCodeBuyer': 'Код региона покупателя',
        'additional.regionCodeConsignee': 'Код региона грузополучателя',
        'additional.regionCodeSeller': 'Код региона продавца',
        'additional.regionCodeShipper': 'Код региона грузоотправителя',
        'additional.signerOrganizationName': 'Наименование организации подписанта',
        'additional.signerPowers': 'Область полномочий',
        'additional.signerPowersBase': 'Основания полномочий (доверия)',
        'additional.signerStatus': 'Статус',
        'additional.signerType': 'Тип подписанта',
        'additional.streetBuyer': 'Улица покупателя',
        'additional.streetConsignee': 'Улица грузополучателя',
        'additional.streetSeller': 'Улица продавца',
        'additional.streetShipper': 'Улица грузоотправителя',
        'additional.territoryBuyer': 'Район покупателя',
        'additional.territoryConsignee': 'Район грузополучателя',
        'additional.territorySeller': 'Район продавца',
        'additional.territoryShipper': 'Район грузоотправителя',
        'additional.totalNet': 'Количество (масса нетто/количество) - всего по документу',
        'additional.totalWithVatExcluded': 'Всего к оплате без НДС',
        'additional.transferTextInfo': 'Сведения о транспортировке и грузе',
        'additional.vat': 'Всего к оплате, сумма налога, предъявляемая покупателю',
        'additional.versForm': 'Версия формата',
        'additional.versProg': 'Версия программы',
        'additional.zipCodeBuyer': 'Индекс покупателя',
        'additional.zipCodeConsignee': 'Индекс грузополучателя',
        'additional.zipCodeSeller': 'Индекс продавца',
        'additional.zipCodeShipper': 'Индекс грузоотправителя',
      },
    },
  },
};
