import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Контрагенты',
      fields: {
        inn: 'ИНН Контрагента',
        name: 'Наименование Контрагента',
        sscc: 'Признак SSCC',
        upd: 'Признак УПД',
        memberIdEdm: 'Идентификатор получателя ЭДО',
        kppConsignee: 'КПП',
        zipCodeConsignee: 'Индекс',
        regionCodeConsignee: 'Код региона',
        streetConsignee: 'Улица',
        buildingConsignee: 'Дом',
        blockConsignee: 'Строение',
        infoFhj: 'ИнфПолФхж',
        valueFhj: 'ФХЖ'
      },
    },
  },
};
