import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'АТК |||| АТК',
      atkKM: 'Коды',
      enums: {
        docType: {
          ATK_AGGREGATION: 'формирование АТК',
          ATK_TRANSFORMATION: 'изменение АТК',
          ATK_DISAGGREGATION: 'расформирование АТК',
        },
        transformationType: {
          REMOVING: 'Изъятие',
          ADDING: 'Добавление',
        },
      },
      dissagr: {
        action: 'Расформировать',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      send: {
        action: 'Отправить',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      fields: {
        actionId: 'ID действия',
        atk: 'Трансформируемый агрегированный таможенный код',
        checkStatus: 'Статус обработки документа',
        country: 'Страна производитель',
        dateInvoice: 'Дата инвойса',
        direction: 'Направление',
        docStatus: 'Статус Документа',
        docType: 'Тип документа',
        docnum: 'Номер документа',
        documentDate: 'Дата документа',
        documentId: 'ID документа',
        documentLinkId: 'ID ссылочного документа',
        documentLinkType: 'Тип ссылочного документа',
        documentSystemStatus: 'Системный статус документа',
        errorModelId: 'ID ошибки',
        errors: 'Ошибка',
        guid: 'Гуид документа',
        invoice: 'Номер инвойса',
        invoiceAtk: 'Номер ссылочного документа',
        invoiceAtkDate: 'Дата ссылочного документа',
        invoiceAtkPos: 'Номер позиции ссылочного документа',
        ismpDocType: 'Тид документа ГИС МТ',
        mrkSystemStatus: 'Внутренний статус КМ',
        numGoods: 'Номер товара',
        processedDate: 'Дата обработки',
        processedDateFrom: 'Дата обработки - от',
        processedDateTo: 'Дата обработки - до',
        productGroup: 'Товарная группа',
        receiverId: 'ID получателя',
        requestId: 'ID запроса',
        runningBefore: 'Запускался ранее',
        status: 'Статус ГИС МТ КМ',
        subjectId: 'ID темы',
        tnved: 'ТН ВЭД',
        tnvedCode: 'Код ТН ВЭД',
        tradeParticipantInn: 'ИНН участника оборота товара',
        transformationType: 'Тип трансформации',
        itemCode: 'Код товара'
      }
    }
  }
}