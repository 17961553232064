import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'ATK KM',
      fields: {
        atkId: 'ATK Id',
        ki: 'KI',
        numGoods: 'Numgoods',
        tnved: 'TNVED',
      },
    }
  }
}