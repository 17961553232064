import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        RECEIPT: 'RECEIPT',
        LOAN_RECEIPT: 'LOAN_RECEIPT',
        CANCELLATION: 'CANCELLATION',
        CANCELLATION_APPLIED: 'CANCELLATION_APPLIED',
        STRICT_REPORTING_FORM: 'STRICT_REPORTING_FORM',
        CONTRACT: 'CONTRACT',
        DESTRUCTION_ACT: 'DESTRUCTION_ACT',
        CONSIGNMENT_NOTE: 'CONSIGNMENT_NOTE',
        UTD: 'UTD',
        CORRECTION_RECEIPT: 'CORRECTION_RECEIPT',
        SALES_RECEIPT: 'SALES_RECEIPT',
        CUSTOMS_DECLARATION: 'CUSTOMS_DECLARATION',
        OTHER: 'OTHER',
      },
    }
  }
}