import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        USED_FOR_PRODUCTION: 'USED_FOR_PRODUCTION',
        SENT_TO_PRINTER: 'SENT_TO_PRINTER',
        PRINTED: 'PRINTED',
        PRINTER_LOST: 'PRINTER_LOST',
        VERIFIED: 'VERIFIED',
      },
    }
  }
}
