import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Sync',
      title: 'Sync',
      executeAction: 'Submit',
      errorMessage: 'Error',
      successMessage: 'Success',
      fields: {
        dateFrom: 'dateFrom',
        dateTo: 'dateTo',
        did: 'did',
        number: 'number',
        documentFormat: 'documentFormat',
        documentStatus: 'documentStatus',
        documentType: 'documentType',
        inputFormat: 'inputFormat',
        participantInn: 'participantInn',
        limit: 'limit',
        order: 'order',
        orderColumn: 'orderColumn',
        orderedColumnValue: 'orderedColumnValue',
        pageDir: 'pageDir',
        senderInn: 'senderInn',
        receiverInn: 'receiverInn',
      },
      enums:{
        documentType: {
          LP_SHIP_GOODS: 'LP_SHIP_GOODS',
          LP_ACCEPT_GOODS: 'LP_ACCEPT_GOODS',
          EAS_CROSSBORDER_EXPORT: 'EAS_CROSSBORDER_EXPORT',
        },
        documentStatus:{
          IN_PROGRESS: 'IN_PROGRESS',
          CHECKED_OK: 'CHECKED_OK',
          CHECKED_NOT_OK: 'CHECKED_NOT_OK',
          PROCESSING_ERROR: 'PROCESSING_ERROR',
          CANCELLED: 'CANCELLED',
          ACCEPTED: 'ACCEPTED',
          WAIT_ACCEPTANCE: 'WAIT_ACCEPTANCE',
          WAIT_PARTICIPANT_REGISTRATION: 'WAIT_PARTICIPANT_REGISTRATION',
          PARSE_ERROR: 'PARSE_ERROR',
          WAIT_FOR_CONTINUATION: 'WAIT_FOR_CONTINUATION'
        }
      }
      
    }
  }
}