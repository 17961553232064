export default {
  enums: [
    { id: 'DAMAGED_OR_LOST' },
    { id: 'ERROR_DETECTED' },
    { id: 'KM_SPOILED' },
    { id: 'DESCRIPTION_ERRORS' },
    { id: 'RETAIL_RETURN' },
    { id: 'REMOTE_SALE_RETURN' },
    { id: 'LEGAL_RETURN' },
    { id: 'INTERNAL_RETURN' },
    { id: 'EEC_EXPORT_RETURN' },
  ]
};

