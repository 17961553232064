import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Order report',
      title: 'Order report',
      executeAction: 'Submit',
      errorMessage: 'Error',
      successMessage: 'Request successfully sent',
      filterErrorMessage: 'Select any date filter',
    },
  },
};
