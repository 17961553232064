import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        LOAD_GOODS: 'LOAD_GOODS',
        LOAD_DOC: 'LOAD_DOC',
        LOAD_KM_LIST: 'LOAD_KM_LIST',
        SEND_ORDER: 'SEND_ORDER',
        PRINT: 'PRINT',
        UPDATE_ORDER_STATUS: 'UPDATE_ORDER_STATUS',
        PRINT_KM: 'PRINT_KM',
        SEND_INPUT: 'SEND_INPUT',
        UPDATE_KM: 'UPDATE_KM',
        SEND_DOC: 'SEND_DOC',
        SEND_OUT: 'SEND_OUT',
        SEND_REMARK: 'SEND_REMARK',
        SEND_AGGR: 'SEND_AGGR',
        LOAD_LABEL: 'LOAD_LABEL',
        UPDATE_GOOD: 'UPDATE_GOOD',
        SEND_DESC_REST: 'SEND_DESC_REST',
        SEND_RETURN: 'SEND_RETURN',
        CHECK_DOCUMENT: 'CHECK_DOCUMENT',
      },
    }
  }
}