export default {
  enums: [
    { id: 'DOC' },
    { id: 'GOODS' },
    { id: 'INPUT' },
    { id: 'ORDER' },
    { id: 'STRDOC' },
    { id: 'OUT' },
    { id: 'REMARK' },
    { id: 'TREEMRK' },
    { id: 'REPORT' },
    { id: 'ORDER_P' },
    { id: 'DESC_REST' },
    { id: 'AGGR' },
    { id: 'RETURN' },
    { id: 'ATK' },
    { id: 'INVENTORY' },
    { id: 'NK_REQUEST' },
    { id: 'PD_INFORMATION' },
  ],
};
