import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        DAMAGED_OR_LOST: 'Поврежден или утерян',
        ERROR_DETECTED: 'Найдена ошибка',
        KM_SPOILED: 'Испорчено либо утеряно СИ с КМ',
        DESCRIPTION_ERRORS: 'Выявлены ошибки описания товара',
        RETAIL_RETURN: 'Возврат товаров с поврежденным СИ/без СИ при розничной реализации',
        REMOTE_SALE_RETURN: 'Возврат товаров с поврежденным СИ/без СИ при дистанционном способе продажи',
        LEGAL_RETURN: 'Возврат от конечного покупателя (юр. лица/ИП)',
        INTERNAL_RETURN: 'Решение о реализации товаров, приобретённых в целях, не связанных с их реализацией',
        EEC_EXPORT_RETURN: 'Возврат ранее экспортированного в ЕАЭС',
      },
    }
  }
}
