export default {
  enums: [
    // { id: 'CUSTOM_LOAD_TASK', },
    { id: 'MRK_INFO_UPDATE_TASK' },
    { id: 'GOODS_LOAD_TASK' },
    { id: 'DOCUMENT_LOAD_TASK' },
    { id: 'CLOSE_READY_ORDER_TASK' },
    { id: 'GET_MRK_FROM_ACTIVE_ORDER_TASK' },
    { id: 'CHECK_MRK_STATUS_FROM_ORDER_TASK' },
    { id: 'CHECK_ORDER_STATUS_TASK' },
    { id: 'SEND_ACTIVE_ORDER_TASK' },
    { id: 'FTP_CHANGE_STATUS_TASK' },
    { id: 'FTP_LOAD_EAN_INFO_TASK' },
    { id: 'DOCUMENT_CHECKER_TASK' },
    // { id: 'QUERY_REQUEST_TASK', },
    { id: 'FTP_INVOICE_SUPPLIER_TASK' },
    { id: 'CUSTOM_KM_LOAD_TASK' },
    { id: 'CREATE_AND_SEND_INPUT_BY_MRK_TASK' },
    { id: 'SOA_SUITE_MRK_SEND_BY_INVOICE_TASK' },
    { id: 'SEND_AGGR_BY_DOC_ERP_TASK' },
    { id: 'CREATE_AND_SEND_DOCUMENT_BY_DOC_ERP_TASK' },
    { id: 'CREATE_ORDER_BY_UPDATED_QUANTITY_KM_TASK' },
    { id: 'CREATE_ORDER_BY_ARTICLE_MAP_KM_TASK' },
    { id: 'FTP_UDP_LOADER_TASK' },
    { id: 'MRK_INFO_UPDATE_V4_TASK' },
    { id: 'SEND_NEW_OUT_TASK' },
    { id: 'MRK_STAT_UPDATE_TASK' },
    { id: 'FTP_TORG_CHECK_DOCUMENT_TASK' },
    { id: 'FTP_XLS_TASK' },
    { id: 'CUSTOM_NEW_STATUS_FIELD_CLEANER_TASK' },
    { id: 'FTP_UDP_LOADER_UPDATER_TASK' },
    { id: 'SEND_NEW_INPUT_TASK' },
    { id: 'INPUT_NOTIFICATOR_TASK' },
    { id: 'SEND_NEW_SHIPMENT_TASK' },
    { id: 'CHANGE_COMPANY_TASK' },
    { id: 'FTP_UCD_LOADER_UPDATER_TASK' },
    { id: 'RECONCILIATION_REPORT_TASK' },
    { id: 'CRYPTO_TAIL_TASK' },
    { id: 'FTP_MARS_REPORT_LOADER_TASK' },
    { id: 'KM_REQUEST_ADN_SAVE_TASK' },
    { id: 'WAREHOUSE_INFORM_TASK' },
    { id: 'MRK_STATUS_CHANGE_HANDLING_TASK' },
    { id: 'FTP_INVOICE_SUPPLIER_V2_TASK' },
    { id: 'REMARK_DOCUMENT_CREATING_TASK' },
    { id: 'ATK_CREATION_TASK' },
    { id: 'NOT_ENTERED_INTO_IMPORT_MRK_REPORT_TASK' },
    { id: 'FORMAT_AND_PRINT_DATAMATRIX_IN_BASE64_TASK' },
    { id: 'SEND_NK_REQUEST_TASK' },
    { id: 'NK_REQUEST_ERROR_FIELD_TASK' },
    { id: 'CONTROL_ORDERED_AND_TRANSFERRED_MC_REPORT_TASK' },
    { id: 'ORDERED_MC_REPORT_TASK' },
    { id: 'FORMATION_OF_TRANSFER_DOCUMENT_TASK' },
    { id: 'OUT_NOTIFICATION_TASK' },
    { id: 'CREATE_AND_SEND_DISAGGREGATION_TASK' },
    { id: 'SEND_SETS_AGGREGATION_TASK' },
    { id: 'PRODUCT_CHECKER_TASK' },
    { id: 'FEED_CHECKER_TASK' },
    { id: 'CHECK_AND_SEND_NEW_ORDERS_TASK' },
    { id: 'NK_MODERATION_AND_SIGN_TASK' },
    { id: 'NK_GOODS_LOAD_TASK' },
    { id: 'IRREDUCIBLE_STOCK_TASK' },
    { id: 'NK_NOT_PUBLISHED_TASK' },
    { id: 'NK_NOT_PUBLISHED_BY_ORDER_TASK' },
    { id: 'FTP_NON_VIRTUAL_TORG2_CHECK_TASK' },
    { id: 'CERT_INFO_REPORT_TASK' },
    { id: 'OUTS_ERR_NOTIFICATION_TASK' },
    { id: 'SEND_RETURN_NOTIFICATION_TASK' },
    { id: 'EDO_ERR_NOTIFICATION_TASK' },
    { id: 'FTP_UPD_CREATION_TASK' },
    { id: 'EDO_ERR_AUTOCHECK_TASK' },
    { id: 'EDO_ERR_AUTOSEND_TASK' },
    { id: 'FTP_UCD_NO_MARKING_TASK' },
    { id: 'CERT_AUTO_ADD_TASK' },
    { id: 'PDF_CREATING_TASK' },
    { id: 'AUTO_COUNTER_INPUTP_TASK'},
    { id: 'REMOVE_FULLKM_IN_ERP'},
    { id: 'CLEAN_MINIO_EXPORT_FILES_TASK'},
    { id: 'EAS_DOCUMENT_LOAD_TASK'},
    { id: 'MRK_QUERY_REQUEST_CLEARING_TASK'},
    { id: 'SOA_SUITE_REMARK_MRK_SEND_TASK'},
    { id: 'CHECK_AND_SEND_ERROR_RETURNS_TASK'},
    { id: 'CHECK_AND_SEND_ERROR_OUTS_TASK'},
    { id: 'SEND_REPORT_ON_IMPORT_ORDER_TASK'},
    { id: 'SEND_IMPORT_MC_TO_SOA_TASK'}
  ],
};
