import React from 'react';
import {
  translate,
  Datagrid,
  Edit,
  Create,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  EditButton,
  Filter,
  NumberInput,
  BooleanInput,
  NumberField,
  DateField,
  CreateButton,
  ExportButton,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Toolbar from '@material-ui/core/Toolbar';
import documentImport from '../updHDocumentImport';

import resourceLocales from './locales';
import resourceConfig from './config';
import documentRowStyle from '../../../../utils/ui/documentRowStyle';
import DateInput from '../../../../components/DateInput';
import exporter from '../../../../utils/exporter';
import directionType from '../directionType';
import docStatus from '../docStatus';
import docStatusSystem from '../docStatusSystem';
import updP from '../updP';
import EnumInput from '../../../../components/EnumInput';
import DownloadXMLButton from './DownloadXMLButton';
import ExportDocButton from '../docH/ExportDocButton';

const ResourceIcon = BookmarkIcon;

const resourceToString = (x) => `#${x.id}`;

const listStyles = {};

const PButton = (props) => {
  return (
    <updP.LinkToList
      label={`resources.${resourceConfig.name}.updP`}
      filter={{
        overEntityIds: props.record.id,
      }}
    />
  );
};

const ResourceListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  translate,
}) => (
  <Toolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <documentImport.OpenButton params={{ docType: 'UPD' }} />
    <CreateButton basePath={basePath} />
    <ExportDocButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

const ResourceListFilter = (props) => (
  <Filter {...props}>
    <TextInput source={'numberSupply'} />
  </Filter>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
  <List
    {...props}
    filters={<ResourceListFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    exporter={exporter}
    actions={<ResourceListActions />}>
    <Datagrid rowStyle={documentRowStyle}>
      <TextField source={'id'} />
      <directionType.Field source={'direction'} />
      <TextField source={'orgNameSeller'} />
      <TextField source={'orgNameBuyer'} />
      <TextField source={'numberSupply'} />
      <DateField source={'dateSupply'} />
      <NumberField source={'total'} />
      <TextField source={'surname'} />
      <DownloadXMLButton />
      <PButton />
      <EditButton />
    </Datagrid>
  </List>
));

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.id}&quot;
  </span>
));

const ResourceCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source={'actionId'} />
      <TextInput source={'checkStatus'} />
      <DateInput
        source={'dateSupply'}
        label={`resources.${resourceConfig.name}.fields.dateSupply`}
      />
      <EnumInput
        source={'updDirection'}
        translatePrefix={`resources.${resourceConfig.name}.enums.updDirection.`}
        enums={['INBOUND', 'OUTBOUND']}
      />
      <directionType.Input source={'direction'} />
      <docStatus.Input source={'docStatus'} />
      <TextInput source={'docnum'} />
      <TextInput source={'documentId'} />
      <NumberInput source={'documentLinkId'} />
      <TextInput source={'documentLinkType'} />
      <docStatusSystem.Input source={'documentSystemStatus'} />
      <NumberInput source={'errorModelId'} />
      <TextInput source={'errors'} />
      <TextInput source={'guid'} />
      <TextInput source={'ismpDocType'} />
      <TextInput source={'numberSupply'} />
      <TextInput source={'orgNameBuyer'} />
      <TextInput source={'orgNameSeller'} />
      <DateInput
        source={'processedDate'}
        label={`resources.${resourceConfig.name}.fields.processedDate`}
      />
      <TextInput source={'receiverId'} />
      <TextInput source={'requestId'} />
      <TextInput source={'subjectId'} />
      <TextInput source={'surname'} />
      <NumberInput source={'total'} />
      <DateInput
        source={'invoiceCreationTime'}
        label={`resources.${resourceConfig.name}.fields.invoiceCreationTime`}
      />
      <TextInput source={'invoiceReason'} />
      <TextInput source={'infoFhj'} />
      <TextInput source={'valueFhj'} />
      <BooleanInput source={'runningBefore'} />
      <BooleanInput source={'additional.addressBuyer'} />
      <TextInput source={'additional.addressCodeBuyer'} />
      <TextInput source={'additional.addressCodeConsignee'} />
      <TextInput source={'additional.addressCodeSeller'} />
      <TextInput source={'additional.addressCodeShipper'} />
      <BooleanInput source={'additional.addressConsignee'} />
      <BooleanInput source={'additional.addressSeller'} />
      <BooleanInput source={'additional.addressShipper'} />
      <TextInput source={'additional.addressTextBuyer'} />
      <TextInput source={'additional.addressTextConsignee'} />
      <TextInput source={'additional.addressTextSeller'} />
      <TextInput source={'additional.addressTextShipper'} />
      <TextInput source={'additional.apartmentBuyer'} />
      <TextInput source={'additional.apartmentConsignee'} />
      <TextInput source={'additional.apartmentSeller'} />
      <TextInput source={'additional.apartmentShipper'} />
      <TextInput source={'additional.baseDocumentName'} />
      <TextInput source={'additional.blockBuyer'} />
      <TextInput source={'additional.blockConsignee'} />
      <TextInput source={'additional.blockSeller'} />
      <TextInput source={'additional.blockShipper'} />
      <TextInput source={'additional.buildingBuyer'} />
      <TextInput source={'additional.buildingConsignee'} />
      <TextInput source={'additional.buildingSeller'} />
      <TextInput source={'additional.buildingShipper'} />
      <TextInput source={'additional.cityBuyer'} />
      <TextInput source={'additional.cityConsignee'} />
      <TextInput source={'additional.citySeller'} />
      <TextInput source={'additional.cityShipper'} />
      <TextInput source={'additional.countryCodeBuyer'} />
      <TextInput source={'additional.countryCodeConsignee'} />
      <TextInput source={'additional.countryCodeSeller'} />
      <TextInput source={'additional.countryCodeShipper'} />
      <TextInput source={'additional.currency'} />
      <TextInput source={'additional.currencyName'} />
      <DateInput
        source={'additional.datetimeInfSeller'}
        label={`resources.${resourceConfig.name}.fields.additional.datetimeInfSeller`}
      />
      <TextInput source={'additional.documentCreator'} />
      <DateInput
        source={'additional.documentDate'}
        label={`resources.${resourceConfig.name}.fields.additional.documentDate`}
      />
      <TextInput source={'additional.documentName'} />
      <TextInput source={'additional.documentNamePFHJ'} />
      <TextInput source={'additional.documentNumber'} />
      <TextInput source={'additional.firstName'} />
      <TextInput source={'additional.fnsParticipantId'} />
      <TextInput source={'additional.function'} />
      <NumberInput source={'additional.id'} />
      <TextInput source={'additional.idFile'} />
      <TextInput source={'additional.idReceipt'} />
      <TextInput source={'additional.idSender'} />
      <TextInput source={'additional.innEdo'} />
      <TextInput source={'additional.innBuyer'} />
      <TextInput source={'additional.innConsignee'} />
      <TextInput source={'additional.innSeller'} />
      <TextInput source={'additional.innShipper'} />
      <TextInput source={'additional.innSigner'} />
      <TextInput source={'additional.jobTitle'} />
      <TextInput source={'additional.kndCode'} />
      <TextInput source={'additional.kppBuyer'} />
      <TextInput source={'additional.kppConsignee'} />
      <TextInput source={'additional.kppSeller'} />
      <TextInput source={'additional.kppShipper'} />
      <TextInput source={'additional.localityBuyer'} />
      <TextInput source={'additional.localityConsignee'} />
      <TextInput source={'additional.localitySeller'} />
      <TextInput source={'additional.localityShipper'} />
      <TextInput source={'additional.nameOrgEdo'} />
      <TextInput source={'additional.operationInfo'} />
      <TextInput source={'additional.orgNameConsignee'} />
      <TextInput source={'additional.orgNameShipper'} />
      <TextInput source={'additional.orgTypeBuyer'} />
      <TextInput source={'additional.orgTypeConsignee'} />
      <TextInput source={'additional.orgTypeSeller'} />
      <TextInput source={'additional.orgTypeShipper'} />
      <TextInput source={'additional.patronymic'} />
      <TextInput source={'additional.regionCodeBuyer'} />
      <TextInput source={'additional.regionCodeConsignee'} />
      <TextInput source={'additional.regionCodeSeller'} />
      <TextInput source={'additional.regionCodeShipper'} />
      <TextInput source={'additional.signerOrganizationName'} />
      <TextInput source={'additional.signerPowers'} />
      <TextInput source={'additional.signerPowersBase'} />
      <TextInput source={'additional.signerStatus'} />
      <TextInput source={'additional.signerType'} />
      <TextInput source={'additional.streetBuyer'} />
      <TextInput source={'additional.streetConsignee'} />
      <TextInput source={'additional.streetSeller'} />
      <TextInput source={'additional.streetShipper'} />
      <TextInput source={'additional.territoryBuyer'} />
      <TextInput source={'additional.territoryConsignee'} />
      <TextInput source={'additional.territorySeller'} />
      <TextInput source={'additional.territoryShipper'} />
      <NumberInput source={'additional.totalNet'} />
      <NumberInput source={'additional.totalWithVatExcluded'} />
      <TextInput source={'additional.transferTextInfo'} />
      <NumberInput source={'additional.vat'} />
      <TextInput source={'additional.versForm'} />
      <TextInput source={'additional.versProg'} />
      <TextInput source={'additional.zipCodeBuyer'} />
      <TextInput source={'additional.zipCodeConsignee'} />
      <TextInput source={'additional.zipCodeSeller'} />
      <TextInput source={'additional.zipCodeShipper'} />
    </SimpleForm>
  </Create>
);

const ResourceEdit = (props) => (
  <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
    <SimpleForm redirect={false}>
      <TextInput source={'actionId'} />
      <TextInput source={'checkStatus'} />
      <DateInput
        source={'dateSupply'}
        label={`resources.${resourceConfig.name}.fields.dateSupply`}
      />
      <EnumInput
        source={'updDirection'}
        translatePrefix={`resources.${resourceConfig.name}.enums.updDirection.`}
        enums={['INBOUND', 'OUTBOUND']}
      />
      <directionType.Input source={'direction'} />
      <docStatus.Input source={'docStatus'} />
      <TextInput source={'docnum'} />
      <TextInput source={'documentId'} />
      <NumberInput source={'documentLinkId'} />
      <TextInput source={'documentLinkType'} />
      <docStatusSystem.Input source={'documentSystemStatus'} />
      <NumberInput source={'errorModelId'} />
      <TextInput source={'errors'} />
      <TextInput source={'guid'} />
      <TextInput source={'ismpDocType'} />
      <TextInput source={'numberSupply'} />
      <TextInput source={'orgNameBuyer'} />
      <TextInput source={'orgNameSeller'} />
      <DateInput
        source={'processedDate'}
        label={`resources.${resourceConfig.name}.fields.processedDate`}
      />
      <TextInput source={'receiverId'} />
      <TextInput source={'requestId'} />
      <TextInput source={'subjectId'} />
      <TextInput source={'surname'} />
      <NumberInput source={'total'} />
      <DateInput
        source={'invoiceCreationTime'}
        label={`resources.${resourceConfig.name}.fields.invoiceCreationTime`}
      />
      <TextInput source={'invoiceReason'} />
      <TextInput source={'infoFhj'} />
      <TextInput source={'valueFhj'} />
      <BooleanInput source={'runningBefore'} />
      <BooleanInput source={'additional.addressBuyer'} />
      <TextInput source={'additional.addressCodeBuyer'} />
      <TextInput source={'additional.addressCodeConsignee'} />
      <TextInput source={'additional.addressCodeSeller'} />
      <TextInput source={'additional.addressCodeShipper'} />
      <BooleanInput source={'additional.addressConsignee'} />
      <BooleanInput source={'additional.addressSeller'} />
      <BooleanInput source={'additional.addressShipper'} />
      <TextInput source={'additional.addressTextBuyer'} />
      <TextInput source={'additional.addressTextConsignee'} />
      <TextInput source={'additional.addressTextSeller'} />
      <TextInput source={'additional.addressTextShipper'} />
      <TextInput source={'additional.apartmentBuyer'} />
      <TextInput source={'additional.apartmentConsignee'} />
      <TextInput source={'additional.apartmentSeller'} />
      <TextInput source={'additional.apartmentShipper'} />
      <TextInput source={'additional.baseDocumentName'} />
      <TextInput source={'additional.blockBuyer'} />
      <TextInput source={'additional.blockConsignee'} />
      <TextInput source={'additional.blockSeller'} />
      <TextInput source={'additional.blockShipper'} />
      <TextInput source={'additional.buildingBuyer'} />
      <TextInput source={'additional.buildingConsignee'} />
      <TextInput source={'additional.buildingSeller'} />
      <TextInput source={'additional.buildingShipper'} />
      <TextInput source={'additional.cityBuyer'} />
      <TextInput source={'additional.cityConsignee'} />
      <TextInput source={'additional.citySeller'} />
      <TextInput source={'additional.cityShipper'} />
      <TextInput source={'additional.countryCodeBuyer'} />
      <TextInput source={'additional.countryCodeConsignee'} />
      <TextInput source={'additional.countryCodeSeller'} />
      <TextInput source={'additional.countryCodeShipper'} />
      <TextInput source={'additional.currency'} />
      <TextInput source={'additional.currencyName'} />
      <DateInput
        source={'additional.datetimeInfSeller'}
        label={`resources.${resourceConfig.name}.fields.additional.datetimeInfSeller`}
      />
      <TextInput source={'additional.documentCreator'} />
      <DateInput
        source={'additional.documentDate'}
        label={`resources.${resourceConfig.name}.fields.additional.documentDate`}
      />
      <TextInput source={'additional.documentName'} />
      <TextInput source={'additional.documentNamePFHJ'} />
      <TextInput source={'additional.documentNumber'} />
      <TextInput source={'additional.firstName'} />
      <TextInput source={'additional.fnsParticipantId'} />
      <TextInput source={'additional.function'} />
      <NumberInput source={'additional.id'} />
      <TextInput source={'additional.idFile'} />
      <TextInput source={'additional.idReceipt'} />
      <TextInput source={'additional.idSender'} />
      <TextInput source={'additional.innEdo'} />
      <TextInput source={'additional.innBuyer'} />
      <TextInput source={'additional.innConsignee'} />
      <TextInput source={'additional.innSeller'} />
      <TextInput source={'additional.innShipper'} />
      <TextInput source={'additional.innSigner'} />
      <TextInput source={'additional.jobTitle'} />
      <TextInput source={'additional.kndCode'} />
      <TextInput source={'additional.kppBuyer'} />
      <TextInput source={'additional.kppConsignee'} />
      <TextInput source={'additional.kppSeller'} />
      <TextInput source={'additional.kppShipper'} />
      <TextInput source={'additional.localityBuyer'} />
      <TextInput source={'additional.localityConsignee'} />
      <TextInput source={'additional.localitySeller'} />
      <TextInput source={'additional.localityShipper'} />
      <TextInput source={'additional.nameOrgEdo'} />
      <TextInput source={'additional.operationInfo'} />
      <TextInput source={'additional.orgNameConsignee'} />
      <TextInput source={'additional.orgNameShipper'} />
      <TextInput source={'additional.orgTypeBuyer'} />
      <TextInput source={'additional.orgTypeConsignee'} />
      <TextInput source={'additional.orgTypeSeller'} />
      <TextInput source={'additional.orgTypeShipper'} />
      <TextInput source={'additional.patronymic'} />
      <TextInput source={'additional.regionCodeBuyer'} />
      <TextInput source={'additional.regionCodeConsignee'} />
      <TextInput source={'additional.regionCodeSeller'} />
      <TextInput source={'additional.regionCodeShipper'} />
      <TextInput source={'additional.signerOrganizationName'} />
      <TextInput source={'additional.signerPowers'} />
      <TextInput source={'additional.signerPowersBase'} />
      <TextInput source={'additional.signerStatus'} />
      <TextInput source={'additional.signerType'} />
      <TextInput source={'additional.streetBuyer'} />
      <TextInput source={'additional.streetConsignee'} />
      <TextInput source={'additional.streetSeller'} />
      <TextInput source={'additional.streetShipper'} />
      <TextInput source={'additional.territoryBuyer'} />
      <TextInput source={'additional.territoryConsignee'} />
      <TextInput source={'additional.territorySeller'} />
      <TextInput source={'additional.territoryShipper'} />
      <NumberInput source={'additional.totalNet'} />
      <NumberInput source={'additional.totalWithVatExcluded'} />
      <TextInput source={'additional.transferTextInfo'} />
      <NumberInput source={'additional.vat'} />
      <TextInput source={'additional.versForm'} />
      <TextInput source={'additional.versProg'} />
      <TextInput source={'additional.zipCodeBuyer'} />
      <TextInput source={'additional.zipCodeConsignee'} />
      <TextInput source={'additional.zipCodeSeller'} />
      <TextInput source={'additional.zipCodeShipper'} />
    </SimpleForm>
  </Edit>
);

const ResourceStringField = ({ record }) => <span>{resourceToString(record)}</span>;

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} {...props}>
    <ResourceStringField />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={resourceToString} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={resourceToString} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Create: ResourceCreate,
  Edit: ResourceEdit,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    edit: ResourceEdit,
    create: ResourceCreate,
    icon: ResourceIcon,
  },
  menuConfig: {
    name: resourceConfig.name,
    icon: ResourceIcon,
  },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {
      invoiceCreationTime: {
        type: 'dateTime',
      },
      dateSupply: {
        type: 'dateTime',
      },
      processedDate: {
        type: 'dateTime',
      },
      'additional.datetimeInfSeller': {
        type: 'dateTime',
      },
      'additional.documentDate': {
        type: 'dateTime',
      },
    },
  },
};
