import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'CUSTOM LOAD EDIT',
      title: 'CUSTOM LOAD EDIT',
      executeAction: 'Отправить',
      errorMessage: 'Ошибка',
      successMessage: 'Все %{count} строки добавлены',
      addedItemsCount: 'Добавлено строк',
      errorItems: 'Ошибки',
      fields: {},
    },
  },
};
