import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Ввод в оборот (товары)',
      fields: {
        id: '#',
        inputPId: 'Родительский документ',

        cis: 'КИС',
        color: 'Цвет',
        packType: 'Тип упаковки',
        productSize: 'Размер',
      },
    }
  }
}