import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Report P |||| Report P records',
      fields: {
        id: '#',
        docHId: 'Документ',
        idpos: 'Id позиции',
        numdoc: 'Номер позиции',
        product_cost: 'Цена',
        product_description: 'Описание товара',
        product_tax: 'Налог',
        uit_code: 'Код маркировки',
        uitu_code: 'Код агрегата',
        accepted: 'Принят',
      },
    }
  }
}