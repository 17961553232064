import React, { Fragment } from 'react';
import {
  translate,
  Datagrid,
  Edit,
  Create,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Filter,
  BulkDeleteButton,
  CreateButton,
  BooleanInput,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Toolbar from '@material-ui/core/Toolbar';
import DateInput from '../../../../components/DateInput';
import UserWarehouseInput from '../../../../components/UserWarehouseInput';

import LinkToList from './LinkToList';
import DirectionType from '../directionType';
import DocP from '../docP';
import SendOrderButton from './SendOrderButton';
import AcceptDocButton from './AcceptDocButton';
import resourceLocales from './locales';
import resourceConfig from './config';
import docStatus from '../docStatus';
import docLoadCustom from '../docLoadCustom';
import docStatusSystem from '../docStatusSystem';
import requestType from '../requestType';
import turnoverType from '../turnoverType';
import documentRowStyle from '../../../../utils/ui/documentRowStyle';
import errorModel from '../errorModel';
import objectSendModel from '../objectSendModel';
import documentImport from '../documentImport';
import exporter from '../../../../utils/exporter';
import docCheckStatus from '../docCheckStatus';
import docUpdate from '../docUpdate';
import docShipmentReport from '../docShipmentReport';
import SendDocs from './SendDocs';
import withdrawalType from '../withdrawalType';
import PostPagination from '../../../../utils/pagination';
import RejectAll from './RejectAll';
import AcceptAll from './AcceptAll';
import docHMassInput from '../docHMassInput';
import docEaeuShipment from '../docEaeuShipments';
import ExportDocButton from './ExportDocButton';
const ResourceIcon = BookmarkIcon;

const listStyles = {};

const ShipmentReport = (props) => {
  let params = {};
  if (props.selectedIds && props.selectedIds.length > 0) {
    params.ids = props.selectedIds;
  }
  return <docShipmentReport.OpenButton params={params} />;
};

const CreateDocEaeuReport = (props) => {
  let params = {};
  if (props.filter && Object.keys(props.filter).length > 0) {
    params = {
      ...params,
      ...props.filter,
    };
  }
  if (props.selectedIds && props.selectedIds.length > 0) {
    params.ids = props.selectedIds;
  }
  return <docEaeuShipment.OpenButton params={params} />;
};

const ResourceBulkActionButtons = (props) => (
  <Fragment>
    <docUpdate.Button {...props} bulk docType={'DOC'} />
    <SendDocs {...props} />
    <ShipmentReport {...props} />
    <AcceptAll {...props} />
    <RejectAll {...props} />
    <BulkDeleteButton {...props} />
  </Fragment>
);

const ResourceListFilter = (props) => (
  <Filter {...props}>
    <TextInput source={'ids'} />
    <docCheckStatus.Input source={'checkStatus'} />
    <UserWarehouseInput source={'warehouseFrom'} />
    <UserWarehouseInput source={'warehouseTo'} />
    <DateInput
      source={'processedDate'}
      label={`resources.${resourceConfig.name}.fields.processedDate`}
    />
    <DateInput
      source={'document_date'}
      label={`resources.${resourceConfig.name}.fields.document_date`}
    />
    <TextInput source={'guid'} />
    <TextInput source={'owner_inn'} />
    <TextInput source={'receiver_inn'} />
    <TextInput source={'sender_inn'} />
    <DateInput
      source={'transferDate'}
      label={`resources.${resourceConfig.name}.fields.transferDate`}
    />
    <turnoverType.Input source={'turnoverType'} />
    <TextInput source={'docnum'} />
    <TextInput source={'guids'} />
    <TextInput source={'docnums'} />
    <TextInput source={'releaseOrderNumber'} />
    <DateInput
      source={'document_date_from'}
      label={`resources.${resourceConfig.name}.fields.document_date_from`}
    />

    <DateInput
      source={'document_date_to'}
      label={`resources.${resourceConfig.name}.fields.document_date_to`}
    />
  </Filter>
);

const ChildrenButton = (props) => {
  return (
    <DocP.LinkToList
      label={`resources.${resourceConfig.name}.docP`}
      filter={{
        overEntityIds: props.record.id,
      }}
    />
  );
};

const ResourceListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  translate,
}) => (
  <Toolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <documentImport.OpenButton params={{ docType: 'DOC' }} />
    <docHMassInput.OpenButton />
    <CreateDocEaeuReport filter={filterValues} />
    <CreateButton basePath={basePath} />
    <ExportDocButton 
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      basePath={basePath}
      resourceConfig={resourceConfig}
    />
    <docLoadCustom.OpenButton />
  </Toolbar>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
  <List
    {...props}
    actions={<ResourceListActions />}
    filters={<ResourceListFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={<ResourceBulkActionButtons />}
    exporter={exporter}
    pagination={<PostPagination />}>
    <Datagrid rowStyle={documentRowStyle}>
      <TextField source={'id'} />

      <DirectionType.Field source={'direction'} />
      <TextField source={'requestType'} />
      <TextField source={'sender'} />
      <TextField source={'receiver'} />
      <TextField source={'guid'} />

      <TextField source={'docnum'} />
      <TextField source={'document_date'} />
      <TextField source={'sender_inn'} />
      <TextField source={'receiver_inn'} />
      <docCheckStatus.Field source={'checkStatus'} />
      <TextField source={'receiverCountryCode'} />
      <errorModel.LinkToRelatedList type={'DOC'} />
      <objectSendModel.LinkToRelatedList type={'DOC'} />
      <AcceptDocButton />
      <SendOrderButton />
      <ChildrenButton />
      <EditButton />
    </Datagrid>
  </List>
));

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.name}
    &quot;
  </span>
));

const ResourceCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <DateInput
        source={'acceptance_date'}
        label={`resources.${resourceConfig.name}.fields.acceptance_date`}
      />
      <DirectionType.Input source={'direction'} />
      <DateInput
        source={'processedDate'}
        label={`resources.${resourceConfig.name}.fields.processedDate`}
      />
      <DateInput
        source={'document_date'}
        label={`resources.${resourceConfig.name}.fields.document_date`}
      />
      <TextInput source={'errors'} />
      <TextInput source={'guid'} />
      <TextInput source={'owner'} />
      <TextInput source={'owner_inn'} />
      <TextInput source={'pdf'} />
      <TextInput source={'receiver'} />
      <TextInput source={'receiver_inn'} />
      <TextInput source={'releaseOrderNumber'} />
      <requestType.Input source={'requestType'} defaultValue={'SHIPMENT'} />
      <TextInput source={'sender'} />
      <TextInput source={'sender_inn'} />

      <TextInput source={'trade_sender_inn'} />
      <TextInput source={'trade_sender_name'} />
      <TextInput source={'trade_owner_inn'} />
      <TextInput source={'trade_owner_name'} />
      <TextInput source={'trade_recipient_inn'} />

      <DateInput
        source={'transferDate'}
        label={`resources.${resourceConfig.name}.fields.transferDate`}
      />
      <turnoverType.Input source={'turnoverType'} />
      <TextInput source={'actionId'} />
      {/* <TextInput source={'creatorUsername'} /> */}
      <docStatus.Input source={'docStatus'} />
      <TextInput source={'docnum'} />
      <TextInput source={'documentId'} />
      {/* "documentLinkId": 0, */}
      {/* "documentLinkType": "DOC", */}
      <docStatusSystem.Input source={'documentSystemStatus'} />
      <TextInput source={'receiverId'} />
      <TextInput source={'requestId'} />
      <TextInput source={'subjectId'} />
      <docCheckStatus.Input source={'checkStatus'} />

      <UserWarehouseInput source={'warehouseFrom'} />
      <UserWarehouseInput source={'warehouseTo'} />

      <BooleanInput source={'needSSCC'} />

      <DateInput
        source={'withdrawalDate'}
        label={`resources.${resourceConfig.name}.fields.withdrawalDate`}
      />
      <withdrawalType.Input source={'withdrawalType'} />

      <TextInput source={'numUpd'} />
      <TextInput source={'torg2'} />
      <BooleanInput source={'virtual'} />

      <BooleanInput source={'needVirtualSSCC'} />
      <TextInput source={'virtualSSCC'} />
      <TextInput source={'numUCD'} />
      <BooleanInput source={'codesReturned'} />
      <TextInput source={'companyIn'} />
      <TextInput source={'companyOut'} />
      <TextInput source={'receiverCountryCode'} />
      <BooleanInput source={'shippingNotice'} />
      <TextInput source={'receiverGln'} />
    </SimpleForm>
  </Create>
);

const ResourceEdit = (props) => (
  <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
    <SimpleForm redirect={false}>
      <TextField source={'id'} />
      <DateInput
        source={'acceptance_date'}
        label={`resources.${resourceConfig.name}.fields.acceptance_date`}
      />
      <DirectionType.Input source={'direction'} />
      <DateInput
        source={'processedDate'}
        label={`resources.${resourceConfig.name}.fields.processedDate`}
      />
      <TextField source={'timeCreate'} />
      <TextField source={'dateCreate'} />
      <DateInput
        source={'document_date'}
        label={`resources.${resourceConfig.name}.fields.document_date`}
      />
      <TextInput source={'errors'} />
      <TextInput source={'guid'} />
      <TextInput source={'owner'} />
      <TextInput source={'owner_inn'} />
      <TextInput source={'pdf'} />
      <TextInput source={'receiver'} />
      <TextInput source={'receiver_inn'} />
      <TextInput source={'releaseOrderNumber'} />
      <requestType.Input source={'requestType'} />
      <TextInput source={'sender'} />
      <TextInput source={'sender_inn'} />
      <TextInput source={'trade_sender_inn'} />
      <TextInput source={'trade_sender_name'} />
      <TextInput source={'trade_owner_inn'} />
      <TextInput source={'trade_owner_name'} />
      <TextInput source={'trade_recipient_inn'} />

      <DateInput
        source={'transferDate'}
        label={`resources.${resourceConfig.name}.fields.transferDate`}
      />
      <turnoverType.Input source={'turnoverType'} />
      <TextInput source={'actionId'} />
      <TextInput source={'creatorUsername'} />
      <docStatus.Input source={'docStatus'} />
      <TextInput source={'docnum'} />
      <TextInput source={'documentId'} />
      {/* "documentLinkId": 0, */}
      {/* "documentLinkType": "DOC", */}
      <docStatusSystem.Input source={'documentSystemStatus'} />
      <TextInput source={'receiverId'} />
      <TextInput source={'requestId'} />
      <TextInput source={'subjectId'} />
      <docCheckStatus.Input source={'checkStatus'} />

      <UserWarehouseInput source={'warehouseFrom'} />
      <UserWarehouseInput source={'warehouseTo'} />

      <BooleanInput source={'needSSCC'} />

      <DateInput
        source={'withdrawalDate'}
        label={`resources.${resourceConfig.name}.fields.withdrawalDate`}
      />
      <withdrawalType.Input source={'withdrawalType'} />

      <TextInput source={'numUpd'} />
      <TextInput source={'torg2'} />
      <BooleanInput source={'virtual'} />

      <BooleanInput source={'needVirtualSSCC'} />
      <TextInput source={'virtualSSCC'} />
      <TextInput source={'numUCD'} />
      <BooleanInput source={'codesReturned'} />
      <TextInput source={'companyIn'} />
      <TextInput source={'companyOut'} />
      <TextInput source={'receiverCountryCode'} />
      <BooleanInput source={'shippingNotice'} />
      <TextInput source={'receiverGln'} />
    </SimpleForm>
  </Edit>
);

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} {...props}>
    <TextField source={'id'} />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={'id'} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={'id'} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Create: ResourceCreate,
  Edit: ResourceEdit,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,
  LinkToList,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    edit: ResourceEdit,
    create: ResourceCreate,
    icon: ResourceIcon,
  },
  menuConfig: {
    name: resourceConfig.name,
    icon: ResourceIcon,
  },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {
      acceptance_date: {
        type: 'dateTime',
      },
      processedDate: {
        type: 'dateTime',
      },
      document_date: {
        type: 'dateTime',
        sortingField: 'documentDate',
      },
      transferDate: {
        type: 'dateTime',
      },
      withdrawalDate: {
        type: 'dateTime',
      },
    },
    filters: {
      processedDate: {
        type: 'dateTime',
      },
      document_date: {
        type: 'dateTime',
      },
      document_date_to: {
        type: 'dateTime',
      },
      document_date_from: {
        type: 'dateTime',
      },
      transferDate: {
        type: 'dateTime',
      },
      documentDatesFrom: {
        type: 'dateTime',
      },
      documentDatesTo: {
        type: 'dateTime',
      },
    },
  },
};
