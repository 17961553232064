import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      action: 'Check status',
      successAllMessage: 'Success',
      successWithFailedMessage: 'Some requests was failed. %{success} of %{total} requests was success',
      errorMessage: 'Error',
      errorOneMessage: 'Failed request for ID %{id}',
    }
  }
}