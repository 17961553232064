import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        DAMAGED_OR_LOST: 'Damaged or lost',
        ERROR_DETECTED: 'Error detected',
        KM_SPOILED: 'Km spoiled',
        DESCRIPTION_ERRORS: 'Description errors',
        RETAIL_RETURN: 'Retail return',
        REMOTE_SALE_RETURN: 'Remote sale return',
        LEGAL_RETURN: 'Legal Return',
        INTERNAL_RETURN: 'Internal Return',
        EEC_EXPORT_RETURN: 'Eec Export Return',
      },
    }
  }
}