import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        CERTIFICATE: 'CERTIFICATE',
        DECLARATION: 'DECLARATION',
      },
    },
  },
};
