import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'EAEU shipments',
      title: 'EAEU shipments',
      executeAction: 'Submit',
      errorMessage: 'Error',
      successMessage: 'Request successfully sent',
      filterErrorMessage: 'Select date range doc date from and doc date to',
    },
  },
};
