import React, { Fragment } from 'react';
import {
  translate,
  Datagrid,
  Edit,
  Create,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Filter,
  BulkDeleteButton,
  NumberInput,
  BooleanInput,
  CreateButton,
  ExportButton,
  DateField,
} from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import DateInput from '../../../../components/DateInput';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';

import LinkToList from './LinkToList';
import SendRemarkButton from './SendRemarkButton';
import resourceLocales from './locales';
import resourceConfig from './config';
import documentRowStyle from '../../../../utils/ui/documentRowStyle';
import errorModel from '../errorModel';
import objectSendModel from '../objectSendModel';
import outActionType from '../outActionType';
import documentImport from '../documentImport';
import exporter from '../../../../utils/exporter';
import docUpdate from '../docUpdate';
import docCheckStatus from '../docCheckStatus';
import PostPagination from '../../../../utils/pagination';
import pdInformationP from '../pdInformationP';
import ExportDocButton from '../docH/ExportDocButton';

const ResourceIcon = BookmarkIcon;

const listStyles = {};

const ResourceBulkActionButtons = (props) => (
  <Fragment>
    <docUpdate.Button {...props} bulk docType={'PD_INFORMATION'} />
    <BulkDeleteButton {...props} />
  </Fragment>
);

const ResourceListFilter = (props) => (
  <Filter {...props}>
    <TextInput source={'ids'} />
    <TextInput source={'participantInn'} />
    <DateInput source={'dateCreate'} />
    {/* <DateInput source={'docDatesFrom'} label={`resources.${resourceConfig.name}.fields.docDatesFrom`} />
        <DateInput source={'docDateTo'} label={`resources.${resourceConfig.name}.fields.docDateTo`} /> */}
  </Filter>
);

const ChildrenPButton = (props) => {
  return (
    <pdInformationP.LinkToList
      label={`resources.${resourceConfig.name}.pdInformationP`}
      filter={{
        overEntityIds: props.record.id,
      }}
    />
  );
};

const ResourceActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  translate,
}) => (
  <Toolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <documentImport.OpenButton params={{ docType: 'PD_INFORMATION' }} />
    <CreateButton basePath={basePath} to={`${basePath}/create`} />
    <ExportDocButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
  <List
    {...props}
    filters={<ResourceListFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    actions={<ResourceActions />}
    bulkActionButtons={<ResourceBulkActionButtons />}
    exporter={exporter}
    pagination={<PostPagination />}>
    <Datagrid rowStyle={documentRowStyle}>
      <TextField source={'id'} />

      <TextField source={'participantInn'} />
      <DateField source={'dateCreate'} />
      <TextField source={'guid'} />
      <docCheckStatus.Field source={'checkStatus'} />
      <errorModel.LinkToRelatedList type={'PD_INFORMATION'} />
      <objectSendModel.LinkToRelatedList type={'PD_INFORMATION'} />
      <SendRemarkButton />
      <ChildrenPButton />
      <EditButton />
    </Datagrid>
  </List>
));

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.name}
    &quot;
  </span>
));

const ResourceCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source={'participantInn'} />
      <DateInput source={'dateCreate'} />
      <TextInput source={'guid'} />
      <outActionType.Input source={'action'} />
      <directionType source={'direction'} />
      <docStatus source={'docStatus'} />
      <TextInput source={'docnum'} />
      <TextInput source={'documentId'} />
      <NumberInput source={'documentLinkId'} />
      <docLinkType source={'documentLinkType'} />
      <docStatusSystem source={'documentSystemStatus'} />
      <NumberInput source={'documentType'} />
      <TextInput source={'receiverId'} />
      <TextInput source={'requestId'} />
      <TextInput source={'subjectId'} />
      <BooleanInput source={'sendBefore'} />
      <TextInput source={'withdrawalTypeOther'} />
    </SimpleForm>
  </Create>
);

const ResourceEdit = (props) => (
  <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
    <SimpleForm redirect={false}>
      <TextField source={'id'} />
      <TextInput source={'participantInn'} />
      <DateInput source={'dateCreate'} />
      <TextInput source={'guid'} />
      <outActionType.Input source={'action'} />
      <directionType source={'direction'} />
      <docStatus source={'docStatus'} />
      <TextInput source={'docnum'} />
      <TextInput source={'documentId'} />
      <NumberInput source={'documentLinkId'} />
      <docLinkType source={'documentLinkType'} />
      <docStatusSystem source={'documentSystemStatus'} />
      <NumberInput source={'documentType'} />
      <TextInput source={'receiverId'} />
      <TextInput source={'requestId'} />
      <TextInput source={'subjectId'} />
      <BooleanInput source={'sendBefore'} />
    </SimpleForm>
  </Edit>
);

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} {...props}>
    <TextField source={'id'} />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={'id'} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={'id'} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Create: ResourceCreate,
  Edit: ResourceEdit,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,
  LinkToList,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    edit: ResourceEdit,
    create: ResourceCreate,
    icon: ResourceIcon,
  },
  menuConfig: {
    name: resourceConfig.name,
    icon: ResourceIcon,
  },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {
      actionDate: {
        type: 'dateTime',
      },
      dateCreate: {
        type: 'dateTime',
      },
      cancellationDocDate: {
        type: 'dateTime',
      },
      documentDate: {
        type: 'dateTime',
      },
      orderDate: {
        type: 'dateTime',
      },
      processedDate: {
        type: 'dateTime',
      },
    },
    filters: {
      actionDate: {
        type: 'dateTime',
      },
      cancellationDocDate: {
        type: 'dateTime',
      },
      documentDate: {
        type: 'dateTime',
      },
      orderDate: {
        type: 'dateTime',
      },
      processedDate: {
        type: 'dateTime',
      },
      docDatesFrom: {
        type: 'dateTime',
      },
      docDateTo: {
        type: 'dateTime',
      },
    },
  },
};
