import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Позиции возврата',
      fields: {
        certificateDate: 'Дата сертификата',
        primaryDocumentDate: 'Дата главного документа',
        certificateNumber: 'Номер сертификата',
        certificateType: 'Тип сертификата',
        paid: 'Оплачено',
        primaryDocumentCustomName: 'Имя основного документа',
        primaryDocumentNumber: 'Номер основного документа',
        primaryDocumentType: 'Тип основного документа',

        returnHId: 'Документ',
        ki: 'КИ',
        mrkSystemStatus: 'Статус маркировки',
        numGoods: 'Номер товара',
      },
    }
  }
}