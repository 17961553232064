export default {
  enums: [
    { id: 'IN_PROGRESS' },
    { id: 'CHECKED_OK' },
    { id: 'CHECKED_NOT_OK' },
    { id: 'PROCESSING_ERROR' },
    { id: 'CANCELLED' },
    { id: 'ACCEPTED' },
    { id: 'WAIT_ACCEPTANCE' },
    { id: 'WAIT_PARTICIPANT_REGISTRATION' },
    { id: 'UNDEFINED', },
  ]
};
