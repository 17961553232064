

export default {
  enums: [
    {id:'EMPTY'},
    {id:'RESERVED_NOT_USED'},
    {id:'WAIT_SHIPMENT'},
    {id:'EXPORTED'},
    {id:'LOAN_RETIRED'},
    {id:'REMARK_RETIRED'},
    {id:'WAIT_TRANSFER_TO_OWNER'},
    {id:'WAIT_REMARK'},
    {id:'RETIRED_CANCELLATION'},
    {id:'RETIRED_DONATION'},
    {id:'RETIRED_STATE_ENTERPRISE'},
    {id:'RETIRED_NO_RETAIL_USE'},
    {id:'RETIRED_BEYOND_EEC_EXPORT'},
    {id:'RETIRED_REMOTE_SALE'},
    {id:'RETIRED_EEC_EXPORT'},
    {id:'RETIRED_RETURN'},
    {id:'RETIRED_DAMAGE_LOSS'},
    {id:'RETIRED_DESTRUCTION'},
    {id:'RETIRED_CONFISCATION'},
    // { id: 'CANCELLATION_APPLIED', },
    // { id: 'REMARK_RETIRED', },
    // { id: 'RETIRED_CANCELLATION', },
    // { id: 'DONATION', },
    // { id: 'STATE_ENTERPRISE', },
    // { id: 'NO_RETAIL_USE', },
    // { id: 'BEYOND_EEC_EXPORT', },
    // { id: 'СREMOTE_SALE', },
    // { id: 'EEC_EXPORT', },
    // { id: 'СRETURN', },
    // { id: 'DAMAGE_LOSS', },
    // { id: 'DESTRUCTION', },
    // { id: 'CONFISCATION', },
    // { id: 'LIQUIDATION', },
  ]
};
