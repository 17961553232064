import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'НК Товар |||| НК Товары',
      sign: {
        action: 'Подписать',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      check: {
        action: 'Проверить',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      load: {
        action: 'Загрузить товары',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      enums: {
        goodDetailedStatus: {
          DRAFT: 'Черновик',
          MODERATION: 'На модерации',
          ERRORS: 'Требует изменений',
          NOTSIGNED: 'Ожидает подписания',
          PUBLISHED: 'Опубликовано',
          ARCHIVED: 'В архиве',
        },
      },
      fields: {
        id: 'Id',
        date: 'Дата создания',
        feedId: 'Id запроса',
        goodId: 'Id товара',
        goodName: 'Наименование товара',
        gtin: 'GTIN',
        kit: 'Комплект',
        set: 'Набор',
        tnved: 'Код группы ТН ВЭД',
        categories: 'Категории',
        attrId: 'Id атрибута',
        attrValue: 'Значение атрибута',
        attrValueType: 'Тип значения атрибута',
        goodAttrs: 'Атрибуты',
        catId: 'Id категормм',
        identifiedBy: 'Идентификаторы',
        level: 'Тип упаковки',
        multiplier: 'Мультипликатор',
        type: 'Тип идентификатора',
        value: 'Значение кода товара упаковки',
        quantity: 'Количество',
        setGtins: 'Вложения в набор',
        brandId: 'Id товарного знака',
        brandName: 'Наименование товарного знака',
        flagUpdatedDate: 'Дата обновления флагов',
        goodDetailedStatus: 'Текущий статус карточки',
        goodMarkFlag: 'Признак заполнения атрибутов для эмиссии',
        goodRating: 'Рейтинг товара',
        goodSigned: 'Признак подписания карточки',
        goodStatus: 'Технологическийстатускарточки',
        goodTurnFlag: 'Признак заполнения атрибутов для ввода в оборот',
        goodUrl: 'Ссылка на страницу товара',
        producerInn: 'ИНН компании',
        producerName: 'Наименование компании',
        remainderType: 'Тип описания остатка',
        updatedDate: 'Дата обновления технологического статуса карточки',
        attrGroupId: 'Id группыатрибутов',
        attrGroupName: 'Наименование группы атрибутов',
        attrName: 'Наименование атрибута',
        valueId: 'Id значения атрибута',
        catName: 'Наименование категории',
      },
    },
  },
};
