import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Создать отчет',
      title: 'Создать отчет',
      executeAction: 'Подтвердить',
      errorMessage: 'Ошибка',
      successMessage: 'Запрос успешно отправлен',
      filterErrorMessage: 'Выберите любой фильтр по дате',
    },
  },
};
