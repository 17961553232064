import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Позиции УПД |||| Позиции УПД',
      updM: 'Подпозиции',
      fields: {
        id: '#',
        updPId: 'УПД',
        country: 'Код страны происхождения товара',
        declarationNumber: 'Номер таможенной декларации',
        excise: 'Акциз',
        hyphenCountry: 'признак того, что цифровой код страны отсутствует',
        hyphenUnit: 'Признак того, что код единицы измерения не указан',
        itemVendorCode: 'Характеристика/код/артикул/сорт товара',
        numberString: 'Номер строки таблицы информации продавца ',
        price: 'Цена (тариф) за единицу товара',
        product: 'Наименование товара',
        quantity: 'Количество единиц товара',
        subtotal: 'Сумма всего',
        subtotalWithVatExcluded: 'Сумма без учета налога',
        taxRate: 'Налоговая ставка',
        unit: 'Единицы измерения товара (код)',
        vat: 'Всего к оплате, сумма налога, предъявляемая покупателю',
      },
    }
  }
}