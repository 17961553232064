import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Inventory P |||| Inventory P',
      enums: {
        result: {
          OK: 'Ok',
          NOTOK: 'Not ok',
          OVER: 'Over',
          SHORTFALL: 'Shortfall',
          DIFFERENT_VENDOR_DATA: 'Different vendor data',
          CODE_NOT_FOUND: 'Code not found',
          ERROR: 'ERROR',
        },
      },
      fields: {
        id: '#',
        inventoryHId: 'Document',
        gtin: 'GTIN',
        result: 'Result',
      },
    },
  },
};
