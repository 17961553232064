import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Document |||| Documents',
      docP: 'Records',
      sendDoc: {
        action: 'Send',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      sendDocs: {
        action: 'Send docs',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      acceptAll: {
        action: 'Accept all positions',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      rejectAll: {
        action: 'Reject all positions',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      acceptDoc: {
        action: 'Accept',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      sync: {
        action: 'Sync',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      exportDoc: {
        action: 'Export',
        successMessage: 'File generation in progress. Find the file in the "Export files" journal',
        errorMessage: 'Error',
      },
      fields: {
        transferDate: 'Transfer date',
        processedDate: 'Processed date',
        acceptance_date: 'Acceptance date',
        document_date: 'Document date',
        direction: 'Direction',
        errors: 'Errors',
        guid: 'Guid',
        owner: 'Owner',
        owner_inn: 'Owner INN',
        pdf: 'PDF',
        receiver: 'Receiver',
        receiver_inn: 'Receiver INN',
        releaseOrderNumber: 'Release Order Number',
        requestType: 'Request Type',
        sender: 'Sender',
        sender_inn: 'Sender INN',
        trade_owner_inn: 'Trade Owner INN',
        trade_owner_name: 'Trade Owner Name',
        trade_sender_inn: 'Trade Sender INN',
        trade_sender_name: 'Trade Sender Name',
        trade_recipient_inn: 'Trade Recipient INN',
        turnoverType: 'Turnover Type',
        actionId: 'Action ID',
        creatorUsername: 'Creator Username',
        docnum: 'Docnum',
        documentId: 'Document Id',
        documentSystemStatus: 'Doc System Status',
        receiverId: 'Receiver Id',
        requestId: 'Request Id',
        subjectId: 'Subject Id',
        document_date_to: 'Document date to',
        document_date_from: 'Document date from',
        numUpd: 'UPD number',
        torg2: 'Torg2',
        virtual: 'Virtual',
      },
    },
  },
};
