import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        DEFECT: 'Брак',
        EXPIRY: 'Истек срок годности',
        QA_SAMPLES: 'Лабораторные образцы',
        PRODUCT_REACALL: 'Отзыв с рынка',
        COMPLAINTS: 'Рекламации',
        PRODUCT_TESTING: 'Тестирование продукта',
        DEMO_SAMPLES: 'Демонстрационные образцы',
        OTHER: 'Другие причины',
        DAMAGE_LOSS: 'Утрата товара',
        DESTRUCTION: 'Уничтожение товаров',
        LIQUIDATION: 'Ликвидация предприятия',
        CONFISCATION: 'Конфискация товаров',
        OWN_USE_EXCISE: 'Использование для собственных нужд с уплатой акциза',
      },
    }
  }
}
