import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Input journal',
      inputP: 'Records',
      sendInput: {
        action: 'Send',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      fields: {
        declarationDate: 'Declaration date',
        processedDate: 'Processed date',
        production_date: 'Production date',
        importDate: 'Import date',
        primaryDocumentDate: 'Primary document date',
        productReceivingDate: 'Product receiving date',
      }
    }
  }
}