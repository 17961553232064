import React, { Fragment } from 'react';
import {
  translate,
  Datagrid,
  Edit,
  Create,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Filter,
  BulkDeleteButton,
  CreateButton,
  ExportButton,
  DateField,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Toolbar from '@material-ui/core/Toolbar';

import LinkToList from './LinkToList';
// import CodeType from '../codeType';
// import EmissionType from '../emissionType';
// import MarkingType from '../markingType';
// import SerialNumType from '../serialNumType';
import resourceLocales from './locales';
import resourceConfig from './config';
import DateInput from '../../../../components/DateInput';
import conformityCertificateUpload from '../conformityCertificateUpload';
import EnumInput from '../../../../components/EnumInput';
import documentRowStyle from '../../../../utils/ui/documentRowStyle';
import exporter from '../../../../utils/exporter';
import EnumField from '../../../../components/EnumField';

const ResourceIcon = BookmarkIcon;

const trPrefix = `resources.${resourceConfig.name}`;

const listStyles = {};

const ResourceBulkActionButtons = (props) => (
  <Fragment>
    {/* <ArkForBrokerButton
            {...props}
        /> */}
    =
    <BulkDeleteButton {...props} />
  </Fragment>
);

const ResourceListFilter = (props) => (
  <Filter {...props}>
    <DateInput source={'certDate'} label={`${trPrefix}.fields.certDate`} />
    <DateInput source={'certDateBegin'} label={`${trPrefix}.fields.certDateBegin`} />
    <DateInput source={'certDateEnd'} label={`${trPrefix}.fields.certDateEnd`} />
    <EnumInput
      source={'certType'}
      translatePrefix={`${trPrefix}.enums.certType.`}
      label={`${trPrefix}.fields.certType`}
      enums={resourceConfig.enums.certType}
    />
    <TextInput source={'certNum'} />
    <TextInput source={'gtin'} />
  </Filter>
);

const ResourceListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  translate,
}) => (
  <Toolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <conformityCertificateUpload.OpenButton />
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
  <List
    {...props}
    filters={<ResourceListFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    actions={<ResourceListActions />}
    bulkActionButtons={<ResourceBulkActionButtons />}
    exporter={exporter}>
    <Datagrid rowStyle={documentRowStyle}>
      <TextField source={'id'} />

      <DateField source={'certDate'} />
      <DateField source={'certDateBegin'} />
      <DateField source={'certDateEnd'} />
      <EnumField
        source={'certType'}
        translatePrefix={`${trPrefix}.enums.certType.`}
        label={`${trPrefix}.fields.certType`}
        enums={resourceConfig.enums.certType}
      />
      <TextField source={'certNum'} />
      <TextField source={'gtin'} />
      <TextField source={'country'} />

      <EditButton />
    </Datagrid>
  </List>
));

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.certNum}
    &quot;
  </span>
));

const ResourceCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <DateInput source={'certDate'} label={`${trPrefix}.fields.certDate`} />
      <DateInput source={'certDateBegin'} label={`${trPrefix}.fields.certDateBegin`} />
      <DateInput source={'certDateEnd'} label={`${trPrefix}.fields.certDateEnd`} />
      <EnumInput
        source={'certType'}
        translatePrefix={`${trPrefix}.enums.certType.`}
        label={`${trPrefix}.fields.certType`}
        enums={resourceConfig.enums.certType}
      />
      <TextInput source={'certNum'} />
      <TextInput source={'gtin'} />
      <TextInput source={'country'} />
    </SimpleForm>
  </Create>
);

const ResourceEdit = (props) => (
  <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
    <SimpleForm redirect={false}>
      <DateInput source={'certDate'} label={`${trPrefix}.fields.certDate`} />
      <DateInput source={'certDateBegin'} label={`${trPrefix}.fields.certDateBegin`} />
      <DateInput source={'certDateEnd'} label={`${trPrefix}.fields.certDateEnd`} />
      <EnumInput
        source={'certType'}
        translatePrefix={`${trPrefix}.enums.certType.`}
        label={`${trPrefix}.fields.certType`}
        enums={resourceConfig.enums.certType}
      />
      <TextInput source={'certNum'} />
      <TextInput source={'gtin'} />
      <TextInput source={'country'} />
    </SimpleForm>
  </Edit>
);

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} {...props}>
    <TextField source={'id'} />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={'id'} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={'id'} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Create: ResourceCreate,
  Edit: ResourceEdit,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,
  LinkToList,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    edit: ResourceEdit,
    create: ResourceCreate,
    icon: ResourceIcon,
  },
  menuConfig: {
    name: resourceConfig.name,
    icon: ResourceIcon,
  },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {
      certDate: {
        type: 'dateTime',
      },
      certDateBegin: {
        type: 'dateTime',
      },
      certDateEnd: {
        type: 'dateTime',
      },
    },
  },
};
