import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'ERP',
      erpGoods: 'Goods',
      erpKM: 'KM',
      fields: {
        id: '#',
        acceptance_date: 'acceptance_date',
        action: 'action',
        actionId: 'actionId',
        action_date: 'action_date',
        certificate_date: 'certificate_date',
        certificate_number: 'certificate_number',
        certificate_type: 'certificate_type',
        checkStatus: 'checkStatus',
        direction: 'direction',
        docDateErp: 'docDateErp',
        docNumErp: 'docNumErp',
        docStatus: 'docStatus',
        docTypeErp: 'docTypeErp',
        doc_date_idocs: 'doc_date_idocs',
        doc_num_idocs: 'doc_num_idocs',
        doc_type_idocs: 'doc_type_idocs',
        docnum: 'docnum',
        documentId: 'documentId',
        documentLinkId: 'documentLinkId',
        documentLinkType: 'documentLinkType',
        documentSystemStatus: 'documentSystemStatus',
        document_date: 'document_date',
        document_number: 'document_number',
        errorModelId: 'errorModelId',
        errors: 'errors',
        flag_create: 'flag_create',
        guid: 'guid',
        ismpDocType: 'ismpDocType',
        out_doc_type: 'out_doc_type',
        owner: 'owner',
        owner_inn: 'owner_inn',
        paid: 'paid',
        primary_document_custom_name: 'primary_document_custom_name',
        primary_document_date: 'primary_document_date',
        primary_document_number: 'primary_document_number',
        primary_document_type: 'primary_document_type',
        processedDate: 'processedDate',
        receiver: 'receiver',
        receiverId: 'receiverId',
        receiver_inn: 'receiver_inn',
        release_order_number: 'release_order_number',
        requestId: 'requestId',
        request_type: 'request_type',
        return_type: 'return_type',
        sender: 'sender',
        senderInn: 'senderInn',
        subjectId: 'subjectId',
        tradeRecipientInn: 'tradeRecipientInn',
        tradeSender_name: 'tradeSender_name',
        trade_owner_inn: 'trade_owner_inn',
        trade_owner_name: 'trade_owner_name',
        trade_participant_inn: 'trade_participant_inn',
        trade_sender_inn: 'trade_sender_inn',
        transfer_date: 'transfer_date',
        turnover_type: 'turnover_type',
        warehouseFrom: 'warehouseFrom',
        warehouseTo: 'warehouseTo',
      },
    }
  }
}