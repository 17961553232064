import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Report P |||| Report P records',
      fields: {
        id: 'ID',
      },
    }
  }
}