import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Позиции агрегации',
      aggrM: 'Записи',
      fields: {
        aggrHId: 'Родительский эл-т',
        unitSerialNumber: 'Серийный номер юнита',
      },
    }
  }
}