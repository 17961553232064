import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'NK mapping',
      fields: {
      }
    }
  }
}
