import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Импорт',
      title: 'Соответствие артикулов импорт',
      executeAction: 'Отправить',
      errorMessage: 'Ошибка',
      successMessage: 'Все %{count} строки добавлены',
      addedItemsCount: 'Добавлено строк',
      errorItems: 'Ошибки',
      fields: {
      },
    }
  }
}