import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        CONFORMITY_CERTIFICATE: 'Сертификат соответствия',
        CONFORMITY_DECLARATION: 'Декларация соответствия',
      },
    }
  }
}
