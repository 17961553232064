import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Сертификат соотв. |||| Сертификаты соотв.',
      enums: {
        certType: {
          CONFORMITY_CERTIFICATE: 'Сертификат соответствия',
          CONFORMITY_DECLARATION: 'Декларация соответствия',
        },
      },
      fields: {
        certDate: 'Дата сертификата',
        certDateBegin: 'Дата действия сертификата (от)',
        certDateEnd: 'Дата действия сертификата (до)',
        certType: 'Тип сертификата',
        certNum: 'Номер сертификата',
        gtin: 'GTIN',
        country: 'Страна',
      }
    }
  }
}