import React, { Fragment } from 'react';
import {
  translate,
  Datagrid,
  Edit,
  Create,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  // NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Filter,
  BulkDeleteButton,
  CreateButton,
  ExportButton,
  BooleanInput,
  DateField,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Toolbar from '@material-ui/core/Toolbar';

import LinkToList from './LinkToList';
// import CodeType from '../codeType';
// import EmissionType from '../emissionType';
// import MarkingType from '../markingType';
// import SerialNumType from '../serialNumType';
import resourceLocales from './locales';
import resourceConfig from './config';
import directionType from '../directionType';
import docStatus from '../docStatus';
import atkForBroker from '../atkForBroker';
import docStatusSystem from '../docStatusSystem';
import mrkStatusSystem from '../mrkStatusSystem';
import DateInput from '../../../../components/DateInput';
import EnumInput from '../../../../components/EnumInput';
import documentRowStyle from '../../../../utils/ui/documentRowStyle';
import exporter from '../../../../utils/exporter';
import docLinkType from '../docLinkType';
import markingStatus from '../markingStatus';
import EnumField from '../../../../components/EnumField';
import DissagrButton from './DissagrButton';
import SendAtkButton from './SendAtkButton';
import errorModel from '../errorModel';
import objectSendModel from '../objectSendModel';
import docUpdate from '../docUpdate';
import atkKM from '../atkKM';
import PostPagination from '../../../../utils/pagination';
import ExportDocButton from '../docH/ExportDocButton';

const ResourceIcon = BookmarkIcon;

const trPrefix = `resources.${resourceConfig.name}`;

const listStyles = {};

const ResourceBulkActionButtons = (props) => (
  <Fragment>
    {/* <ArkForBrokerButton
            {...props}
        /> */}
    <docUpdate.Button {...props} bulk docType={'ATK'} />
    <DissagrButton {...props} />
    <BulkDeleteButton {...props} />
  </Fragment>
);

const ATKKmListButton = (props) => {
  return (
    <atkKM.LinkToList
      label={`resources.${resourceConfig.name}.atkKM`}
      filter={{
        overEntityIds: props.record.id,
      }}
    />
  );
};
const ArkForBrokerButton = (props) => {
  let params = {};
  if (props.filter && Object.keys(props.filter).length > 0) {
    params = {
      ...params,
      ...props.filter,
    };
  }
  if (props.selectedIds && props.selectedIds.length > 0) {
    params.ids = props.selectedIds;
  }
  return <atkForBroker.OpenButton params={params} />;
};

const ResourceListFilter = (props) => (
  <Filter {...props}>
    <TextInput source={'actionId'} />
    <TextInput source={'atk'} />
    <TextInput source={'checkStatus'} />
    <TextInput source={'country'} />
    <DateInput source={'dateInvoice'} label={`${trPrefix}.fields.dateInvoice`} />
    <directionType.Input source={'direction'} />
    <docStatus.Input source={'docStatus'} />
    <EnumInput
      source={'docType'}
      translatePrefix={`${trPrefix}.enums.docType.`}
      label={`${trPrefix}.fields.docType`}
      enums={resourceConfig.enums.docType}
    />
    <TextInput source={'docnum'} />
    <TextInput source={'documentId'} />
    <docStatusSystem.Input source={'documentSystemStatus'} />
    <TextInput source={'guid'} />
    <TextInput source={'invoice'} />
    <TextInput source={'invoiceAtk'} />
    <DateInput source={'invoiceAtkDate'} label={`${trPrefix}.fields.invoiceAtkDate`} />
    <TextInput source={'invoiceAtkPos'} />
    <mrkStatusSystem.Input source={'mrkSystemStatus'} />
    <TextInput source={'numGoods'} />
    <DateInput source={'processedDateFrom'} label={`${trPrefix}.fields.processedDateFrom`} />
    <DateInput source={'processedDateTo'} label={`${trPrefix}.fields.processedDateTo`} />
    <TextInput source={'productGroup'} />
    <TextInput source={'receiverId'} />
    <TextInput source={'requestId'} />
    <BooleanInput source={'runningBefore'} />
    <markingStatus.Input source={'status'} />
    <TextInput source={'subjectId'} />
    <TextInput source={'tnved'} />
    <TextInput source={'tradeParticipantInn'} />
    <EnumInput
      source={'transformationType'}
      translatePrefix={`${trPrefix}.enums.transformationType.`}
      label={`${trPrefix}.fields.transformationType`}
      enums={resourceConfig.enums.transformationType}
    />
  </Filter>
);

const ResourceListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  translate,
}) => (
  <Toolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <ArkForBrokerButton filter={filterValues} />
    <CreateButton basePath={basePath} />
    <ExportDocButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
  <List
    {...props}
    filters={<ResourceListFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    actions={<ResourceListActions />}
    bulkActionButtons={<ResourceBulkActionButtons />}
    exporter={exporter}
    pagination={<PostPagination />}>
    <Datagrid rowStyle={documentRowStyle}>
      <TextField source={'id'} />

      <TextField source={'atk'} />
      <EnumField
        source={'transformationType'}
        translatePrefix={`${trPrefix}.enums.transformationType.`}
        label={`${trPrefix}.fields.transformationType`}
        enums={resourceConfig.enums.transformationType}
      />
      <EnumField
        source={'docType'}
        translatePrefix={`${trPrefix}.enums.docType.`}
        label={`${trPrefix}.fields.docType`}
        enums={resourceConfig.enums.docType}
      />
      <TextField source={'guid'} />
      <TextField source={'checkStatus'} />
      <DateField source={'dateInvoice'} label={`${trPrefix}.fields.dateInvoice`} />
      <TextField source={'invoice'} />
      <TextField source={'numGoods'} />
      <mrkStatusSystem.Field source={'mrkSystemStatus'} />
      <markingStatus.Field source={'status'} />
      <TextField source={'tnved'} />
      <TextField source={'invoiceAtk'} />
      <DateField source={'invoiceAtkDate'} label={`${trPrefix}.fields.invoiceAtkDate`} />
      <TextField source={'invoiceAtkPos'} />
      <TextField source={'country'} />

      <errorModel.LinkToRelatedList type={'ATK'} />
      <objectSendModel.LinkToRelatedList type={'ATK'} />
      <ATKKmListButton />
      <SendAtkButton />
      <EditButton />
    </Datagrid>
  </List>
));

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.name}
    &quot;
  </span>
));

const ResourceCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source={'actionId'} />
      <TextInput source={'atk'} />
      <TextInput source={'checkStatus'} />
      <TextInput source={'country'} />
      <DateInput source={'dateInvoice'} label={`${trPrefix}.fields.dateInvoice`} />
      <directionType.Input source={'direction'} />
      <docStatus.Input source={'docStatus'} />
      <EnumInput
        source={'docType'}
        translatePrefix={`${trPrefix}.enums.docType.`}
        label={`${trPrefix}.fields.docType`}
        enums={resourceConfig.enums.docType}
      />
      <TextInput source={'docnum'} />
      <DateInput source={'documentDate'} label={`${trPrefix}.fields.documentDate`} />
      <TextInput source={'documentId'} />
      <docLinkType.Input source={'documentLinkType'} />
      <NumberInput source={'documentLinkId'} />
      <docStatusSystem.Input source={'documentSystemStatus'} />
      <NumberInput source={'errorModelId'} />
      <TextInput source={'errors'} />
      <TextInput source={'guid'} />
      <TextInput source={'invoice'} />
      <TextInput source={'invoiceAtk'} />
      <DateInput source={'invoiceAtkDate'} label={`${trPrefix}.fields.invoiceAtkDate`} />
      <TextInput source={'invoiceAtkPos'} />
      <TextInput source={'ismpDocType'} />
      <mrkStatusSystem.Input source={'mrkSystemStatus'} />
      <TextInput source={'numGoods'} />
      <DateInput source={'processedDate'} label={`${trPrefix}.fields.processedDate`} />
      <TextInput source={'productGroup'} />
      <TextInput source={'receiverId'} />
      <TextInput source={'requestId'} />
      <BooleanInput source={'runningBefore'} />
      <markingStatus.Input source={'status'} />
      <TextInput source={'subjectId'} />
      <TextInput source={'tnved'} />
      <TextInput source={'tnvedCode'} />
      <TextInput source={'tradeParticipantInn'} />
      <EnumInput
        source={'transformationType'}
        translatePrefix={`${trPrefix}.enums.transformationType.`}
        label={`${trPrefix}.fields.transformationType`}
        enums={resourceConfig.enums.transformationType}
      />
      <TextInput source={'itemCode'} />
    </SimpleForm>
  </Create>
);

const ResourceEdit = (props) => (
  <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
    <SimpleForm redirect={false}>
      <TextInput source={'actionId'} />
      <TextInput source={'atk'} />
      <TextInput source={'checkStatus'} />
      <TextInput source={'country'} />
      <DateInput source={'dateInvoice'} label={`${trPrefix}.fields.dateInvoice`} />
      <directionType.Input source={'direction'} />
      <docStatus.Input source={'docStatus'} />
      <EnumInput
        source={'docType'}
        translatePrefix={`${trPrefix}.enums.docType.`}
        label={`${trPrefix}.fields.docType`}
        enums={resourceConfig.enums.docType}
      />
      <TextInput source={'docnum'} />
      <DateInput source={'documentDate'} label={`${trPrefix}.fields.documentDate`} />
      <TextInput source={'documentId'} />
      <docLinkType.Input source={'documentLinkType'} />
      <NumberInput source={'documentLinkId'} />
      <docStatusSystem.Input source={'documentSystemStatus'} />
      <NumberInput source={'errorModelId'} />
      <TextInput source={'errors'} />
      <TextInput source={'guid'} />
      <TextInput source={'invoice'} />
      <TextInput source={'invoiceAtk'} />
      <DateInput source={'invoiceAtkDate'} label={`${trPrefix}.fields.invoiceAtkDate`} />
      <TextInput source={'invoiceAtkPos'} />
      <TextInput source={'ismpDocType'} />
      <mrkStatusSystem.Input source={'mrkSystemStatus'} />
      <TextInput source={'numGoods'} />
      <DateInput source={'processedDate'} label={`${trPrefix}.fields.processedDate`} />
      <TextInput source={'productGroup'} />
      <TextInput source={'receiverId'} />
      <TextInput source={'requestId'} />
      <BooleanInput source={'runningBefore'} />
      <markingStatus.Input source={'status'} />
      <TextInput source={'subjectId'} />
      <TextInput source={'tnved'} />
      <TextInput source={'tnvedCode'} />
      <TextInput source={'tradeParticipantInn'} />
      <EnumInput
        source={'transformationType'}
        translatePrefix={`${trPrefix}.enums.transformationType.`}
        label={`${trPrefix}.fields.transformationType`}
        enums={resourceConfig.enums.transformationType}
      />
      <TextInput source={'itemCode'} />
    </SimpleForm>
  </Edit>
);

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} {...props}>
    <TextField source={'id'} />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={'id'} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={'id'} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Create: ResourceCreate,
  Edit: ResourceEdit,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,
  LinkToList,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    edit: ResourceEdit,
    create: ResourceCreate,
    icon: ResourceIcon,
  },
  menuConfig: {
    name: resourceConfig.name,
    icon: ResourceIcon,
  },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {
      dateInvoice: {
        type: 'dateTime',
      },
      invoiceAtkDate: {
        type: 'dateTime',
      },
      processedDate: {
        type: 'dateTime',
      },
      processedDateFrom: {
        type: 'dateTime',
      },
      processedDateTo: {
        type: 'dateTime',
      },
    },
  },
};
