import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Позиция инвентаризации |||| Позиции инвентаризации',
      enums: {
        result: {
          OK: 'Ok',
          NOTOK: 'Не корректные статусы',
          OVER: 'Излишки',
          SHORTFALL: 'Недостача',
          DIFFERENT_VENDOR_DATA: 'Несоответствие КМ - Артикул',
          CODE_NOT_FOUND: 'Код не найден',
          ERROR: 'Ошибка',
        },
      },
      fields: {
        id: '#',
        inventoryHId: 'Документ',
        gtin: 'GTIN',
        mrk_system_status: 'Системный статус кода',
        num_goods: 'Номер товара',
        result: 'Результат',
        status: 'Статус',
        uit_code: 'Код маркировки',
        warehouse: 'Склад',
        ownerInn: 'Собственник',
        statusFm: 'Статус склада',
      },
    },
  },
};
