import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'UTD',
      updP: 'Positions',
      downloadXML: {
        action: 'Download',
        errorMessage: 'Error',
      },
      enums:{
        updDirection:{
          INBOUND:'INBOUND',
          OUTBOUND:'OUTBOUND'
        }
      },
      fields: {
        dateSupply: 'Date Supply',
        invoiceCreationTime: 'Invoice Creation Time',
        processedDate: 'Processed Date',
        'additional.datetimeInfSeller': 'Datetime Inf Seller',
        'additional.documentDate': 'Document Date',
      },
    },
  },
};
