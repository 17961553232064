import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Remark |||| Remarks',
      remarkP: 'Records',
      sendRemark: {
        action: 'Send',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      fields: {
        processedDate: 'Processed date',
        remarkingDate: 'Remarking date',
      }
    }
  }
}