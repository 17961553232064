import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Импортировать отгрузки',
      title: 'Импортировать отгрузки',
      executeAction: 'Отправить',
      errorMessage: 'Ошибка',
      successMessage: 'Запрос успешно отправлен',
      fields: {},
    },
  },
};
