import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'NK Request',
      send: {
        action: 'Send',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      check: {
        action: 'Check',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      verify: {
        action: 'Verify',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      moderate: {
        action: 'Moderate',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      enums: {
        nkRequestType: {
          CREATE: 'Create',
          EDIT: 'Edit',
        },
        nkStatus: {
          REJECTED: 'Rejected',
          RECEIVED: 'Received',
          MODERATED: 'Moderated',
          SIGNED: 'Signed',
          DRAFT: 'Draft',
          ERRORS: 'Errors',
        },
        goodDetailedStatus: {
          DRAFT: 'Draft',
          MODERATION: 'Moderation',
          ERRORS: 'Errors',
          NOTSIGNED: 'Notsigned',
          PUBLISHED: 'Published',
        },
      },
      fields: {
        id: 'Id',
        brand: 'Brand',
        date: 'Date',
        feedId: 'Feed id',
        goodId: 'Good id',
        goodName: 'Good name',
        gtin: 'Gtin',
        isKit: 'Is kit',
        isSet: 'Is set',
        moderation: 'Moderation',
        nkRequestType: 'Request type',
        nkStatus: 'Status',
        tnved: 'Tnved',
        categories: 'Categories',
        attr_id: 'Attr id',
        attr_value: 'Attr value',
        attr_value_type: 'Attr value type',
        goodAttrs: 'Good attrs',
        cat_id: 'Cat id',
        identifiedBy: 'Identified by',
        level: 'Level',
        multiplier: 'Multiplier',
        partyId: 'Party id',
        type: 'Type',
        unit: 'Unit',
        value: 'Value',
        delete: 'Delete',
        goodDetailedStatus: 'Good detailed status',
        quantity: 'quantity',
        party_id: 'party id',
      },
    },
  },
};
