import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Ввод в оборот',
      inputP: 'Записи',
      sendInput: {
        action: 'Отправить',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      fields: {
        actionId: 'Id действия',
        companyUniqName: 'Компания в системе',
        creatorUsername: 'Имя создателя',
        currency: 'Валюта',
        customsCode: 'Таможенный код',
        decisionCode: 'Код принятого решения',
        declarationDate: 'Дата декларации',
        declarationNumber: 'Номер декларации',
        direction: 'Направление',
        docStatus: 'Статус документа',
        docType: 'Тип документа',
        docnum: 'Номер документа',
        documentId: 'Id документа',
        documentSystemStatus: 'Системный статус дока',
        error: 'Ошибка',
        guid: 'GUID',
        markingType: 'Тип маркировки',
        ownerInn: 'ИНН Владельца',
        participantInn: 'ИНН участника оборота',
        processedDate: 'Дата обработки',
        producerInn: 'ИНН производителя',
        productionOrder: 'Порядок производства',
        productionType: 'Тип производства',
        production_date: 'Дата производства',
        receiverId: 'Id получателя',
        requestId: 'Id запроса',
        status: 'Статус',
        subjectId: 'Id субъекта',
        tradeParticipantInn: 'ИНН участника торговли',
        checkStatus: 'Статус ИСМП',
        documentLinkType: 'Тип ссылки на документ',
        documentLinkId: 'Id ссылки на документ',

        countryOksm: 'Код страны экспортера',
        exporterName: 'Наименование экспортера',
        importDate: 'Дата импорта',
        ismpDocType: 'Тип подумента ИСМП',
        primaryDocumentDate: 'Дата документа, подтверждающего перемещение товара',
        primaryDocumentNumber: 'Номер документа, подтверждающего перемещение товара',
        productReceivingDate: 'Дата получения товара',
        runningBefore: 'Запускался ранее',
        vsdNumber: 'Номер ВСД',
        guids: 'GUID (Список)',
        docnums: 'Номер документа (Список)',
        senderTaxNumber: 'Номер налогоплательщика отправителя',
      },
    },
  },
};
