import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Input journal (subrecords)',
      packM: 'Records',
      inputPChild: 'Children',
      fields: {
        id: 'ID',
        certificate_document_date: 'Сertificate document date',
        productionDate: 'Production date',
      },
    }
  }
}