import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Соответствие артикулов',
      fields: {
        gtin: 'GTIN',
        itemCode: 'Код товара',
        manufacturingItem: 'Производственный код товара',
        irreducibleStock: 'Неснижаемый запас',
        irreducibleStockCount: 'Неснижаемый запас (кол-во)',
      }
    }
  }
}