import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        RETAIL: 'RETAIL',
        REMOTE_SALE: 'REMOTE_SALE',
        EEC_EXPORT: 'EEC_EXPORT',
        BEYOND_EEC_EXPORT: 'BEYOND_EEC_EXPORT',
        RETURN: 'RETURN',
        DAMAGE_LOSS: 'DAMAGE_LOSS',
        DESTRUCTION: 'DESTRUCTION',
        CONFISCATION: 'CONFISCATION',
        LIQUIDATION: 'LIQUIDATION',
        ENTERPRISE_USE: 'ENTERPRISE_USE',
        DONATION: 'DONATION',
        STATE_ENTERPRISE: 'STATE_ENTERPRISE',
        NO_RETAIL_USE: 'NO_RETAIL_USE',
        OTHER: 'OTHER',
        DISTANCE: 'DISTANCE',
        PRODUCTION_USE: 'PRODUCTION_USE',
        EXPIRATION: 'EXPIRATION',
        RECALL: 'RECALL',
        STATE_SECRET: 'STATE_SECRET',
        VENDING: 'VENDING',
        UTILIZATION: 'UTILIZATION',
        PACKING: 'PACKING',
        LOSS: 'LOSS',
        BY_SAMPLES: 'BY_SAMPLES',
        EAS_TRADE: 'EAS_TRADE',
        OWN_USE: 'OWN_USE',
        STATE_CONTRACT: 'STATE_CONTRACT',
      },
    },
  },
};
