import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        CONFORMITY_CERTIFICATE: 'CONFORMITY_CERTIFICATE',
        CONFORMITY_DECLARATION: 'CONFORMITY_DECLARATION',
      },
    }
  }
}