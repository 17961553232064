import React from 'react';
import {
    translate,
} from 'react-admin';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import ChipList from '../../../../components/ChipList';

import resourceLocales from './locales';
import resourceConfig from './config';

const stopPropagation = e => e.stopPropagation();

const styles = {
  link: {
      display: 'inline-flex',
      alignItems: 'center',
      padding: 0,
  },
};

const linkTypes = {
  'DOC': { resource: 'marking--docH' },
  'GOODS': { resource: 'marking--goods' },
  'INPUT': { resource: 'marking--inputH' },
  'ORDER': { resource: 'marking--orderH' },
  'STRDOC': { resource: 'marking--strDoc' },
  'OUT': { resource: 'marking--outH' },
  'REMARK': { resource: 'marking--remarkH' },
  'TREEMRK': { resource: 'marking--treeMrk' },
}

const ResourceField = compose(
  withStyles(styles),
  translate
)(({ classes, translate, record = {}, typeField = 'documentLinkType', idField = 'documentLinkId', ...otherProps }) => {
  let to = null;
  let component = undefined;
  let label = '-'

  if (record[typeField] !== null && record[idField]) {
    label = `${record[typeField]} #${record[idField]}`
  } else if (record[typeField] !== null) {
    label = record[typeField]
  }
  if (record[typeField] !== null && record[typeField] in linkTypes) {
    component = Link;
    to = {
      pathname: `/${linkTypes[record[typeField]].resource}`,
    };

    if (record[idField] !== null) {
      to.search = stringify({
        filter: JSON.stringify({
          ids: record[idField]
        }),
      })
    }
  }
  
  return (
    <Button
      size="small"
      color="primary"
      {...otherProps}
      component={component}
      onClick={stopPropagation}
      to={to}
      className={classes.link}
    >
      {label}
    </Button>
  );
});
ResourceField.defaultProps = {
  addLabel: true,
  label: `resources.${resourceConfig.name}.label`
};

const ResourceArrayField = compose(translate)(({ translate, source, record = {}, ...otherProps }) => (
  <ChipList
    items={record[source] && record[source].map(x => (translate(`resources.${resourceConfig.name}.enums.${x}`)))}
  />
));
ResourceArrayField.defaultProps = {
  addLabel: true
};



export default {
    Field: ResourceField,
    ArrayField: ResourceArrayField,
    
    name: resourceConfig.name,
    locales:  resourceLocales,
}
