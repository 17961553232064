import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Order P |||| Order P records',
      orderM: 'M records',
      ordersPool: 'Pools',
      fields: {
        id: 'ID',
        dateInvoice: 'Date invoice',
        certType:'Cert type',
        certDate:'Cert date'
      },
      enums: {
        certType: {
          CONFORMITY_CERTIFICATE: 'Conformity Certificate',
          CONFORMITY_DECLARATION: 'Conformity Declaration',
        },
      },
    }
  }
}