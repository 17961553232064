import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        DROPOUT: 'Отчет о выбытии',
        UTILISATION: 'Отчет о нанесении',
      },
    }
  }
}
