import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'ERP |||| ERP',
      erpGoods: 'Товары',
      erpKM: 'Коды',
      fields: {
        id: '#',
        acceptance_date: 'дата приемки товара(для приемки)',
        action: 'Действие',
        actionId: 'Действие ID',
        action_date: 'Дата вывода из оборота',
        certificate_date: 'Дата документа, подтверждающего соответствие',
        certificate_number: 'Номер документа, подтверждающего соответствие',
        certificate_type: 'Вид документа обязательной сертификации',
        checkStatus: 'Статус для перемещения',
        direction: 'Направление',
        docDateErp: 'Дата документа ERP',
        docNumErp: 'Номер документа ERP',
        docStatus: 'Статус документа',
        docTypeErp: 'Тип документа в ERP',
        doc_date_idocs: 'Дата создания документа Idocs',
        doc_num_idocs: 'Номер последующего документа Idocs',
        doc_type_idocs: 'Тип документа в idocs',
        docnum: 'Номер документа',
        documentId: 'ID документа',
        documentLinkId: 'ID ссылочного документа',
        documentLinkType: 'Тип ссылочного документа',
        documentSystemStatus: 'Системный статус документа',
        document_date: 'Дата документа',
        document_number: 'Номер документа',
        errorModelId: 'ID ошибки',
        errors: 'Ошибка при создании последующего документа',
        flag_create: 'Флаг создания',
        guid: 'GUID',
        ismpDocType: 'Тип документа ГИСМТ',
        out_doc_type: 'Вид документа',
        owner: 'наименование получателя',
        owner_inn: 'ИНН получателя',
        paid: 'Товар оплачен',
        primary_document_custom_name: 'Наименование первичного документа',
        primary_document_date: 'Дата первичного документа',
        primary_document_number: 'Номер первичного документа',
        primary_document_type: 'Тип первичного документа',
        processedDate: 'Дата обработки',
        receiver: 'наименование получателя',
        receiverId: 'ID получателя',
        receiver_inn: 'ИНН получателя',
        release_order_number: 'номер заявки на отгрузку(для приемки)',
        requestId: 'ID запроса',
        request_type: 'Тип документа',
        return_type: 'Вид возврата',
        sender: 'наименование отправителя',
        senderInn: 'ИНН отправителя (для отгрузки)',
        subjectId: 'ID темы',
        tradeRecipientInn: 'ИНН получателя',
        tradeSender_name: 'наименование организации отправителя(для приемки)',
        trade_owner_inn: 'ИНН получателя(для приемки)',
        trade_owner_name: 'наименование организации получателя(для приемки)',
        trade_participant_inn: 'ИНН участника оборота',
        trade_sender_inn: 'ИНН отправителя (для приемки)',
        transfer_date: 'дата передачи маркированных товаров',
        turnover_type: 'Тип отгрузки',
        warehouseFrom: 'Склад отправитель',
        warehouseTo: 'Склад получатель',

        needSSCC: 'Нужен код товарной упаковки',

        needVirtualSSCC: 'Генерировать виртуальный SSCC',
        virtualSSCC: 'Виртуальный SSCC',

        torg2: 'ТОРГ 2',
        virtual: 'Виртуальный',
      },
    }
  }
}