import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Товар |||| Товары',
      createOrder: {
        action: 'Эмиссия  КМ',
        successMessage: 'Документ создан',
        errorMessage: 'Ошибка',
      },
      load: {
        action: 'Загрузка',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      fields: {
        processedDate: 'Дата обработки',
        actionId: 'Id действия',
        brand: 'Бренд',
        companyUniqName: 'Компания в системе',
        creatorUsername: 'Имя создателя',
        direction: 'Направление',
        docStatus: 'Статус документа',
        docnum: 'Номер документа',
        documentId: 'Id документа',
        documentSystemStatus: 'Статус документа',
        gtin: 'GTIN',
        model: 'Модель',
        name: 'Название',
        numgoods: 'Номер товара',
        owner_inn: 'ИНН владельца',
        producer_inn: 'ИНН производителя',
        receiverId: 'ИНН получателя',
        requestId: 'Id запроса',
        subjectId: 'Id субъекта',
        tnVedCode: 'ТНВЭД',
        packageType: 'Тип упаковки',
        innerUnitCount: 'Внутренний номер юнита',
        publicationDate: 'Дата публикации',
        inn: 'ИНН',
        country: 'Страна',
        productTypeDesc: 'Описание типа продукта',
        color: 'Цвет',
        materialDown: 'Материал низа',
        materialUpper: 'Материал верха',
        materialLining: 'Внутренний материал',
        productType: 'Тип продукта',
        productSize: 'Размер продукта',
        ean: 'EAN',
      }
    }
  }
}