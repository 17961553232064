export default {
  enums: [
    { id: 'RECEIPT' },
    { id: 'LOAN_RECEIPT' },
    { id: 'CANCELLATION' },
    { id: 'CANCELLATION_APPLIED' },
    { id: 'STRICT_REPORTING_FORM' },
    { id: 'CONTRACT' },
    { id: 'DESTRUCTION_ACT' },
    { id: 'CONSIGNMENT_NOTE' },
    { id: 'UTD' },
    { id: 'OTHER' },
    { id: 'CORRECTION_RECEIPT' },
    { id: 'SALES_RECEIPT' },
    { id: 'CUSTOMS_DECLARATION' },
  ]
};
