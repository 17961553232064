export default {
  enums: [
    { id: 'RECEIPT' },
    { id: 'CORRECTION_RECEIPT' },
    { id: 'SALES_RECEIPT' },
    { id: 'CONSIGNMENT_NOTE' },
    { id: 'UTD' },
    { id: 'CUSTOMS_DECLARATION' },
    { id: 'DESTRUCTION_ACT' },
    { id: 'OTHER' },
  ]
};
