import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Инвентаризация |||| Инвентаризация',
      inventoryP: 'Позиции',
      send: {
        action: 'Отправить',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      fields: {
        id: '#',
        actionId: 'Id действия',
        checkStatus: 'Статус проверки',
        creatorUsername: 'Имя создателя',
        direction: 'Направление',
        docStatus: 'Статус документа',
        docnum: 'Номер документа',
        documentId: 'Id документа',
        documentLinkType: 'Тип ссылки на документ',
        documentLinkId: 'Id ссылки на документ',
        documentSystemStatus: 'Системный статус документа',
        errors: 'Ошибка',
        guid: 'GUID',
        inventory_date: 'Дата инвентаризации',
        ismpDocType: 'Тип подумента ИСМП',
        numdoc: 'Номер документа',
        processedDate: 'Дата обработки',
        productGroup: 'Товарная группа',
        receiverId: 'Id получателя',
        requestId: 'Id запроса',
        runningBefore: 'Запускался ранее',
        subjectId: 'Id субъекта'
      },
    }
  }
}