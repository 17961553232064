

export default {
  enums: [
    { id: 'DRAFT' },
    { id: 'PENDING' },
    { id: 'READY_TO_SEND' },
    { id: 'REJECTED' },
    { id: 'SENT' },
    { id: 'SUCCESS'},
    { id: 'FAILED'},
    { id: 'PARTIALLY'}
  ]
};