import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Counterparty',
      fields: {
        inn: 'inn',
        name: 'name',
        sscc: 'sscc',
        upd: 'upd',
        memberIdEdm: 'Member ID EDM',
      },
    },
  },
};
