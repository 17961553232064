export default {
  name: 'marking--inventoryP',
  apiService: 'shoes',
  apiPath: '/admin/inventory_p',

  enums: {
    result: [
      'OK',
      'NOTOK',
      'OVER',
      'SHORTFALL',
      'CODE_NOT_FOUND',
      'DIFFERENT_VENDOR_DATA',
      'ERROR',
    ],
  },
};
