export default {
  enums: [
    { id: 'UPDATE_ORDER_STATUS', },
    { id: 'PRINT_KM', },
    { id: 'SEND_INPUT', },
    { id: 'LOAD_KM_LIST', },
    { id: 'UPDATE_KM', },
    { id: 'SEND_DOC', },
    { id: 'LOAD_DOC', },
    { id: 'SEND_OUT', },
    { id: 'SEND_REMARK', },
    { id: 'SEND_AGGR', },
    { id: 'LOAD_LABEL', },
    { id: 'UPDATE_GOOD', },
    { id: 'LOAD_GOODS', },
    { id: 'SEND_DESC_REST', },
    { id: 'SEND_RETURN', },
    { id: 'CHECK_DOCUMENT', },
  ]
};
