import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        IN_PROGRESS: 'IN_PROGRESS',
        CHECKED_OK: 'CHECKED_OK',
        CHECKED_NOT_OK: 'CHECKED_NOT_OK',
        PROCESSING_ERROR: 'PROCESSING_ERROR',
        CANCELLED: 'CANCELLED',
        ACCEPTED: 'ACCEPTED',
        WAIT_ACCEPTANCE: 'WAIT_ACCEPTANCE',
        WAIT_PARTICIPANT_REGISTRATION: 'WAIT_PARTICIPANT_REGISTRATION',
        UNDEFINED: 'UNDEFINED',
      },
    }
  }
}