import React from 'react';
import {
  translate,
  Datagrid,
  Edit,
  Create,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  EditButton,
  Filter,
  NumberInput,
  BooleanInput,
  CreateButton,
  ExportButton,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';

import resourceLocales from './locales';
import resourceConfig from './config';
import documentRowStyle from '../../../../utils/ui/documentRowStyle';
import DateInput from '../../../../components/DateInput';
import exporter from '../../../../utils/exporter';
import SendButton from './SendButton';
import EnumInput from '../../../../components/EnumInput';
import EnumField from '../../../../components/EnumField';
import CheckButton from './CheckButton';
import ModerateButton from './ModerateButton';
import objectSendModel from '../objectSendModel';
import errorModel from '../errorModel';
import VerifyButton from './VerifyButton';
import SignButton from './SignButton';
import PostPagination from '../../../../utils/pagination';
import nkRequestCustomLoadEdit from '../nkRequestCustomLoadEdit';
import ExportDocButton from '../docH/ExportDocButton';
const ResourceIcon = BookmarkIcon;

const resourceToString = (x) => `#${x.id}`;

const trPrefix = `resources.${resourceConfig.name}`;

const listStyles = {};

const ResourceActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  translate,
}) => (
  <Toolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} to={`${basePath}/create`} />
    <nkRequestCustomLoadEdit.OpenButton />

    <ExportDocButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

const ResourceListFilter = (props) => (
  <Filter {...props}>
    <TextInput source={'brand'} />
    <DateInput source={'date'} label={`resources.${resourceConfig.name}.fields.date`} />
    <TextInput source={'feedId'} />
    <TextInput source={'goodId'} />
    <TextInput source={'goodName'} />
    <TextInput source={'gtin'} />
    <BooleanInput source={'kit'} />
    <BooleanInput source={'set'} />
    <NumberInput source={'moderation'} />
    <EnumInput
      source={'nkRequestType'}
      translatePrefix={`${trPrefix}.enums.nkRequestType.`}
      label={`${trPrefix}.fields.nkRequestType`}
      enums={resourceConfig.enums.nkRequestType}
    />
    <EnumInput
      source={'nkStatus'}
      translatePrefix={`${trPrefix}.enums.nkStatus.`}
      label={`${trPrefix}.fields.nkStatus`}
      enums={resourceConfig.enums.nkStatus}
    />
    <TextInput source={'tnved'} />
  </Filter>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
  <List
    {...props}
    filters={<ResourceListFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    actions={<ResourceActions />}
    exporter={exporter}
    pagination={<PostPagination />}>
    <Datagrid rowStyle={documentRowStyle}>
      <TextField source={'id'} />

      <TextField source={'feedId'} />
      <TextField source={'goodId'} />
      <TextField source={'goodName'} />
      <TextField source={'gtin'} />
      <EnumField
        source={'nkRequestType'}
        translatePrefix={`${trPrefix}.enums.nkRequestType.`}
        label={`${trPrefix}.fields.nkRequestType`}
        enums={resourceConfig.enums.nkRequestType}
      />
      <EnumField
        source={'nkStatus'}
        translatePrefix={`${trPrefix}.enums.nkStatus.`}
        label={`${trPrefix}.fields.nkStatus`}
        enums={resourceConfig.enums.nkStatus}
      />
      <TextField source={'goodStatus'} />
      <EnumField
        source={'goodDetailedStatus'}
        translatePrefix={`${trPrefix}.enums.goodDetailedStatus.`}
        label={`${trPrefix}.fields.goodDetailedStatus`}
        enums={resourceConfig.enums.goodDetailedStatus}
      />

      <errorModel.LinkToRelatedList type={'NK_REQUEST'} />
      <objectSendModel.LinkToRelatedList type={'NK_REQUEST'} />
      <SendButton />
      <VerifyButton />
      <CheckButton />
      <ModerateButton />
      <SignButton />
      <EditButton />
    </Datagrid>
  </List>
));

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.id}&quot;
  </span>
));

const ResourceCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source={'brand'} />
      <DateInput source={'date'} label={`resources.${resourceConfig.name}.fields.date`} />
      <TextInput source={'feedId'} />
      <TextInput source={'goodId'} />
      <TextInput source={'goodName'} />
      <TextInput source={'gtin'} />
      <BooleanInput source={'kit'} />
      <BooleanInput source={'set'} />
      <NumberInput source={'moderation'} />
      <EnumInput
        source={'nkRequestType'}
        translatePrefix={`${trPrefix}.enums.nkRequestType.`}
        label={`${trPrefix}.fields.nkRequestType`}
        enums={resourceConfig.enums.nkRequestType}
      />
      <EnumInput
        source={'nkStatus'}
        translatePrefix={`${trPrefix}.enums.nkStatus.`}
        label={`${trPrefix}.fields.nkStatus`}
        enums={resourceConfig.enums.nkStatus}
      />
      <TextInput source={'goodStatus'} />
      <EnumInput
        source={'goodDetailedStatus'}
        translatePrefix={`${trPrefix}.enums.goodDetailedStatus.`}
        label={`${trPrefix}.fields.goodDetailedStatus`}
        enums={resourceConfig.enums.goodDetailedStatus}
      />
      <TextInput source={'tnved'} />
      <ArrayInput source={'goodAttrs'}>
        <SimpleFormIterator>
          {/* <NumberInput source={'id'} />
                    <NumberInput source={'delete'} />
                    <TextInput source={'gtin'} /> */}
          <TextInput source={'attr_id'} />
          <TextInput source={'attr_value'} />
          <TextInput source={'attr_value_type'} />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source={'categories'}>
        <SimpleFormIterator>
          {/* <NumberInput source={'id'} /> */}
          <NumberInput source={'cat_id'} />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source={'identifiedBy'}>
        <SimpleFormIterator>
          {/* <NumberInput source={'id'} /> */}
          <TextInput source={'level'} />
          <NumberInput source={'multiplier'} />
          <NumberInput source={'party_id'} />
          <TextInput source={'type'} />
          <TextInput source={'unit'} />
          <TextInput source={'value'} />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source={'setGtins'}>
        <SimpleFormIterator>
          <NumberInput source={'quantity'} />
          <TextInput source={'gtin'} />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);
const ResourceEdit = (props) => (
  <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
    <SimpleForm redirect={false}>
      <TextField source={'id'} />
      <TextInput source={'brand'} />
      <DateInput source={'date'} label={`resources.${resourceConfig.name}.fields.date`} />
      <TextInput source={'feedId'} />
      <TextInput source={'goodId'} />
      <TextInput source={'goodName'} />
      <TextInput source={'gtin'} />
      <BooleanInput source={'kit'} />
      <BooleanInput source={'set'} />
      <NumberInput source={'moderation'} />
      <EnumInput
        source={'nkRequestType'}
        translatePrefix={`${trPrefix}.enums.nkRequestType.`}
        label={`${trPrefix}.fields.nkRequestType`}
        enums={resourceConfig.enums.nkRequestType}
      />
      <EnumInput
        source={'nkStatus'}
        translatePrefix={`${trPrefix}.enums.nkStatus.`}
        label={`${trPrefix}.fields.nkStatus`}
        enums={resourceConfig.enums.nkStatus}
      />
      <TextInput source={'goodStatus'} />
      <EnumInput
        source={'goodDetailedStatus'}
        translatePrefix={`${trPrefix}.enums.goodDetailedStatus.`}
        label={`${trPrefix}.fields.goodDetailedStatus`}
        enums={resourceConfig.enums.goodDetailedStatus}
      />
      <TextInput source={'tnved'} />
      <ArrayInput source={'goodAttrs'}>
        <SimpleFormIterator>
          {/* <NumberInput source={'id'} />
                    <NumberInput source={'delete'} />
                    <TextInput source={'gtin'} /> */}
          <TextInput source={'attr_id'} />
          <TextInput source={'attr_value'} />
          <TextInput source={'attr_value_type'} />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source={'categories'}>
        <SimpleFormIterator>
          {/* <NumberInput source={'id'} /> */}
          <NumberInput source={'cat_id'} />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source={'identifiedBy'}>
        <SimpleFormIterator>
          {/* <NumberInput source={'id'} /> */}
          <TextInput source={'level'} />
          <NumberInput source={'multiplier'} />
          <NumberInput source={'party_id'} />
          <TextInput source={'type'} />
          <TextInput source={'unit'} />
          <TextInput source={'value'} />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source={'setGtins'}>
        <SimpleFormIterator>
          <NumberInput source={'quantity'} />
          <TextInput source={'gtin'} />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

const ResourceStringField = ({ record }) => <span>{resourceToString(record)}</span>;

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} {...props}>
    <ResourceStringField />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={resourceToString} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={resourceToString} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Create: ResourceCreate,
  Edit: ResourceEdit,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    edit: ResourceEdit,
    create: ResourceCreate,
    icon: ResourceIcon,
  },
  menuConfig: {
    name: resourceConfig.name,
    icon: ResourceIcon,
  },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {
      date: {
        type: 'dateTime',
      }
    },
  },
};
