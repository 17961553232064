import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        // 'CANCELLATION_APPLIED': 'Cancellation applied',
        // 'REMARK_RETIRED': 'Remark retired',
        // 'RETIRED_CANCELLATION': 'Retired cancellation',
        // 'DONATION': 'Donation',
        // 'STATE_ENTERPRISE': 'State enterprise',
        // 'NO_RETAIL_USE': 'No retail use',
        // 'BEYOND_EEC_EXPORT': 'Beyond eec export',
        // 'СREMOTE_SALE': 'Сremote sale',
        // 'EEC_EXPORT': 'Eec export',
        // 'СRETURN': 'Сreturn',
        // 'DAMAGE_LOSS': 'Damage loss',
        // 'DESTRUCTION': 'Destruction',
        // 'CONFISCATION': 'Confiscation',
        'EMPTY': 'Empty',
        'RESERVED_NOT_USED': 'Reserved not used',
        'WAIT_SHIPMENT': 'Wait shipment',
        'EXPORTED': 'Exported',
        'LOAN_RETIRED': 'Loan retired',
        'REMARK_RETIRED': 'Remark retired',
        'WAIT_TRANSFER_TO_OWNER': 'Wait transfer to owner',
        'WAIT_REMARK': 'Wait remark',
        'RETIRED_CANCELLATION': 'Retired cancellation',
        'RETIRED_DONATION': 'Retired domination',
        'RETIRED_STATE_ENTERPRISE': 'Retired state enterprise',
        'RETIRED_NO_RETAIL_USE': 'Retired no retail use',
        'RETIRED_BEYOND_EEC_EXPORT': 'Retired beyond eec export',
        'RETIRED_REMOTE_SALE': 'Retired remote sale',
        'RETIRED_EEC_EXPORT': 'Retired eec export',
        'RETIRED_RETURN': 'Retired return',
        'RETIRED_DAMAGE_LOSS': 'Retired demage loss',
        'RETIRED_DESTRUCTION': 'Retired destruction',
        'RETIRED_CONFISCATION': 'Retired confiscation',
      },
    }
  }
}





