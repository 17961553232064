import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Doc M',
      enums: {
        certType: {
          CONFORMITY_CERTIFICATE: 'Conformity Certificate',
          CONFORMITY_DECLARATION: 'Conformity Declaration',
        },
      },
      fields: {
        certType: 'Certificate type',
        certDate: 'Certificate date',
      },
    },
  },
};
