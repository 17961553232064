import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        RETAIL_RETURN: 'RETAIL_RETURN',
        REMOTE_SALE_RETURN: 'REMOTE_SALE_RETURN',
        RECEIPT_RETURN: 'RECEIPT_RETURN',
      },
    }
  }
}