import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate, Button, showNotification, refreshView as refreshViewAction } from 'react-admin';
import compose from 'recompose/compose';

import request from '../../../../network/request';
import resourceConfig from './config';

class DocUpdateButton extends Component {
    state = {
      executing: false
    }
    requestOne = async(docId) => {
      const {
        docType,
      } = this.props;

      let data = {
        docId,
        documentType: docType,
      }

      let response = null;
      try {
        response = await request({
          apiService: 'shoes',
          path: '/admin/document/check',
          urlParams: data,
          method: 'POST',
        })
      } catch (e) {
        console.error(e);
      }

      if (response === null || !response.ok) {
        return false;
      }

      return true;
    } 
    requestMany = async(ids = []) => {
      const {
        translate,
        showNotification,
      } = this.props;

      let successCount = 0;
      const promises = ids.map(async (id) => {
        const success = await this.requestOne(id);
        if (success) {
          successCount += 1;
        } else {
          showNotification(translate(`resources.${resourceConfig.name}.errorOneMessage`, { id }), 'error');
        }
      });

      await Promise.all(promises);

      if (successCount === ids.length) {
        showNotification(`resources.${resourceConfig.name}.successAllMessage`, 'success');
      } else {
        showNotification(translate(`resources.${resourceConfig.name}.successWithFailedMessage`, { success: successCount, total: ids.length }), 'warn');
      }
    }
    handleClick = async() => {
        const { 
          showNotification,
          refreshView,
          bulk = false,
          record = null,
          selectedIds = [],
        } = this.props;

        this.setState({
          executing: true
        });

        if (bulk) {
          await this.requestMany(selectedIds);
        } else if (record) {
          const success = await this.requestOne(record.id);
          if (success) {
            showNotification(translate(`resources.${resourceConfig.name}.successMessage`), 'success');
          } else {
            showNotification(translate(`resources.${resourceConfig.name}.errorOneMessage`, { id: record.id }), 'error');
          }
        }
        
        this.setState({
          executing: false
        }, refreshView);
    };
    

    render() {
        return (
            <Button label={`resources.${resourceConfig.name}.action`} disabled={this.state.executing} onClick={this.handleClick} />
        );
    }
}

export default compose(
  translate,
  connect(undefined, { 
    showNotification, 
    refreshView: refreshViewAction 
  })
)(DocUpdateButton);
