import React, { Fragment } from 'react';
import {
    translate,
    Datagrid,
    Edit,
    Create,
    EditButton,
    List,
    SimpleForm,
    TextField,
    TextInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    ReferenceArrayInput, 
    SelectArrayInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    Filter,
    // BulkDeleteButton,
    required,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import ResourceIcon from '@material-ui/icons/People';

import LinkToList from './LinkToList';
import resourceLocales from './locales';
import resourceConfig from './config';
import documentRowStyle from '../../../../utils/ui/documentRowStyle';
import authority from '../authority';
import exporter from '../../../../utils/exporter';

const listStyles = {
};

const ResourceBulkActionButtons = props => (
    <Fragment>
        {/* <BulkDeleteButton {...props} /> */}
    </Fragment>
);


const ResourceListFilter = (props) => (
    <Filter {...props}>
    </Filter>
);


const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
    <List
        {...props}
        filters={<ResourceListFilter />}
        bulkActionButtons={<ResourceBulkActionButtons />}
        exporter={exporter}
    >
        <Datagrid rowStyle={documentRowStyle}>
            <TextField source={'username'} />
            <authority.ArrayField source={'authorities'} />
            <EditButton />
        </Datagrid>
    </List>
));

const ResourceTitle = translate(({ record, translate }) => (
    <span>
        {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{
            record.username
        }&quot;
    </span>
));


const validateRequired = required();
const ResourceCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source={'username'} validate={validateRequired}/>
            <TextInput source={'password'} validate={validateRequired}/>
            <authority.ArrayInput source={'authorities'} />
        </SimpleForm>
    </Create>
);

const ResourceEdit = props => (
    <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
        <SimpleForm redirect={false}>
            <TextField source={'username'} />
            <authority.ArrayInput source={'authorities'} />
        </SimpleForm>
    </Edit>
);

const ResourceReferenceField = (props) => (
    <ReferenceField
        reference={resourceConfig.name}
        {...props}
    >
        <TextField source={'id'} />
    </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
    <ReferenceArrayField
        reference={resourceConfig.name}
        {...props}
    >
        <SingleFieldList>
            <ChipField source={'id'} />
        </SingleFieldList>
    </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
    <ReferenceInput 
      reference={resourceConfig.name} 
      {...props}
    >
      <SelectInput optionText={'id'} />
    </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
    <ReferenceArrayInput 
      reference={resourceConfig.name} 
      {...props}
    >
      <SelectArrayInput optionText={'id'} />
    </ReferenceArrayInput>
);



export default {
    Icon: ResourceIcon,
    List: ResourceList,
    Title: ResourceTitle,
    Create: ResourceCreate,
    Edit: ResourceEdit,
    Field: ResourceReferenceField,
    ArrayField: ResourceReferenceArrayField,
    Input: ResourceReferenceInput,
    ArrayInput: ResourceReferenceArrayInput,
    LinkToList,
    
    name: resourceConfig.name,
    resourceConfig: {
        name: resourceConfig.name,
        list: ResourceList,
        edit: ResourceEdit,
        create: ResourceCreate,
        icon: ResourceIcon,
    },
    menuConfig: {
        name: resourceConfig.name, 
        icon: ResourceIcon,
    },
    locales:  resourceLocales,
    restProviderResolve: {
        name: resourceConfig.name,
        apiService: resourceConfig.apiService,
        basePath: resourceConfig.apiPath,
        idField: 'username',
        schema: {
        },
        filters: {
        },
        mapping: {
            inputItem: (item) => {
                if (Array.isArray(item.authorities)) {
                    item.authorities = item.authorities.map(x => x.name);
                }
                return item;
            },
        }
    }
}
