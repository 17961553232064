import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Позиции перемаркировки',
      fields: {
        certificateDocumentDate: 'Дата сертификата',
        remarkingDate: 'Дата перемаркировки',
        certificateDocument: 'Тип сертификата',
        certificateDocumentNumber: 'Номер сертификата',
        lastUin: 'Предыдущий UIN',
        markingType: 'Тип маркировки',
        newUin: 'Новый UIN',
        remarkHId: 'Документ',
        remarkingCause: 'Причина перемаркировки',

        primaryDocumentType: 'Вид первичного документа ',
        primaryDocumentCustomName: 'Наименование первичного документа',
        primaryDocumentDate: 'Дата первичного документа',
        primaryDocumentNumber: 'Номер первичного документа',
        tnved10: 'ТНВЭД',
        paid: 'Товар оплачен',
        production_country: 'Страна производства',
        color: 'Цвет',
        product_size: 'Размер',
      },
    }
  }
}