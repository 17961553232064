import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        DOC: 'DOC',
        OUT: 'OUT',
        INPUT: 'INPUT',
        AGGR: 'AGGR',
        ORDER: 'ORDER',
        REMARK: 'REMARK',
        UPD: 'UPD',
        REPORT: 'REPORT'
      },
    },
  },
};
