import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import SettingsIcon from '@material-ui/icons/Settings';
import DescriptionIcon from '@material-ui/icons/Description';

import {
    translate,
    DashboardMenuItem,
    MenuItemLink,
    Responsive,
} from 'react-admin';
import { withRouter } from 'react-router-dom';
import appCore from '../../appCore';
import SubMenu from './SubMenu';
import { Divider } from '@material-ui/core';
import ProductGroupSelect from '../ProductGroupSelect';


const services = appCore.services;
const items = appCore.menuItems;

const Menu = ({ onMenuClick, translate, sidebarOpen, dense, logout }) => {
    const [state, setState] = useState({
    });

    const handleToggle = menu => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    const renderServiceItems = (service) => (
        items.filter(x => x.serviceName === service.name).map(item =>
            item.isCustom ? (
                <MenuItemLink
                    key={item.name}
                    to={item.path}
                    primaryText={translate(item.name)}
                    onClick={onMenuClick}
                    leftIcon={item.icon && <item.icon />}
                />
            ) : (
                    <MenuItemLink
                        key={item.name}
                        to={`/${item.name}`}
                        primaryText={translate(`resources.${item.name}.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                        leftIcon={item.icon && <item.icon />}
                    />
                )
        )
    )

    const markingService = services.find(x => x.name === 'marking') || null;
    return (
        <div className="left-menu-container" style={{ width: sidebarOpen ? '245px' : '55px' }}>
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={sidebarOpen} />
            {services.filter(x => x.name !== 'marking').map(service => (
                <SubMenu
                    key={service.name}
                    handleToggle={() => handleToggle(service.name)}
                    isOpen={state[service.name]}
                    name={translate(`services.${service.name}.name`)}
                    icon={service.icon && <service.icon />}
                    dense={dense}
                    sidebarIsOpen={sidebarOpen}
                >
                    {renderServiceItems(service)}
                    {service.apiDocUrl && (
                        <MenuItemLink
                            to={service.apiDocUrl}
                            primaryText={translate('menu.serviceApiDoc')}
                            onClick={onMenuClick}
                            leftIcon={<DescriptionIcon />}
                        />
                    )}
                </SubMenu>
            ))}

            {markingService !== null && (
                <Fragment>
                    <Divider style={{ marginTop: 15 }} />

                    <ProductGroupSelect />

                    <Divider style={{ marginBottom: 10 }} />

                    {renderServiceItems(markingService)}
                    {markingService.apiDocUrl && (
                        <MenuItemLink
                            to={markingService.apiDocUrl}
                            primaryText={translate('menu.serviceApiDoc')}
                            onClick={onMenuClick}
                            leftIcon={<DescriptionIcon />}
                        />
                    )}
                </Fragment>
            )}

            <Responsive
                xsmall={
                    <MenuItemLink
                        to="/configuration"
                        primaryText={translate('pos.configuration')}
                        leftIcon={<SettingsIcon />}
                        onClick={onMenuClick}
                    />
                }
                medium={null}
            />
            <Responsive xsmall={logout} medium={null} />
        </div>
    );
};

Menu.propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object,
};


const enhance = compose(
    withRouter,
    connect(
        state => ({
            theme: state.theme,
            // locale: state.i18n.locale,
            sidebarOpen: state.admin.ui.sidebarOpen,
        }),
        {}
    ),
    translate
);

export default enhance(Menu);
