import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'PD Information |||| PD Information',
      pdInformationP: 'Records',
      sendPdInformation: {
        action: 'Send',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      fields: {
        actionDate: 'Action date',
        cancellationDocDate: 'CancellationDoc date',
        documentDate: 'Document date',
        orderDate: 'Order date',
        processedDate: 'Processed date',
      },
    },
  },
};
