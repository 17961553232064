import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'ERP KM',
      fields: {
        docErpHId: 'docErpHId',
        algorithm: 'algorithm',
        ki: 'ki',
        num_goods: 'num_goods',
        uit_code: 'uit_code',
        uitu_code: 'uitu_code',
      },
    }
  }
}