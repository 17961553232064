import React from 'react';
import {
    translate,
    Datagrid,
    Edit,
    Create,
    // EditButton,
    List,
    SimpleForm,
    TextField,
    TextInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    ReferenceArrayInput, 
    SelectArrayInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    Filter,
    EditButton,
    CreateButton,
    ExportButton,
} from 'react-admin';
import qs from 'qs';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Toolbar from '@material-ui/core/Toolbar';

import InputP from '../inputP';
import resourceLocales from './locales';
import resourceConfig from './config';

import LinkToList from './LinkToList';
import exporter from '../../../../utils/exporter';
import ExportDocButton from '../docH/ExportDocButton';

const ResourceIcon = BookmarkIcon;

const resourceToString = x => `#${x.id} (${x.docnum})`;

const listStyles = {
};

const ResourceActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    translate
}) => (
    <Toolbar>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <CreateButton
            basePath={basePath}
            to={`${basePath}/create?${filterValues && filterValues.overEntityIds ? `_inputPId=${filterValues.overEntityIds}` : ''}`}
        />
        <ExportDocButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
    </Toolbar>
);

const ResourceListFilter = (props) => (
    <Filter {...props}>
        <InputP.Input source={'overEntityIds'} label="overEntityIds"/>
    </Filter>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
    <List
        {...props}
        filters={<ResourceListFilter />}
        actions={<ResourceActions />}
        exporter={exporter}
    >
        <Datagrid>
            <TextField source={'id'} />
            <TextField source={'cis'} />
            <TextField source={'color'} />
            <TextField source={'packType'} />
            <TextField source={'productSize'} />
            <InputP.Field source={'inputPId'} />
            <EditButton />
        </Datagrid>
    </List>
));

const ResourceTitle = translate(({ record, translate }) => (
    <span>
        {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{
            record.name
        }&quot;
    </span>
));


const ResourceCreate = props => {
    const defaultData = {};
    if (props.location && props.location.search && props.location.search.length > 1) {
        const params = qs.parse(props.location.search.replace(/^\?/, ''));
        if (params && params._inputPId) {
            defaultData.inputPId = parseInt(params._inputPId);
        }
    }
    return (
        <Create {...props}>
            <SimpleForm>
                <InputP.Input source={'inputPId'} defaultValue={defaultData.inputPId} />

                <TextInput source={'cis'} />
                <TextInput source={'color'} />
                <TextInput source={'packType'} />
                <TextInput source={'productSize'} />
            </SimpleForm>
        </Create>
    );
}

const ResourceEdit = props => (
    <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
        <SimpleForm redirect={false}>
            <InputP.Input source={'inputPId'} />

            <TextInput source={'cis'} />
            <TextInput source={'color'} />
            <TextInput source={'packType'} />
            <TextInput source={'productSize'} />
        </SimpleForm>
    </Edit>
);


const ResourceStringField = ({ record }) => (
    <span>{resourceToString(record)}</span>
)

// const ResourceStringChipField = ({ record }) => (
//     <span>{resourceToString(record)}</span>
// )

const ResourceReferenceField = (props) => (
    <ReferenceField
        reference={resourceConfig.name}
        {...props}
    >
        <ResourceStringField />
    </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
    <ReferenceArrayField
        reference={resourceConfig.name}
        {...props}
    >
        <SingleFieldList>
            <ChipField source={'id'} />
        </SingleFieldList>
    </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
    <ReferenceInput 
      reference={resourceConfig.name} 
      {...props}
    >
      <SelectInput optionText={resourceToString} />
    </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
    <ReferenceArrayInput 
      reference={resourceConfig.name} 
      {...props}
    >
      <SelectArrayInput optionText={resourceToString}  />
    </ReferenceArrayInput>
);


export default {
    Icon: ResourceIcon,
    List: ResourceList,
    Title: ResourceTitle,
    Create: ResourceCreate,
    Edit: ResourceEdit,
    Field: ResourceReferenceField,
    ArrayField: ResourceReferenceArrayField,
    Input: ResourceReferenceInput,
    ArrayInput: ResourceReferenceArrayInput,
    LinkToList,

    name: resourceConfig.name,
    resourceConfig: {
        name: resourceConfig.name,
        list: ResourceList,
        edit: ResourceEdit,
        create: ResourceCreate,
        icon: ResourceIcon,
    },
    // menuConfig: {
    //     name: resourceConfig.name, 
    //     icon: ResourceIcon,
    // },
    locales:  resourceLocales,
    restProviderResolve: {
        name: resourceConfig.name,
        apiService: resourceConfig.apiService,
        basePath: resourceConfig.apiPath,
        schema: {
        }
    }
}
