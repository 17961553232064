import React, { Fragment } from 'react';
import {
  translate,
  Datagrid,
  Edit,
  Create,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  EditButton,
  Filter,
  NumberInput,
  CreateButton,
  BulkDeleteButton,
  BooleanInput,
  DateField,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Toolbar from '@material-ui/core/Toolbar';
import DateInput from '../../../../components/DateInput';

import DeliveryType from '../deliveryType';
import docStatus from '../docStatus';
import directionType from '../directionType';
import OrderStatus from '../orderStatus';
import ProductionType from '../productionType';
import serialNumType from '../serialNumType';
import releaseMethodType from '../releaseMethodType';
import productionType from '../productionType';
import resourceLocales from './locales';
import resourceConfig from './config';
import documentRowStyle from '../../../../utils/ui/documentRowStyle';
import docStatusSystem from '../docStatusSystem';
import orderP from '../orderP';
import orderSend from '../orderSend';
import orderGetKM from '../orderGetKM';
import orderCheckKM from '../orderCheckKM';
import orderUpdateAllStatuses from '../orderUpdateAllStatuses';
import orderUpload from '../orderUpload';
import OrderSendListButton from './OrderSendListButton';
import OrderGetKMListButton from './OrderGetKMListButton';
import OrderCheckKMListButton from './OrderCheckKMListButton';
import OrderCloseReadyOrdersButton from './OrderCloseReadyOrdersButton';
import exporter from '../../../../utils/exporter';
import errorModel from '../errorModel';
import objectSendModel from '../objectSendModel';
import orderOmsStatus from '../orderOmsStatus';
import orderReport from '../orderReport';
import PostPagination from '../../../../utils/pagination';
import ExportDocButton from '../docH/ExportDocButton';
import paymentType from '../paymentType'
const ResourceIcon = BookmarkIcon;

const resourceToString = (x) => `#${x.id}`;

const listStyles = {};

const CreateOrder = (props) => {
  let params = {};
  if (props.filter && Object.keys(props.filter).length > 0) {
    params = {
      ...params,
      ...props.filter,
    };
  }
  if (props.selectedIds && props.selectedIds.length > 0) {
    params.ids = props.selectedIds;
  }
  return <orderReport.OpenButton params={params} />;
};

const UpdateAllStatusesButton = (props) => {
  return <orderUpdateAllStatuses.OpenButton size={'small'} variant={'raised'} />;
};

const PButton = (props) => {
  return (
    <orderP.LinkToList
      label={`resources.${resourceConfig.name}.orderP`}
      filter={{
        overEntityIds: props.record.id,
      }}
    />
  );
};

const SendButton = (props) => {
  return (
    <orderSend.OpenButton
      size={'small'}
      variant={'raised'}
      params={{
        id: props.record.id,
      }}
    />
  );
};

const GetKMButton = (props) => {
  return (
    <orderGetKM.OpenButton
      size={'small'}
      variant={'raised'}
      params={{
        id: props.record.id,
      }}
    />
  );
};

const CheckKMButton = (props) => {
  return (
    <orderCheckKM.OpenButton
      size={'small'}
      variant={'raised'}
      params={{
        id: props.record.id,
      }}
    />
  );
};

const ResourceBulkActionButtons = (props) => (
  <Fragment>
    <OrderSendListButton {...props} />
    <OrderGetKMListButton {...props} />
    <OrderCheckKMListButton {...props} />
    <BulkDeleteButton {...props} />
  </Fragment>
);

const ResourceActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  translate,
}) => (
  <Toolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <OrderCloseReadyOrdersButton />
    <OrderSendListButton />
    <OrderGetKMListButton />
    <OrderCheckKMListButton />
    <UpdateAllStatusesButton />
    <CreateOrder filter={filterValues} />
    <orderUpload.OpenButton />
    <CreateButton basePath={basePath} to={`${basePath}/create`} />
    <ExportDocButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

const ResourceListFilter = (props) => (
  <Filter {...props}>
    <TextInput source={'ids'} />
    <TextInput source={'orderIds'} />
    <TextInput source={'numdoc'} />
    <TextInput source={'descriptionNumdoc'} />
    <TextInput source={'guids'} />
    <TextInput source={'docnums'} />
    <DateInput
      source={'dateCreateFrom'}
      label={`resources.${resourceConfig.name}.filters.dateCreateFrom`}
    />
    <DateInput
      source={'dateCreateTo'}
      label={`resources.${resourceConfig.name}.filters.dateCreateTo`}
    />
  </Filter>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
  <List
    {...props}
    filters={<ResourceListFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    actions={<ResourceActions />}
    bulkActionButtons={<ResourceBulkActionButtons />}
    exporter={exporter}
    pagination={<PostPagination />}>
    <Datagrid rowStyle={documentRowStyle}>
      <TextField source={'id'} />
      <TextField source={'descriptionNumdoc'} />
      <TextField source={'numdoc'} />
      <TextField source={'orderId'} />
      <DateField source={'dateCreate'} />
      <orderOmsStatus.Field source={'status'} />
      <docStatusSystem.Field source={'documentSystemStatus'} />
      <releaseMethodType.Field source={'releaseMethodType'} />
      <PButton />
      <errorModel.LinkToRelatedList type={'ORDER'} />
      <objectSendModel.LinkToRelatedList type={'ORDER'} />
      <SendButton />
      <GetKMButton />
      <CheckKMButton />
      <EditButton />
    </Datagrid>
  </List>
));

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.id}&quot;
  </span>
));

const ResourceCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source={'orderId'}/>
      <TextInput source={'omsId'}/>
      <TextInput source={'actionId'}/>
      <NumberInput source={'expectedCompletionTime'}/>
      <DateInput
          source={'cemContractDate'}
          label={`resources.${resourceConfig.name}.fields.cemContractDate`}
      />
      <NumberInput source={'cemContractNumber'}/>
      <TextInput source={'cemInn'}/>
      <TextInput source={'cemName'}/>
      {/* <TextInput source={'companyUniqName'} /> */}
      <TextInput source={'contactPerson'}/>
      <DateInput
          source={'contractDate'}
          label={`resources.${resourceConfig.name}.fields.contractDate`}
      />
      <TextInput source={'contractNumber'}/>
      <TextInput source={'creatorUsername'}/>
      <TextInput source={'deliveryAddress'}/>
      <DeliveryType.Input source={'deliveryType'}/>
      <directionType.Input source={'direction'}/>
      <docStatus.Input source={'docStatus'}/>
      <TextInput source={'docnum'}/>
      <TextInput source={'documentId'}/>
      {/* "documentLinkId": 0,
            "documentLinkType": "DOC", */}
      <docStatusSystem.Input source={'documentSystemStatus'}/>
      <TextInput source={'emissionRegistrarOrderId'}/>
      <TextInput source={'labelTemplateId'}/>
      <TextInput source={'numdoc'}/>
      <OrderStatus.Input source={'orderStatus'}/>
      <DateInput
          source={'processedDate'}
          label={`resources.${resourceConfig.name}.fields.processedDate`}
      />
      <ProductionType.Input source={'productionType'}/>
      <TextInput source={'receiverId'}/>
      <TextInput source={'registrarId'}/>
      <TextInput source={'requestId'}/>
      <TextInput source={'subjectId'}/>
      <orderOmsStatus.Input source={'status'}/>

      <serialNumType.Input source="serialNumberType"/>
      <releaseMethodType.Input source="releaseMethodType"/>
      <productionType.Input source="createMethodType"/>

      <BooleanInput source={'remainsAvailable'}/>
      <BooleanInput source={'remainsImport'}/>
      <TextInput source={'descriptionNumdoc'}/>
      <DateInput
          source={'dateCreate'}
          label={`resources.${resourceConfig.name}.fields.dateCreate`}
      />
      <TextInput source={'producer'}/>
      <TextInput source={'paymentType'}/>
      <paymentType.Input source={'paymentType'}/>

    </SimpleForm>
  </Create>
);

const ResourceEdit = (props) => (
    <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
    <SimpleForm redirect={false}>
      <TextField source={'id'} />
      <TextInput source={'orderId'} />
      <TextInput source={'omsId'} />
      <TextInput source={'actionId'} />
      <NumberInput source={'expectedCompletionTime'} />
      <DateInput
        source={'cemContractDate'}
        label={`resources.${resourceConfig.name}.fields.cemContractDate`}
      />
      <NumberInput source={'cemContractNumber'} />
      <TextInput source={'cemInn'} />
      <TextInput source={'cemName'} />
      {/* <TextInput source={'companyUniqName'} /> */}
      <TextInput source={'contactPerson'} />
      <DateInput
        source={'contractDate'}
        label={`resources.${resourceConfig.name}.fields.contractDate`}
      />
      <TextInput source={'contractNumber'} />
      <TextInput source={'creatorUsername'} />
      <TextInput source={'deliveryAddress'} />
      <DeliveryType.Input source={'deliveryType'} />
      <directionType.Input source={'direction'} />
      <docStatus.Input source={'docStatus'} />
      <TextInput source={'docnum'} />
      <TextInput source={'documentId'} />
      {/* "documentLinkId": 0,
            "documentLinkType": "DOC", */}
      <docStatusSystem.Input source={'documentSystemStatus'} />
      <TextInput source={'emissionRegistrarOrderId'} />
      <TextInput source={'labelTemplateId'} />
      <TextInput source={'numdoc'} />
      <OrderStatus.Input source={'orderStatus'} />
      <DateInput
        source={'processedDate'}
        label={`resources.${resourceConfig.name}.fields.processedDate`}
      />
      <ProductionType.Input source={'productionType'} />
      <TextInput source={'receiverId'} />
      <TextInput source={'registrarId'} />
      <TextInput source={'requestId'} />
      <TextInput source={'subjectId'} />
      <orderOmsStatus.Input source={'status'} />

      <serialNumType.Input source="serialNumberType" />
      <releaseMethodType.Input source="releaseMethodType" />
      <productionType.Input source="createMethodType" />

      <BooleanInput source={'remainsAvailable'} />
      <BooleanInput source={'remainsImport'} />
      <TextInput source={'descriptionNumdoc'} />
      <DateInput
        source={'dateCreate'}
        label={`resources.${resourceConfig.name}.fields.dateCreate`}
      />
      <BooleanInput source={'autoRemark'} />
      <BooleanInput source={'sendToSoa'} />
      <TextInput source={'producer'} />
      <paymentType.Input source={'paymentType'} />
    </SimpleForm>
  </Edit>
);

const ResourceStringField = ({ record }) => <span>{resourceToString(record)}</span>;

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} {...props}>
    <ResourceStringField />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={resourceToString} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={resourceToString} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Create: ResourceCreate,
  Edit: ResourceEdit,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    edit: ResourceEdit,
    create: ResourceCreate,
    icon: ResourceIcon,
  },
  menuConfig: {
    name: resourceConfig.name,
    icon: ResourceIcon,
  },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {
      cemContractDate: {
        type: 'dateTime',
      },
      contractDate: {
        type: 'dateTime',
      },
      processedDate: {
        type: 'dateTime',
      },
      dateCreate: {
        type: 'dateTime',
      },
      dateCreateFrom: {
        type: 'dateTime',
      },
      dateCreateTo: {
        type: 'dateTime',
      },
    },
  },
};
