import React from 'react';
import {
    translate,
    Datagrid,
    Edit,
    Create,
    List,
    SimpleForm,
    TextField,
    TextInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    ReferenceArrayInput, 
    SelectArrayInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    EditButton,
    Filter,
    Toolbar,
    CreateButton
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';


import resourceLocales from './locales';
import resourceConfig from './config';
import documentRowStyle from '../../../../utils/ui/documentRowStyle';
import descRestP from '../descRestP';
import directionType from '../directionType';
import docStatusSystem from '../docStatusSystem';
import docStatus from '../docStatus';
import exporter from '../../../../utils/exporter';
import SendButton from './SendButton';
import ExportDocButton from '../docH/ExportDocButton';


const ResourceIcon = BookmarkIcon;

const resourceToString = x => `#${x.id}`;

const listStyles = {
};


const PButton = (props) => {
    return (
        <descRestP.LinkToList
            label={`resources.${resourceConfig.name}.reportP`}
            filter={{
                parent: props.record.id
            }}
        />
    );
}

const ResourceListActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    translate,
  }) => (
    <Toolbar>
      {bulkActions &&
        React.cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
        })}
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportDocButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
    </Toolbar>
  );

const ResourceListFilter = (props) => (
    <Filter {...props}>
    </Filter>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
    <List
        {...props}
        actions={<ResourceListActions />}
        filters={<ResourceListFilter />}
        exporter={exporter}
    >
        <Datagrid
            rowStyle={documentRowStyle}
        >
            <TextField source={'id'} />
            <TextField source={'docnum'} />
            <docStatusSystem.Field source={'documentSystemStatus'} />
            <PButton />
            <SendButton />
            <EditButton />
        </Datagrid>
    </List>
));

const ResourceTitle = translate(({ record, translate }) => (
    <span>
        {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{
            record.id
        }&quot;
    </span>
));

const ResourceCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source={'actionId'} />
            <TextInput source={'checkStatus'} />
            <directionType source={'direction'} />
            <docStatus source={'docStatus'} />
            <TextInput source={'docnum'} />
            <TextInput source={'documentId'} />
            <docStatusSystem.Input source={'documentSystemStatus'} />
            <TextInput source={'errors'} />
            <TextInput source={'guid'} />
            <TextInput source={'receiverId'} />
            <TextInput source={'requestId'} />
            <TextInput source={'subjectId'} />
            <TextInput source={'tradeParticipantInn'} />
        </SimpleForm>
    </Create>
);

const ResourceEdit = props => (
    <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
        <SimpleForm redirect={false}>
            <TextInput source={'actionId'} />
            <TextInput source={'checkStatus'} />
            <directionType source={'direction'} />
            <docStatus source={'docStatus'} />
            <TextInput source={'docnum'} />
            <TextInput source={'documentId'} />
            <docStatusSystem.Input source={'documentSystemStatus'} />
            <TextInput source={'errors'} />
            <TextInput source={'guid'} />
            <TextInput source={'receiverId'} />
            <TextInput source={'requestId'} />
            <TextInput source={'subjectId'} />
            <TextInput source={'tradeParticipantInn'} />
        </SimpleForm>
    </Edit>
);


const ResourceStringField = ({ record }) => (
    <span>{resourceToString(record)}</span>
)


const ResourceReferenceField = (props) => (
    <ReferenceField
        reference={resourceConfig.name}
        {...props}
    >
        <ResourceStringField />
    </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
    <ReferenceArrayField
        reference={resourceConfig.name}
        {...props}
    >
        <SingleFieldList>
            <ChipField source={'id'} />
        </SingleFieldList>
    </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
    <ReferenceInput 
      reference={resourceConfig.name} 
      {...props}
    >
      <SelectInput optionText={resourceToString} />
    </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
    <ReferenceArrayInput 
      reference={resourceConfig.name} 
      {...props}
    >
      <SelectArrayInput optionText={resourceToString}  />
    </ReferenceArrayInput>
);


export default {
    Icon: ResourceIcon,
    List: ResourceList,
    Title: ResourceTitle,
    Create: ResourceCreate,
    Edit: ResourceEdit,
    Field: ResourceReferenceField,
    ArrayField: ResourceReferenceArrayField,
    Input: ResourceReferenceInput,
    ArrayInput: ResourceReferenceArrayInput,

    name: resourceConfig.name,
    resourceConfig: {
        name: resourceConfig.name,
        list: ResourceList,
        edit: ResourceEdit,
        create: ResourceCreate,
        icon: ResourceIcon,
    },
    menuConfig: {
        name: resourceConfig.name, 
        icon: ResourceIcon,
    },
    locales:  resourceLocales,
    restProviderResolve: {
        name: resourceConfig.name,
        apiService: resourceConfig.apiService,
        basePath: resourceConfig.apiPath,
        schema: {
        }
    }
}
