import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Журнал остатков |||| Журнал остатков',
      mrkInput: {
        action: 'Ввод в оборот',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      soaSuiteSend: {
        action: ' Отправить в SOA Suite',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      bulkUpdate: {
        action: 'Обновление КИ',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      mrkDoc: {
        action: 'Отгрузка',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      load: {
        action: 'Загрузка',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      filters: {
        emissionDateFrom: 'Дата эмиссии с',
        emissionDateTo: 'Дата эмиссии по',
      },
      updateKM: {
        action: 'Обновить',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      updateAllKM: {
        action: 'Обновить все',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      fields: {
        id: '#',
        currentchldtype: 'Тип ',
        parentId: 'Родитель',
        parentIds: 'Родитель',
        prnttype: 'Тип родительского элемента',
        status: 'Статус',
        value: 'Код агрегата',
        emmissionDate: 'Дата эмиссии',
        producedDate: 'Дата производства',
        username: 'Пользователь',
        mrkSystemStatus: 'Системный статус',
        ordernum: 'Номер заказа',
        orderNum: 'Номер заказа',
        tnVedCode: 'ТНВЭД',
        tnVed10: 'ТНВЭД 10',
        productName: 'Название продукта',
        emissionType: 'Тип эмиссии',
        serialNumType: 'Тип серийного номера',
        serialNumber: 'Серийный номер',
        markingType: 'Тип маркировки',
        codeType: 'Тип кода',
        ownerInn: 'ИНН владельца',
        lastDocId: 'id последнего документа',
        uitCode: 'Код uit',
        documentSystemStatus: 'Системный статус документа',
        errorModelId: 'id модели ошибок',
        cryptoKey: 'Криптоключ',
        cryptoCode: 'Криптокод',
        temp: 'Временный',
        updated: 'Обновлен',
        cisPackageType: 'Тип упаковки',

        numGoods: 'Номер товара',
        invoice: 'Номер инвойса',
        dateInvoice: 'Дата инвойса',

        gtd: 'ГТД',
        dateGtd: 'Дата ГТД',

        numdoc: 'Номер ссылочного документа',
        manufacturingItem: 'Производственный код товара',
        itemCode: 'Код товара',
        utd: 'Номер УПД',
        mut: 'MUT-код',
        vendorCode: 'Артикул',
        timestampDate: 'Штамп дата-время',
        trackNumber: 'Номер трака',
        descriptionNumdoc: 'Описание ссылочного документа',
        ownerINN: 'ИНН собсвенника',
        numpos: 'Номер строки документа',
        statusChangeDate: 'Дата изменения статуса',
        counrty: 'Страна производитель',
        atk: 'ATK',
        invoiceAtkDate: 'Дата инвойса',
        invoiceAtk: 'Номер ссылочного документа',
        invoiceAtkPos: 'Номер позиции ссылочного документа',
        uit: 'UIT Код',
        uituCode: 'UITU Код',

        gtins: 'GTIN (Список)',
        numsGoods: 'Номер товара (Список)',
        invoices: 'Номер инвойса (Список)',
        manufacturingItems: 'Произв. код товара (Список)',
        itemCodes: 'Код товара (Список)',
        ssccs: 'SSCC (Список)',
        mrkSystemStatuses: 'Системный статус (Список)',
        statuses: 'Статус (Список)',
        ATKs: 'ATK (Список)',
        uituCodes: 'UITU Код (Список)',
        contactPerson:'Контактное лицо'
      },
    }
  }
}