import ServiceIcon from '@material-ui/icons/BorderClear';
import SearchPageIcon from '@material-ui/icons/Search';
import locales from './locales';

import actionType from './resources/actionType';
import aggrDocType from './resources/aggrDocType';
import aggrH from './resources/aggrH';
import aggrM from './resources/aggrM';
import aggrP from './resources/aggrP';
import bufferStatus from './resources/bufferStatus';
import cancelReason from './resources/cancelReason';
import codeType from './resources/codeType';
import confirmDocType from './resources/confirmDocType';
import controlSamplesType from './resources/controlSamplesType';
import deliveryType from './resources/deliveryType';
import directionType from './resources/directionType';
import docStatus from './resources/docStatus';
import docH from './resources/docH';
import docP from './resources/docP';
import docM from './resources/docM';
import docLink from './resources/docLink';
import docLinkType from './resources/docLinkType';
import docStatusSystem from './resources/docStatusSystem';
import docType from './resources/docType';
import emissionType from './resources/emissionType';
import errorModel from './resources/errorModel';
import good from './resources/good';
import inputH from './resources/inputH';
import inputP from './resources/inputP';
import labelTemplate from './resources/labelTemplate';
import markingStatus from './resources/markingStatus';
import markingType from './resources/markingType';
import mrkStatusSystem from './resources/mrkStatusSystem';
import objectSendModel from './resources/objectSendModel';
import orderCheckKM from './resources/orderCheckKM';
import orderCloseOrder from './resources/orderCloseOrder';
import orderGetKM from './resources/orderGetKM';
import orderH from './resources/orderH';
import orderM from './resources/orderM';
import orderP from './resources/orderP';
import orderSend from './resources/orderSend';
import ordersPool from './resources/ordersPool';
import orderStatus from './resources/orderStatus';
import orderType from './resources/orderType';
import orderUpdateAllStatuses from './resources/orderUpdateAllStatuses';
import orderUpload from './resources/orderUpload';
import outH from './resources/outH';
import outP from './resources/outP';
import outDocType from './resources/outDocType';
import printJasper from './resources/printJasper';
import productionType from './resources/productionType';
import productionTypeInput from './resources/productionTypeInput';
import printJournal from './resources/printJournal';
import printJournalElement from './resources/printJournalElement';
import printUpload from './resources/printUpload';
import printTicketUpload from './resources/printTicketUpload';
import printLoad from './resources/printLoad';
import queryRequest from './resources/queryRequest';
import quickPrint from './resources/quickPrint';
import requestType from './resources/requestType';
import remarkH from './resources/remarkH';
import remarkP from './resources/remarkP';
import remarkCause from './resources/remarkCause';
import releaseMethodType from './resources/releaseMethodType';
import requestStatus from './resources/requestStatus';
import reportH from './resources/reportH';
import reportP from './resources/reportP';
import reportStatus from './resources/reportStatus';
import serialNumType from './resources/serialNumType';
import serialNumberType from './resources/serialNumberType';
import treeMrk from './resources/treeMrk';
import turnoverType from './resources/turnoverType';
import treeMrkType from './resources/treeMrkType';
import treeMrkAggregation from './resources/treeMrkAggregation';
import treeMrkDisaggregation from './resources/treeMrkDisaggregation';
import treeMrkReaggregation from './resources/treeMrkReaggregation';
import xmlRequestDocument from './resources/xmlRequestDocument';
import xmlRequestTicket from './resources/xmlRequestTicket';
import documentType from './resources/documentType';
import documentImport from './resources/documentImport';
import eanInfo from './resources/eanInfo';
import eanInput from './resources/eanInput';
import eanInputUpload from './resources/eanInputUpload';
import inputCheck from './resources/inputCheck';
import treeMrkLoadCustom from './resources/treeMrkLoadCustom';
import goodUpload from './resources/goodUpload';
import eanInfoUpload from './resources/eanInfoUpload';
import descRestH from './resources/descRestH';
import descRestP from './resources/descRestP';
import notificationStatusChange from './resources/notificationStatusChange';
import mcMovementLogs from './resources/mcMovementLogs';
import returnType from './resources/returnType';
import returnPrimaryDocType from './resources/returnPrimaryDocType';
import returnP from './resources/returnP';
import returnH from './resources/returnH';
import certificateType from './resources/certificateType';
import mrkMoveLogs from './resources/mrkMoveLogs';
import rePrintByXlsx from './resources/rePrintByXlsx';
import docCheckStatus from './resources/docCheckStatus';
import taskManager from './resources/taskManager';
import taskManagerErrors from './resources/taskManagerErrors';
import taskManagerLogs from './resources/taskManagerLogs';
import docUpdate from './resources/docUpdate';
import ismpDocumentType from './resources/ismpDocumentType';
import ismpDocumentStatus from './resources/ismpDocumentStatus';
import mrkPackageType from './resources/mrkPackageType';
import mrkPacksType from './resources/mrkPacksType';
import mrkTreeType from './resources/mrkTreeType';
import mrkExStatus from './resources/mrkExStatus';
import taskManagerTask from './resources/taskManagerTask';
import orderOmsStatus from './resources/orderOmsStatus';
import funcType from './resources/funcType';
import cisPackageType from './resources/cisPackageType';
import reportUsageType from './resources/reportUsageType';
import erpH from './resources/erpH';
import erpGoods from './resources/erpGoods';
import erpKM from './resources/erpKM';
import articlesMap from './resources/articlesMap';
import articlesMapUpload from './resources/articlesMapUpload';
import counterparty from './resources/counterparty';
import docLoadCustom from './resources/docLoadCustom';
import updH from './resources/updH';
import updP from './resources/updP';
import updM from './resources/updM';
import outActionType from './resources/outActionType';
import remarkPrimaryDocType from './resources/remarkPrimaryDocType';
import withdrawalType from './resources/withdrawalType';
import atk from './resources/atk';
import inputPChild from './resources/inputPChild';
import atkForBroker from './resources/atkForBroker';
import treeMrkForBroker from './resources/treeMrkForBroker';
import xlsBrokerTemplateEnum from './resources/xlsBrokerTemplateEnum';
import conformityCertificate from './resources/conformityCertificate';
import conformityCertificateUpload from './resources/conformityCertificateUpload';
import inventoryH from './resources/inventoryH';
import inventoryP from './resources/inventoryP';
import inventoryImport from './resources/inventoryImport';
import nkRequestH from './resources/nkRequestH';
import nkGoodsH from './resources/nkGoodsH';
import statusMap from './resources/statusMap';
import nkMappingUpload from './resources/nkMappingUpload';
import atkKM from './resources/atkKM';
import treeMrkUpdateUpload from './resources/treeMrkUpdateUpload';
import statusMapUpload from './resources/statusMapUpload';
import docShipmentReport from './resources/docShipmentReport';
import docsSend from './resources/docShipmentReport';
import nkMapping from './resources/nkMapping';
import orderReport from './resources/orderReport';
import erpKMupload from './resources/erpKMupload';
import nkRequestCustomLoadEdit from './resources/nkRequestCustomLoadEdit';
import docHMassInput from './resources/docHMassInput';
import pdInformationH from './resources/pdInformationH';
import pdInformationP from './resources/pdInformationP';
import pdInformationPermitDocType from './resources/pdInformationPermitDocType';
import docEaeuShipments from './resources/docEaeuShipments';
import updHDocumentImport from './resources/updHDocumentImport';
import nkGoodsCheckGtin from './resources/nkGoodsCheckGtin';
import reportReportType from './resources/reportReportType';
import reportDropoutReason from './resources/reportDropoutReason';
import exportFile from './resources/exportFile';
import treeMrkCheckGisMtStatus from './resources/treeMrkCheckGisMtStatus';
import paymentType from './resources/paymentType';
const resources = [
  good,
  orderH,
  orderM,
  orderP,
  treeMrk,
  exportFile,

  treeMrkForBroker,
  orderReport,
  inputH,
  inputP,
  inputPChild,
  aggrH,
  aggrM,
  aggrP,
  //отгрузка / приемка
  outH,
  outP,
  remarkH,
  remarkP,
  printJournal,
  printJournalElement,

  // далее уже журналы идут

  docH,
  docP,
  docM,

  errorModel,
  objectSendModel,
  reportH,
  reportP,

  erpH,
  erpGoods,
  erpKM,

  updH,
  updP,
  updM,

  atk,
  atkKM,
  atkForBroker,

  conformityCertificate,
  conformityCertificateUpload,

  pdInformationH,
  pdInformationP,
  pdInformationPermitDocType,

  inventoryH,
  inventoryP,
  inventoryImport,

  nkRequestH,
  nkGoodsH,

  statusMap,
  nkMapping,

  actionType,
  aggrDocType,
  bufferStatus,
  cancelReason,
  codeType,
  confirmDocType,
  controlSamplesType,
  deliveryType,
  directionType,
  docStatus,
  docLink,
  docLinkType,
  docStatusSystem,
  docType,
  emissionType,
  labelTemplate,
  markingStatus,
  markingType,
  mrkStatusSystem,
  orderCheckKM,
  orderCloseOrder,
  orderGetKM,
  orderSend,
  ordersPool,
  orderStatus,
  orderOmsStatus,
  orderType,
  orderUpdateAllStatuses,
  orderUpload,
  outDocType,
  outActionType,
  funcType,
  remarkPrimaryDocType,
  withdrawalType,

  returnType,
  returnPrimaryDocType,
  returnP,
  returnH,
  certificateType,
  statusMapUpload,
  nkMappingUpload,
  erpKMupload,
  printJasper,
  productionType,
  productionTypeInput,
  printUpload,
  printTicketUpload,
  printLoad,
  queryRequest,
  quickPrint,
  requestType,
  remarkCause,
  releaseMethodType,
  requestStatus,
  reportStatus,
  serialNumType,
  serialNumberType,
  turnoverType,
  treeMrkType,
  treeMrkAggregation,
  treeMrkDisaggregation,
  treeMrkReaggregation,
  xmlRequestDocument,
  xmlRequestTicket,

  documentType,
  documentImport,

  eanInfo,
  articlesMap,
  articlesMapUpload,
  eanInput,
  eanInputUpload,

  counterparty,

  inputCheck,
  treeMrkLoadCustom,
  treeMrkUpdateUpload,

  goodUpload,
  eanInfoUpload,
  descRestP,
  descRestH,
  notificationStatusChange,
  mcMovementLogs,
  mrkMoveLogs,
  rePrintByXlsx,
  docCheckStatus,
  docUpdate,
  mrkPackageType,
  mrkPacksType,
  mrkTreeType,
  mrkExStatus,

  taskManager,
  taskManagerErrors,
  taskManagerLogs,
  ismpDocumentType,
  ismpDocumentStatus,
  taskManagerTask,
  cisPackageType,
  reportUsageType,
  docLoadCustom,
  xlsBrokerTemplateEnum,
  docShipmentReport,
  docsSend,
  nkRequestCustomLoadEdit,
  docHMassInput,
  docEaeuShipments,
  updHDocumentImport,
  nkGoodsCheckGtin,
  reportReportType,
  reportDropoutReason,
  treeMrkCheckGisMtStatus,
  paymentType
];

export default {
  name: 'marking',
  locales,
  resources,
  icon: ServiceIcon,
  customMenuItems: [
    {
      path: '/search-km',
      icon: SearchPageIcon,
      name: 'searchKMPage.title',
    },
  ],
  documentation: {
    swaggerResourcesPath: '/swagger-resources',
  },
};
