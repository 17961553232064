import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'АТК коды',
      fields: {
        atkId: 'АТК',
        ki: 'Код идентификации',
        numGoods: 'Код товара',
        tnved: 'ТНВЭД',
      },
    }
  }
}