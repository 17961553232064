import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Синхронизация',
      title: 'Синхронизация',
      executeAction: 'Отправить',
      errorMessage: 'Ошибка',
      successMessage: 'Успешно',
      fields: {
        dateFrom: 'Дата документа, от',
        dateTo: 'Дата документа, до',
        did: 'Значение номера документа - "точки отсчета", по которому сортируются записи',
        number: 'Номер документа',
        documentFormat: 'Формат документа',
        documentStatus: 'Статус документа',
        documentType: 'Тип документа',
        inputFormat: 'Входящий документ',
        participantInn: 'ИНН участника товарооборота',
        limit: 'Максимальное количество записей, которое вернется в качестве ответа',
        order: 'Направление сортировки',
        orderColumn: 'Название столбца, по которому будет производиться сортировка',
        orderedColumnValue: 'Значение столбца - "точки отсчета", по которому сортируются записи',
        pageDir: 'Выбор направления (вперед/назад) для пагинации',
        pg: 'Наименование товарных групп',
        senderInn: 'ИНН отправителя',
        receiverInn: 'ИНН получателя',
      },
      enums: {
        documentFormat: {
          MANUAL: 'формат json',
          XML: 'формат xml',
          CSV: 'формат csv',
        },
        documentStatus: {
          IN_PROGRESS: 'Проверяется',
          CHECKED_OK: 'Успешно обработан',
          CHECKED_NOT_OK: 'Обработан с ошибками',
          PROCESSING_ERROR: 'Ошибка при обработке',
          CANCELLED: 'Отгрузка отменена. Только для документа \'Отмена отгрузки\'',
          ACCEPTED: 'Отгрузка принята. Только для документа \'Отгрузка\'',
          WAIT_ACCEPTANCE: 'Ожидание приемки. Только для документа \'Отгрузка\'. Устанавливается при успешной обработке документа \'Отгрузка товара\'',
          WAIT_PARTICIPANT_REGISTRATION: 'Ожидание приемки. Только для документа \'Отгрузка\'. Устанавливается при успешной обработке документа \'Отгрузка товара\' в сторону незарегистрированного участника',
        },
        documentType: {
          LP_SHIP_GOODS: 'Отгрузка. json',
          LP_ACCEPT_GOODS: 'Приемка. json',
          EAS_CROSSBORDER_EXPORT: 'Отгрузка в ЕАЭС с признанием КИ (экспорт)'
        },
        order: {
          ASC: 'по возрастанию',
          DESC: 'по убыванию',
        },
        pageDir: {
          PREV: 'вперед',
          NEXT: 'назад',
        },
      }
    }
  }
}
