import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Позиции сведений РД',
      fields: {
        outHId: 'Документ',
        cancellationReason: 'Причина отмены',
        cis: 'ЦИС',
        posId: 'Id позиции',
        productCost: 'Стоимость',
        productTax: 'Налог',
        sscc: 'SSCC',
        uit: 'Код маркировки',
        uitu: 'Код агрегата',
        num_goods: 'Номер товара',
        primaryDocumentCustomName: 'Наименование первичного документа',
      },
    },
  },
};
