import React from 'react';
import qs from 'qs';
import {
  translate,
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  TextInput,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Filter,
  NumberField,
  Create,
  BooleanInput,
  EditButton,
  CreateButton,
  ExportButton,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Toolbar from '@material-ui/core/Toolbar';

import orderH from '../orderH';
import resourceLocales from './locales';
import resourceConfig from './config';
import LinkToList from './LinkToList';
import bufferStatus from '../bufferStatus';
import serialNumberType from '../serialNumberType';
import orderM from '../orderM';
import ordersPool from '../ordersPool';
import orderCloseOrder from '../orderCloseOrder';
import codeType from '../codeType';
import emissionType from '../emissionType';
import markingType from '../markingType';
import exporter from '../../../../utils/exporter';
import { DateInput } from '../../../../components/DateInput/DateInputRA';
import ExportDocButton from '../docH/ExportDocButton';
import EnumInput from '../../../../components/EnumInput';
const trPrefix = `resources.${resourceConfig.name}`;

const ResourceIcon = BookmarkIcon;

const listStyles = {};

const ResourceActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  translate,
}) => (
  <Toolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton
      basePath={basePath}
      to={`${basePath}/create?${
        filterValues && filterValues.overEntityIds ? `_orderHId=${filterValues.overEntityIds}` : ''
      }`}
    />
    <ExportDocButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

const MButton = (props) => {
  return (
    <orderM.LinkToList
      label={`resources.${resourceConfig.name}.orderM`}
      filter={{
        overEntityIds: props.record.id,
      }}
    />
  );
};

const PoolsButton = (props) => {
  return (
    <ordersPool.LinkToList
      label={`resources.${resourceConfig.name}.ordersPool`}
      filter={{
        overEntityIds: props.record.id,
      }}
    />
  );
};

const CloseButton = (props) => {
  return (
    <orderCloseOrder.OpenButton
      size={'small'}
      variant={'raised'}
      params={{
        orderPId: props.record.id,
      }}
    />
  );
};

const ResourceListFilter = (props) => (
  <Filter {...props}>
    <orderH.Input source="overEntityIds" label="overEntityIds" />
    <TextInput source={'gtin'} />
  </Filter>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
  <List
    {...props}
    sort={{ field: 'id', order: 'DESC' }}
    filters={<ResourceListFilter />}
    actions={<ResourceActions />}
    exporter={exporter}>
    <Datagrid>
      <TextField source={'id'} />
      <bufferStatus.Field source={'bufferStatus'} />
      <orderH.Field source={'orderHId'} />
      <NumberField source={'quantity'} />
      <TextField source={'gtin'} />
      <TextField source={'storageUnit'} />
      <TextField source={'ean'} />
      <MButton />
      <PoolsButton />
      <CloseButton />
      <EditButton />
    </Datagrid>
  </List>
));

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.id}&quot;
  </span>
));

const ResourceCreate = (props) => {
  const defaultData = {};
  if (props.location && props.location.search && props.location.search.length > 1) {
    const params = qs.parse(props.location.search.replace(/^\?/, ''));
    if (params && params._orderHId) {
      defaultData.orderHId = parseInt(params._orderHId);
    }
  }
  return (
    <Create {...props}>
      <SimpleForm>
        <orderH.Input source={'orderHId'} defaultValue={defaultData.orderHId} />
        <NumberInput source={'availableCodes'} />
        <TextInput source={'blockId'} />
        <bufferStatus.Input source={'bufferStatus'} />
        <TextInput source={'gtin'} />
        <NumberInput source={'leftInBuffer'} />
        <TextInput source={'numpos'} />
        <BooleanInput source={'poolsExhausted'} />
        <NumberInput source={'quantity'} />
        <TextInput source={'rejectionReason'} />
        <serialNumberType.Input source={'serialNumberType'} />
        <NumberInput source={'unavailableCodes'} />
        <TextInput source={'codeQuantity'} />
        <codeType.Input source={'codeType'} />
        <emissionType.Input source={'emissionType'} />
        <markingType.Input source={'markingType'} />
        <TextInput source={'numdoc'} />
        <TextInput source={'orderLineId'} />
        <TextInput source={'serialNumber'} />
        <TextInput source={'status'} />
        <TextInput source={'tnVedCode'} />
        <TextInput source={'templateKMId'} />
        <TextInput source={'fullKM'} />
        <TextInput source={'storageUnit'} />
        <TextInput source={'ean'} />
        <TextInput source={'cisType'} />
        <TextInput source={'exporterTaxpayerId'} />
        <TextInput source={'expDate72'} />
        <TextInput source={'certNum'} />
        <EnumInput
          source={'certType'}
          translatePrefix={`${trPrefix}.enums.certType.`}
          label={`${trPrefix}.fields.certType`}
          enums={resourceConfig.enums.certType}
        />
        <DateInput
        source={'certDate'}
        label={`resources.${resourceConfig.name}.fields.dateInvoice`}
        />
        <TextInput source={'expDate'} />

        <TextInput source={'numGoods'} />
        <TextInput source={'invoice'} />
        <DateInput
          source={'dateInvoice'}
          label={`resources.${resourceConfig.name}.fields.dateInvoice`}
        />

        <TextInput source={'manufacturingItem'} />
        <TextInput source={'itemCode'} />

        <BooleanInput source={'isDefault'} />
      </SimpleForm>
    </Create>
  );
};

const ResourceEdit = (props) => (
  <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
    <SimpleForm redirect={false}>
      <TextField source={'id'} />
      <NumberInput source={'availableCodes'} />
      <TextInput source={'blockId'} />
      <bufferStatus.Input source={'bufferStatus'} />
      <TextInput source={'gtin'} />
      <NumberInput source={'leftInBuffer'} />
      <TextInput source={'numpos'} />
      <orderH.Input source={'orderHId'} />
      <BooleanInput source={'poolsExhausted'} />
      <NumberInput source={'quantity'} />
      <TextInput source={'rejectionReason'} />
      <serialNumberType.Input source={'serialNumberType'} />
      <NumberInput source={'unavailableCodes'} />
      <TextInput source={'codeQuantity'} />
      <codeType.Input source={'codeType'} />
      <emissionType.Input source={'emissionType'} />
      <markingType.Input source={'markingType'} />
      <TextInput source={'numdoc'} />
      <TextInput source={'orderLineId'} />
      <TextInput source={'serialNumber'} />
      <TextInput source={'status'} />
      <TextInput source={'tnVedCode'} />
      <TextInput source={'templateKMId'} />
      <TextInput source={'fullKM'} />
      <TextInput source={'storageUnit'} />
      <TextInput source={'ean'} />
      <TextInput source={'cisType'} />
      <TextInput source={'exporterTaxpayerId'} />
      <TextInput source={'expDate72'} />
      <TextInput source={'expDate'} />
      <TextInput source={'certNum'} />
      <EnumInput
          source={'certType'}
          translatePrefix={`${trPrefix}.enums.certType.`}
          label={`${trPrefix}.fields.certType`}
          enums={resourceConfig.enums.certType}
        />
      <DateInput
        source={'certDate'}
        label={`resources.${resourceConfig.name}.fields.certDate`}
      />

      <TextInput source={'numGoods'} />
      <TextInput source={'invoice'} />
      <DateInput
        source={'dateInvoice'}
        label={`resources.${resourceConfig.name}.fields.dateInvoice`}
      />

      <TextInput source={'manufacturingItem'} />
      <TextInput source={'itemCode'} />

      <BooleanInput source={'isDefault'} />
    </SimpleForm>
  </Edit>
);

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} linkType={'show'} {...props}>
    <TextField source={'id'} />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} linkType={'show'} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={'id'} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={'id'} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Edit: ResourceEdit,
  Create: ResourceCreate,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,
  LinkToList,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    // show: ResourceShow,
    icon: ResourceIcon,
    edit: ResourceEdit,
    create: ResourceCreate,
  },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {
      dateInvoice: {
        type: 'dateTime',
      },
      certDate:{
        type: 'dateTime'
      }
    },
  },
};
