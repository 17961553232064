import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Сведения о РД |||| Сведения о РД ',
      pdInformationP: 'Позиции вывода',
      sendPdInformation: {
        action: 'Отправить',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      fields: {
        actionDate: 'Дата операции',
        cancellationDocDate: 'Дата отмены',
        documentDate: 'Дата документа',
        orderDate: 'Дата заказа',
        processedDate: 'Дата обработки',
        checkStatus: 'Статус ИСМП',
        action: 'Операция',
        cancelationReason: 'Причина отмены',
        cancellationDocNumber: 'Номер документа отмены',
        cis: 'ЦИС',
        direction: 'Направление',
        docStatus: 'Статус',
        docnum: 'Номер',
        documentId: 'Id документа',
        documentLinkType: 'Тип ссылки на документ',
        documentLinkId: 'Id ссылки на документ',
        documentNumber: 'Номер документа',
        documentSystemStatus: 'Системный статус документ',
        documentType: 'Тип документа',
        errorModelId: 'Id ошибки',
        inn: 'ИНН',
        orderNumber: 'Номер заказа',
        outDocType: 'Тип документа',
        productCost: 'Стоимость товара',
        productTax: 'Налог',
        receiverId: 'Id получателя',
        requestId: 'Id запроса',
        subjectId: 'Id темы',
        kktNumber: 'Контрольно-кассовая техника (Номер кассы)',
        pdfFile: 'Приложенный PDF файл в Base64',
        primaryDocumentCustomName: 'Наименование первичного документа',

        guids: 'GUID (Список)',
        docnums: 'Номер документа (Список)',
        documentNumbers: 'Номер документа',
      },
    },
  },
};
